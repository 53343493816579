import React, { useState, useEffect, useMemo } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import addMonths from 'date-fns/addMonths';
import isBefore from 'date-fns/isBefore';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import { isMonday, isTuesday, isWednesday, isThursday, set } from 'date-fns';
import { auth, db } from '../firebaseConfig'; // Adjust path as needed
import { doc, setDoc, getDoc, query, where, updateDoc, deleteDoc, deleteField, getFirestore, arrayUnion, arrayRemove, getDocs, collection } from "firebase/firestore"; // Add missing imports
import AI_Opinion from './AI_Opinion'; // Import the component at the top
import NavigationBar from './NavigationBar';
import AI_Chat from './AI_Chat'; // Import the component at the top
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import jsonData from '../api_responses.json';
import { useParams } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { useLocation } from 'react-router-dom';
import { AppBar, Toolbar, TextField, InputAdornment, Button, Box, CircularProgress, Typography, Grid, Checkbox, Paper, FormControlLabel, Card, Tab, Tabs, IconButton, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { AccountCircle, Star, StarBorder } from '@mui/icons-material';
import AutoAwesome from '@mui/icons-material/AutoAwesome'; // AI icon
import Dashboard from '@mui/icons-material/Dashboard'; // AI icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FlagIcon from '@mui/icons-material/Flag'; // Ensure you have imported this icon
import FavoriteIcon from '@mui/icons-material/Favorite'; // Ensure you have imported this icon
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'; // This is for the unflagged state
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import allUpcomingEvents from '../all_upcoming_events.json';
import AddIcon from '@mui/icons-material/Add'; // Import the add icon


const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: 'white',
  transition: '0.05s',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  '&.Mui-disabled': {
    background: 'linear-gradient(45deg, #E0E0E0 30%, #BEBEBE 90%)',
    color: '#9E9E9E',
  },
}));

const GradientTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'purple',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
      // boxShadow: '0 0 5px rgba(254,107,139,0.5), 0 0 10px rgba(255,142,83,0.5)',
      boxShadow: '0 0 2px black, 0 0 4px grey',
      borderWidth: '1px',
    },
  },
  '& .MuiInputBase-inputMultiline': {
    minHeight: '50px',
    overflow: 'auto',
  },
});

// Custom AI icon with gradient
const GradientIcon = styled(AutoAwesome)(({ theme }) => ({
  color: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomButton = styled(Button)(({ theme, bgcolor }) => ({
  color: 'black',
  borderColor: 'transparent',
  boxShadow: '0 3px 6px rgba(0,0,0,0.2)', // Normal state shadow
  transition: 'box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out', // Smooth transition for shadow and background color
  '&:hover': {
    boxShadow: '0 6px 12px rgba(0,0,0,0.3)', // Larger shadow on hover
    backgroundColor: bgcolor, // Example hover background color
    borderColor: 'transparent'
  },
}));

const convertToISOFormatMINI = (dateStr) => {
  if (!dateStr) {
    console.error("Error: dateStr is null or empty.");
    return null; // Handle null or empty strings early
  }

  // Remove any surrounding quotation marks that might be included in the input string
  dateStr = dateStr.replace(/['"]+/g, '');
  console.log("Cleaned Date Input:", dateStr); // Log cleaned input to verify format

  const parts = dateStr.split('-');
  if (parts.length !== 3) {
    console.error("Error: dateStr does not split into three parts.", parts);
    return null; // Ensure it splits into three parts
  }

  try {
    let year = parseInt(parts[0], 10); // Convert the string to an integer
    if (isNaN(year)) {
      console.error("Conversion Error: Year is not a number.", parts[0]);
      return null; // Check if year conversion failed
    }

    const month = parts[1].padStart(2, '0'); // Ensure two-digit month
    const day = parts[2].padStart(2, '0');   // Ensure two-digit day

    if (year < 100) { // Assume year is two digits
      year = year > 50 ? 1900 + year : 2000 + year;
    }

    return `${year}-${month}-${day}`; // Return the date in ISO format YYYY-MM-DD
  } catch (e) {
    console.error("Error converting date:", e);
    return null;
  }
};


const convertToISOFormat = (dateStr) => {
  if (!dateStr) return null; // Return null if dateStr is empty
  const parts = dateStr.split('-');
  if (parts.length !== 3) return null; // Return null if dateStr format is not as expected

  // Assuming date is in YY-MM-DD format and needs conversion to YYYY-MM-DD
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  const fullYear = year.length === 2 ? (parseInt(year, 10) > 50 ? `19${year}` : `20${year}`) : year;

  return `${fullYear}-${month}-${day}`; // Return the date in ISO format (YYYY-MM-DD)
};


const cleanSpeakerName = (name) => {
  return name.replace(/\*\*/g, '');  // Removes asterisks
};

const cleanText = (text) => {
  // Normalize space around colons and hyphens, remove quotes, parentheses, then trim
  return text.replace(/['\(\)]/g, "")  // Remove parentheses and quotes
    // .replace(/[-:]+/g, ":")  // Replace multiple hyphens/colons with a single colon
    .replace(/^:\s*|\s*:\s*$/g, "")  // Remove leading/trailing colons
    .trim();  // Trim whitespace
};

const getColorForSentiment = (sentiment) => {
  let color;
  if (sentiment < 0) {
    // Map sentiment from -1 to 0 into opacity from 1 to 0.1 (more negative, more opaque red)
    let opacity = 0 - sentiment * 0.60; // as sentiment increases (less negative), opacity decreases
    color = `rgba(255, 0, 0, ${opacity})`; // Red with varying opacity
  } else if (sentiment > 0) {
    // Map sentiment from 0 to 1 into opacity from 0.1 to 1 (more positive, more opaque green)
    let opacity = 0 + sentiment * 0.6; // as sentiment increases, opacity increases
    color = `rgba(0, 255, 0, ${opacity})`; // Green with varying opacity
  } else {
    // If sentiment is exactly 0, make it a very faint gray (near white)
    color = 'rgba(255, 255, 255, 0.1)'; // Nearly white
  }
  return color;
};

const getColorForPriceChange = (priceChange) => {
  let color;
  priceChange = 0;
  if (priceChange < 0) {
    let opacity = 0 - priceChange / 50.0; // More negative change, more intense red
    color = `rgba(255, 0, 0, ${opacity})`;
  } else if (priceChange > 0) {
    let opacity = 0 + priceChange / 50.0; // More positive change, more intense green
    color = `rgba(0, 255, 0, ${opacity})`;
  } else {
    color = 'rgba(0, 0, 0, 0.1)'; // Neutral change, nearly transparent gray
  }
  return color;
};
function CompanyPageMini({ fullTrade }) {
  const companyName = fullTrade.stockName;
  console.log("COMPANYMINI COMPANY ", companyName);

  const data = jsonData[companyName];
  const [headlines, setHeadlines] = useState([]);
  const [matched_headlines, setMatchedHeadlines] = useState([]);
  const [user, setUser] = useState(null);

  const formatDate = (dateStr) => {
    try {
      const isoDateStr = convertToISOFormat(dateStr);
      if (!isoDateStr) {
        console.error("Invalid ISO Date:", dateStr); // Log bad inputs
        return 'Invalid date';
      }

      const date = new Date(isoDateStr + 'T18:00:00Z');
      if (isNaN(date.getTime())) {
        console.error("Invalid Date Object from ISO String:", isoDateStr); // More specific logging
        return 'Invalid date';
      }

      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error(`Error in formatDate function: ${dateStr}`, error);
      return 'Invalid date';
    }
  };
  const sortedDates = Object.keys(data).sort((a, b) => new Date(formatDate(a)) - new Date(formatDate(b)));
  // const [selectedDate, setSelectedDate] = useState(convertToISOFormat(fullTrade.infoDate));
  const findMatchingDateKey = (data, targetDate) => {
    const cleanedTargetDate = targetDate.replace(/['"]+/g, '');
    const formattedTargetDate = convertToISOFormatMINI(cleanedTargetDate);
    console.log("FORMATTED TARGET DATE: ", formattedTargetDate);

    const keys = Object.keys(data).map(key => ({
      original: key,
      formatted: convertToISOFormatMINI(key.replace(/['"]+/g, ''))
    }));
    console.log("KEYS: ", keys.map(k => k.formatted));

    const match = keys.find(key => key.formatted === formattedTargetDate);
    console.log("MATCH FOUND: ", match ? match.original : undefined);
    return match ? match.original : undefined;
  };


  const [selectedDate, setSelectedDate] = useState(() => findMatchingDateKey(data, fullTrade.infoDate));
  const mostRecentDate = selectedDate;


  console.log("COMPANYMINI SELECTED DATE", selectedDate);

  const [setupPeRatio, setupSetPeRatio] = useState('N/A');
  const [setupPTtmRevenue, setupSetPTtmRevenue] = useState('N/A');
  const [isStarred, setIsStarred] = useState(false);
  const [notes, setNotes] = useState([]);
  const [currentThesisId, setCurrentThesisId] = useState(null);
  const [newNoteText, setNewNoteText] = useState("");
  const [newNoteSetAsThesis, setNewNoteSetAsThesis] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [editText, setEditText] = useState("");
  const [editDate, setEditDate] = useState("");
  const personalThesis = fullTrade.personalThesis;
  const [keyNotes, setKeyNotes] = useState([]); // keyNotes as an array
  const [isLoading, setIsLoading] = useState(true);
  const [showBullAnalysis, setShowBullAnalysis] = useState(false);
  const [showBearAnalysis, setShowBearAnalysis] = useState(false);
  const [showPersonalAnalysis, setShowPersonalAnalysis] = useState(false);
  const [bullAnalysis, setBullAnalysis] = useState("");
  const [bearAnalysis, setBearAnalysis] = useState("");
  const [personalAnalysis, setPersonalAnalysis] = useState("");
  const [hiddenTheses, setHiddenTheses] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [displayingChat, setDisplayingChat] = useState(false);
  const [aiChatContents, setAiChatContents] = useState("");

  const [query, setQuery] = useState(fullTrade.aiChatQuestion || '');

  // const db = getFirestore();

  const [showHeadlines, setShowHeadlines] = useState(false);
  const [showQuotes, setShowQuotes] = useState(false);
  const [openDebates, setOpenDebates] = useState({});
  const [globalShowHeadlines, setGlobalShowHeadlines] = React.useState(false);
  const [openHeadlines, setOpenHeadlines] = useState({});
  const [showHeadlinesSection, setShowHeadlinesSection] = useState(true);
  const [showCustomNotes, setShowCustomNotes] = useState(true);
  const [showTranscript, setShowTranscript] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [downloadedTranscript, setDownloadedTranscript] = useState(false);
  const [transcriptIsLoading, setTranscriptIsLoading] = useState(false);

  const [showKeyNotesSection, setKeyNotesSection] = useState(true);
  const [tradeDialogOpen, setTradeDialogOpen] = useState(false);

  const handleAddTradeClick = () => {
    setTradeDialogOpen(true);
  };

  const handleDialogClose = () => {
    setTradeDialogOpen(false);
  };
  // Fetch transcript from Firestore
  const fetchTranscript = async () => {
    setTranscriptIsLoading(true);
    try {

      console.log("SELECTED DATE: ", selectedDate);
      const reportsRef = collection(db, `transcripts/${companyName}/reports`);
      console.log("REPORTS REF: ", reportsRef);
      const querySnapshot = await getDocs(reportsRef);

      // Filter documents based on document ID starting with the desired prefix
      const prefix = `${companyName}-${selectedDate}`;
      const matchedDocs = querySnapshot.docs.filter(doc =>
        doc.id.startsWith(prefix)
      );

      if (matchedDocs.length > 0) {
        // Assuming we take the first report if multiple are present
        setTranscript(matchedDocs[0].data().content); // Assuming 'content' is the field where the transcript text is stored
        console.log("Transcript fetched: ", matchedDocs[0].data().content);
      } else {
        setTranscript('No transcript available for this date.');
        console.log("No matching documents found.");
      }
    } catch (error) {
      console.error('Failed to fetch transcript:', error);
    } finally {
      setTranscriptIsLoading(false);
    }
  };

  function removeUnwantedContent(text) {
    const lines = text.split('\n');
    const cleanedLines = lines.filter(line => {
      // Remove lines starting with '#'
      if (line.trim().startsWith('#')) {
        return false;
      }

      if (line.trim().endsWith(' Earnings Call')) {
        return false;
      }

      // Remove lines likely containing ads or email subscription prompts
      if (/Subscribe|Enter Your Email|click here/i.test(line)) {
        return false;
      }

      // Remove embedded unrelated content or specific phrases
      if (/Best Places to Retire|Alternatives to/.test(line)) {
        return false;
      }
      // Remove embedded unrelated content or specific phrases
      if (/Prepared Remarks:/.test(line)) {
        return false;
      }
      if (/Questions & Answers:/.test(line)) {
        return false;
      }
      // Remove embedded unrelated content or specific phrases
      if (/Q&A Session/.test(line)) {
        return false;
      }

      // Remove embedded unrelated content or specific phrases
      if (/NYSE/.test(line)) {
        return false;
      }

      if (/FACTSET|FactSet|Factset|Corrected Transcript|MANAGEMENT DISCUSSION SECTION|QUESTION AND, ANSWER SECTION/.test(line)) {
        return false;
      }
      if (/Apr-2024/.test(line)) {
        return false;
      }
      // Case-insensitive check for company name and ticker
      if (new RegExp(`${jsonData[companyName][Object.keys(jsonData[companyName])[0]].full_name.toLowerCase()}.replace(/\s+/g, '\\s*,?\\s*')}\\s*\\(${companyName}\\)`, 'i').test(line.toLowerCase())) {
        return false;
      }

      // Remove embedded unrelated content or specific phrases
      // if (/Inc.|Co./.test(line)) {
      //   return false;
      // }
      // if (/Inc.|Co./.test(line)) {
      //   return false;
      // }
      // Remove lines that contain only one word, unless it's "Operator"


      if (line.trim().toLowerCase() === 'or') {
        return false;
      }
      if (/^\d+$/.test(line)) {  // Ensures the line is a number
        const number = parseInt(line, 10);
        if (number >= 0 && number <= 50) {
          return false;
        }
      }
      const nasdaqMatches = (line.match(/nasdaq/gi) || []).length;
      if (nasdaqMatches === 1) {
        return false;
      }

      return true;
    });

    return cleanedLines.join('\n');
  }

  function insertParagraphs(text, sentenceThreshold = 5, lengthThreshold = 500) {
    const sentences = text.match(/[^.!?]+[.!?]\s*/g) || [];
    let newParagraph = "";
    let sentenceCount = 0;

    // Iterate through each sentence to construct new paragraphs
    sentences.forEach(sentence => {
      newParagraph += sentence;
      sentenceCount++;

      // Check if the current set of sentences reaches the threshold and the total length exceeds a given limit
      if (sentenceCount >= sentenceThreshold && newParagraph.length > lengthThreshold) {
        newParagraph += '\n\n'; // Add two new lines to create a paragraph break
        sentenceCount = 0; // Reset sentence count after a break
      }
    });

    return newParagraph.trim(); // Trim the final string to remove any extra spaces or new lines
  }


  function employFactsetFormatting(transcript) {
    // Check for the presence of many single quotes or FactSet mentions
    const quoteCount = (transcript.match(/''/g) || []).length;
    const factSetCount = (transcript.match(/FactSet/gi) || []).length;

    // Determine if special formatting should be applied based on thresholds
    return quoteCount > 50 || factSetCount >= 3;
  }

  function employDashFormatting(transcript) {
    // Regex to match lines starting with two names followed by a dash and a title or similar
    const dashNameFormatRegex = /^(?:\w+\s+\w+\s*--\s*.+)$/gm;

    // Extract all matches for the specific name-title dash format
    const dashFormatMatches = transcript.match(dashNameFormatRegex) || [];

    // Use dash format if there are at least 2 instances of the name-title dash format
    return dashFormatMatches.length >= 10;
  }

  function formatLastPass(transcript) {
    // Normalize line breaks - replace multiple breaks with a single one
    transcript = transcript.replace(/^<br\/>/, '');
    transcript = transcript.replace(/(<br\/>)+/g, '<br/><br/>');

    transcript = transcript.replace(/<br\/>\.\s+(Q\s+|A\s+)?/g, '<br/>');


    // Ensure there is a line break before a speaker's name with a colon
    transcript = transcript.replace(/(?<!<br\/>)(<strong>[A-Z][a-z]*\s+[A-Z][a-z]*(\s+[A-Z][a-z]*)*:<\/strong><br\/>)/g, '<br/>$1');

    // Remove bold and italics from lines with more than 30 words
    transcript = transcript.replace(/<br\/>(<strong>.*?<\/strong>)/g, (match, p1) => {
      // Count words by splitting on spaces within the bolded text
      const wordCount = p1.replace(/<[^>]+>/g, "").split(/\s+/).length;
      if (wordCount > 30) {
        // Remove bold and italics tags
        return `<br/>${p1.replace(/<\/?strong>/g, '').replace(/<\/?i>/g, '')}`;
      }
      return match;
    });

    return transcript;
  }

  function formatTranscript(transcript) {
    // Determine the formatting style based on the content of the transcript
    const useFactsetFormat = employFactsetFormatting(transcript);
    let cleanedTranscript = removeUnwantedContent(transcript);
    const useDashFormat = employDashFormatting(cleanedTranscript);

    if (useFactsetFormat) {

      // get rid of ...
      cleanedTranscript = cleanedTranscript.replace(/[\.\-]{10,}/g, '....');

      // // Apply special formatting for transcripts with many single quotes or FactSet mentions
      cleanedTranscript = cleanedTranscript.replace(/\.\s*\n/g, '. '); // Replace periods followed by newlines with period and space
      cleanedTranscript = cleanedTranscript.replace(/,\s*\n/g, ', '); // Replace commas followed by newlines with comma and space

      // Remove any improper newline characters within sentences
      // cleanedTranscript = cleanedTranscript.replace(/([a-z]),\n/g, '$1, ');
      // cleanedTranscript = cleanedTranscript.replace(/(\.)\n([a-z])/g, '$1 $2');

      cleanedTranscript = cleanedTranscript.replace(/(\.{4})\s+(\w+\s\w+)([\s\S]*?)(?=(\.{1}\s|$))/g, (match, dots, name, details) => {
        return `<br/> <strong>${name}, <i>${details}</i></strong><br/><br/>`; // Formats the name in bold and preserves details following the name
      });
      // // Fix the separation of names from titles and bold them appropriately
      // cleanedTranscript = cleanedTranscript.replace(/Ms\.\s*([A-Za-z]+)\s*,\s*([A-Za-z ]+)\s*\n/g, '<strong>$1, $2:</strong> ');
      // cleanedTranscript = cleanedTranscript.replace(/([A-Za-z]+)\s+([A-Za-z]+),\s*([A-Za-z &-]+),\s*([A-Za-z, Inc\.]+)\n/g, '<strong>$1 $2, $3, $4:</strong> ');

      // Combine broken sentences
      cleanedTranscript = cleanedTranscript.replace(/([a-z])\s*\n([a-z])/g, '$1 $2');

      // Normalize line breaks after sentences
      // cleanedTranscript = cleanedTranscript.replace(/([.?!])\s+/g, '$1<br/><br/>');

      // Remove any remaining newlines
      cleanedTranscript = cleanedTranscript.replace(/\n/g, ' ');

      return cleanedTranscript;
    } else if (useDashFormat) {
      // Format using dashes and customize speaker labels
      return cleanedTranscript.replace(/^(.+?) -- (.+)$/gm, (match, p1, p2) => {

        let parts = p1.split(' ');
        const title = parts.pop(); // Last part is the title
        const lastName = parts.pop(); // Second to last part is the last name
        const firstName = parts.join(' '); // Remaining parts are the first name
        return `<strong>${firstName} ${lastName} ${title}, <i>${p2}</i></strong>`;

      }).replace(/^Operator\n((.|\n)*?)(?=\n[A-Z])/gm, (match, p1) => {
        // Special handling for "Operator" where the entire speech block is formatted
        return `<strong>Operator</strong><br/>`;
      }).replace(/\n/g, '<br/>');


    } else {
      // Default formatting using strong tags and colons
      return cleanedTranscript.replace(/([A-Z][a-z]*\s+[A-Z][a-z]*(\s+[A-Z][a-z]*)*):/g, (match) => {
        return `<strong>${match}</strong><br/>`; // Add bold to speaker's name and a line break for clarity
      }).replace(/\n/g, '<br/>');
    }
  }

  // useEffect(() => {
  //   if (companyName) {
  //     fetchTranscript();
  //   }
  // }, [companyName, selectedDate]);


  const toggleHeadlinesSection = () => {
    setShowHeadlinesSection(!showHeadlinesSection);
  };
  const toggleCustomNotesSection = () => {
    setShowCustomNotes(!showCustomNotes);
  };
  const toggleTranscript = () => {
    if (showTranscript === false && downloadedTranscript === false) {
      fetchTranscript();
      setDownloadedTranscript(true);
    }
    setShowTranscript(!showTranscript);
  };
  const toggleKeyNotesSection = () => {
    setKeyNotesSection(!showKeyNotesSection);
  };

  const toggleQuotes = () => {
    setShowQuotes(!showQuotes);
    // Ensure to reset individual debates' toggles when globally toggling quotes
    if (!showQuotes) { // If we are turning quotes on, open all debates
      const allDebatesOpen = {};
      selectedData.debates.forEach((_, index) => allDebatesOpen[index] = true);
      setOpenDebates(allDebatesOpen);
    } else { // If we are turning quotes off, close all debates
      setOpenDebates({});
    }
  };

  const handleToggleDebate = (index) => {
    setOpenDebates(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const toggleHeadlines = () => {
    if (!globalShowHeadlines) {
      // Opening all headlines globally
      const newOpenHeadlines = {};
      selectedData.debates.forEach((debate, debateIndex) => {
        newOpenHeadlines[debateIndex] = {};
        debate.key_quotes.forEach((_, quoteIndex) => {
          newOpenHeadlines[debateIndex][quoteIndex] = true; // Set all quotes' headlines to be open
        });
      });
      setOpenHeadlines(newOpenHeadlines);
    } else {
      // Closing all headlines globally
      setOpenHeadlines({});
    }
    setGlobalShowHeadlines(!globalShowHeadlines);
  };

  const handleToggleHeadlines = (index, quoteIndex) => {
    // This function should check if we're currently in a global state
    if (globalShowHeadlines) {
      // If globally showing, turning one off should first set global off and only toggle the specific one
      setGlobalShowHeadlines(false);
      const newOpenHeadlines = { ...openHeadlines };
      for (const [debIdx, quotes] of Object.entries(newOpenHeadlines)) {
        for (const qIdx in quotes) {
          if (parseInt(debIdx) === index && parseInt(qIdx) === quoteIndex) {
            newOpenHeadlines[index][quoteIndex] = false;
          } else {
            newOpenHeadlines[debIdx][qIdx] = true;
          }
        }
      }
      setOpenHeadlines(newOpenHeadlines);
    } else {
      // Normal toggle functionality
      setOpenHeadlines(prev => ({
        ...prev,
        [index]: {
          ...prev[index],
          [quoteIndex]: !(prev[index] && prev[index][quoteIndex])
        }
      }));
    }
  };

  // MATERIAL UI
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatThesisText = (text) => {
    const formattedItems = text.split('\n').map((item, index) => {
      // Check if line is valid for formatting
      if (item.trim().startsWith('- **')) {
        // Extract and bold only the initial keyword(s)
        const cleanText = item.replace('- **', '').replace('**', '').trim();
        // const cleanText2 = item.replace('**:', ':').trim();
        const splitIndex = cleanText.indexOf(':');
        const keyText = cleanText.substring(0, splitIndex + 1); // Includes the colon in the bold part
        const restText = cleanText.substring(splitIndex + 1).trim();

        return (
          <ListItem key={index} alignItems="flex-start" sx={{ marginTop: '-10px', marginBottom: '0px' }} >
            {index === 0 && ( // Only the first item gets the icon
              <ListItemIcon sx={{ marginTop: '-2px' }}> {/* Adjust alignment as needed */}
                <GradientIcon fontSize="medium" />
              </ListItemIcon>
            )}
            <ListItemText primary={
              <>
                <Typography component="span" variant="body2" style={{ marginTop: '-20px', fontWeight: 'bold', fontSize: '0.925rem', marginRight: '4px' }}>{keyText}</Typography>
                <Typography component="span" variant="body2" style={{ marginTop: '-20px', fontSize: '0.925rem', ml: '5px' }}>{restText}</Typography>
              </>
            } />

          </ListItem>
        );
      }
      return null;
    });

    // Filter out any null entries if some lines do not start with the expected '- **'
    return formattedItems.filter(item => item !== null);
  };

  const displayDate = (dateStr) => {
    try {

      const date = new Date(dateStr + 'T12:00:00Z');
      if (isNaN(date.getTime())) {
        console.error("Invalid Date Object from ISO String:", date); // More specific logging
        return 'Invalid date';
      }

      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error(`Error in formatDate function: ${dateStr}`, error);
      return 'Invalid date';
    }
  }
  // Function to format the thesis text
  // Function to handle received AI opinion
  const handleOpinionReceived = (opinionText, newResponse, chatFlag) => {
    if (!chatFlag) {
      if (newResponse) {
        extractAndStoreKeyNotes(opinionText);
      } else if (opinionText != "") {
        console.log("Chat flag false, NOT a new response, and text: ", opinionText);
        setHiddenTheses(true);
      }
      extractAndDisplayAnalyses(opinionText);
    } else {
      console.log("Chat flag true, and text: ", opinionText);
      setDisplayingChat(true);
    }
  };

  // Modify the text processing to add new lines for bullet points
  const formatAIResponse = (text) => {
    const formattedText = text.replace(/(-\s)/g, '\n$1');
    console.log("FORMATTING AI", formattedText); // Check what the formatted text looks like
    return formattedText;
  }

  const extractAndDisplayAnalyses = (text) => {
    // First, ensure the text is split by the correct headers
    // add new lines
    const splitBull = text.split("### 1. Analysis of Street Bull Thesis");
    const bullAnalysisDraft = splitBull.length > 1 ? splitBull[1].split("### 2. Analysis of Street Bear Thesis")[0] : "";

    const splitBear = text.split("### 2. Analysis of Street Bear Thesis");
    const bearAnalysisDraft = splitBear.length > 1 ? splitBear[1].split("### 3. Analysis of Personal Thesis")[0] : "";

    const splitPersonal = text.split("### 3. Analysis of Personal Thesis");
    const personalAnalysisDraft = splitPersonal.length > 1 ? splitPersonal[1].split("### 4. Recent")[0] : "";

    // Use the modified function when setting state or displaying the text
    setBullAnalysis(formatAIResponse(bullAnalysisDraft.trim()));
    setBearAnalysis(formatAIResponse(bearAnalysisDraft.trim()));
    setPersonalAnalysis(formatAIResponse(personalAnalysisDraft.trim()));

    console.log("FINISHING ANALSYSES&*!#$*!#*$!#$!#$!#$!#$!#$");

  }

  //   useEffect(() => {
  //     console.log("Setting up auth state listener");
  //     const unsubscribe = auth.onAuthStateChanged((currentUser) => {
  //         if (currentUser) {
  //             console.log("Authenticated user:", currentUser);
  //             setUser(currentUser);  // Set the authenticated user to state
  //         } else {
  //             console.log("No user authenticated");
  //             setUser(null);  // Set user to null if not authenticated
  //         }
  //     });

  //     // Cleanup function to unsubscribe from the listener when the component unmounts
  //     return () => {
  //         console.log("Cleaning up auth listener");
  //         unsubscribe();
  //     };
  // }, []);

  const extractAndStoreKeyNotes = (text) => {
    console.log("TEXT: ", text);

    // Try to extract text after "Recent Disconfirming Evidence"
    const splitText = text.split("### 4. Recent");
    const relevantText = splitText.length > 1 ? splitText[1] : "";
    console.log("RELEVANT TEXT: ", relevantText);

    if (!relevantText) {
      console.log("No relevant text found after '### 4. Recent'.");
      return; // Exit function if no relevant text is found
    }

    // Updated regex to match the new markers @@@ and +++
    const keyNotesRegex = /@@@\s*([^@]+?)\s*(@@@|\+\+\+)/g;
    const matches = [...(relevantText.matchAll(keyNotesRegex) || [])]; // Use || [] to handle null results
    console.log("MATCHES: ", matches);

    if (matches.length === 0) {
      console.log("No key notes were matched.");
      return; // Exit function if no matches are found
    }

    // Process and store these key notes
    matches.forEach((match, index) => {
      const keyNoteText = match[1]; // Extracted text from each match
      console.log(`Processing match ${index + 1}: ${keyNoteText}`); // Debug each match processed
      toggleKeyNote({
        text: "Disconfirming Evidence: " + keyNoteText.trim(),
        id: (new Date().getTime() + index).toString(),
        author: "AI",
        type: "ai",
        date: new Date().toISOString(),
        tentative: true
      });
    });

    // Show the analyses
    setShowBullAnalysis(true);
    setShowBearAnalysis(true);
    setShowPersonalAnalysis(true);

    // insert delay for a few seconds before loading key notes      
    setTimeout(() => {
      loadKeyNotes();
    }, 50);
  };




  const formatNoteForStorage = (note) => {
    try {
      console.log("Parsing date:", note.date);
      const validDate = parseISO(note.date);
      if (isNaN(validDate)) {
        throw new Error('Invalid date value received');
      }
      const formattedDate = format(validDate, 'yyyy-MM-dd');
      console.log("Formatted date:", formattedDate);
      return {
        id: note.id || new Date().getTime().toString(),
        text: note.text || note.content,
        type: note.type || 'headline',
        date: formattedDate,
        ...(note.author && { author: note.author }),
        ...(note.likes && { likes: note.likes })
      };
    } catch (error) {
      console.error('Error formatting note:', error.message);
      return { ...note, date: 'Invalid date', id: note.id || new Date().getTime().toString() };
    }
  };

  const loadKeyNotes = async () => {
    const companyRef = doc(db, "users", auth.currentUser.uid, "keyNotes", companyName);
    const confirmedNotesSnapshot = await getDocs(collection(companyRef, "confirmed"));
    const deniedNotesSnapshot = await getDocs(collection(companyRef, "denied"));

    const deniedTexts = deniedNotesSnapshot.docs.map(doc => doc.data().text);
    const confirmedKeyNotes = confirmedNotesSnapshot.docs.map(doc => ({
      ...doc.data(),
      tentative: doc.data().tentative !== undefined ? doc.data().tentative : false
    }));

    const tentativeNotes = jsonData[companyName][mostRecentDate]?.key_notes?.filter(note =>
      !deniedTexts.includes(note.text) && !confirmedKeyNotes.some(confirmedNote => confirmedNote.text === note.text)
    ).map(note => ({
      ...note,
      tentative: true
    })) || [];  // Ensure an empty array is returned if key_notes is undefined

    setKeyNotes([...confirmedKeyNotes, ...tentativeNotes]);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   console.log("---STARTING LOAD KEY NOTES EFFECT!!!!!!");
  //   loadKeyNotes();
  //   setDownloadedTranscript(false);
  //   setTranscript('');
  //   setShowTranscript(false);
  // }, [companyName, db, jsonData]);


  useEffect(() => {
    console.log('Component re-rendered due to change in keyNotes:', keyNotes);
  }, [keyNotes]);

  useEffect(() => {
    console.log('CompanyPage re-rendered');
  });

  const confirmKeyNote = async (note) => {
    const formattedNote = formatNoteForStorage(note);

    // Ensure that formattedNote only contains data Firestore can serialize
    const updatedNote = {
      ...formattedNote,
      key_note_added: new Date().toISOString(), // Adding timestamp when confirming as key note
      tentative: false // Remove tentative flag
    };

    // Log the data to inspect it
    console.log("Attempting to save:", updatedNote);

    try {
      const keyNotesRef = doc(db, "users", auth.currentUser.uid, "keyNotes", companyName, "confirmed", updatedNote.id);
      await setDoc(keyNotesRef, updatedNote);
      const keyNotesRefsecond = doc(db, "users", auth.currentUser.uid, "keyNotes", companyName, "denied", updatedNote.id);
      await setDoc(keyNotesRefsecond, updatedNote);
      // Update state: remove any existing tentative note with the same ID, then add the updated note
      setKeyNotes(prevNotes => {
        const filteredNotes = prevNotes.filter(n => n.text !== note.text); // Remove tentative note if it exists
        return [...filteredNotes, updatedNote]; // Add the updated note
      });
    } catch (error) {
      console.error("Failed to save note:", error);
    }
  };



  const denyKeyNote = async (note) => {
    const formattedNote = formatNoteForStorage(note);
    setKeyNotes(keyNotes.filter(n => n.text !== note.text));
    // Update Firestore with new structure

    // check if the note is in the confirmed notes
    const confirmedNotesRef = doc(db, "users", auth.currentUser.uid, "keyNotes", companyName, "confirmed", formattedNote.id);
    // delete  if it exists
    await deleteDoc(confirmedNotesRef);

    const deniedNotesRef = doc(db, "users", auth.currentUser.uid, "keyNotes", companyName, "denied", formattedNote.id);
    await setDoc(deniedNotesRef, formattedNote);

    // Remove from tentative
  };

  const toggleKeyNote = async (note) => {
    console.log("Attempting to toggle note:", note.text);
    const existingNote = keyNotes.find(n => n.text === note.text);

    if (existingNote) {
      console.log("Note exists, deleting:", existingNote.text);
      try {
        await deleteDoc(doc(db, "users", auth.currentUser.uid, "keyNotes", companyName, "confirmed", existingNote.id));
        const filteredNotes = keyNotes.filter(n => n.id !== existingNote.id);
        setKeyNotes(filteredNotes);
        console.log("Updated keyNotes after deletion:", filteredNotes);
      } catch (error) {
        console.error("Failed to delete note:", error);
      }
    } else {
      console.log("Note does not exist, adding:", note.text);
      const updatedNote = {
        ...note,
        key_note_added: new Date().toISOString(),
        id: note.id || new Date().getTime().toString(),
        type: note.type || 'headline',
        date: note.date || new Date().toISOString()
      };

      try {
        await setDoc(doc(db, "users", auth.currentUser.uid, "keyNotes", companyName, "confirmed", updatedNote.id), updatedNote);
        const newNotesList = [...keyNotes, updatedNote];
        setKeyNotes(newNotesList);

        console.log("Updated keyNotes after addition:", newNotesList);
      } catch (error) {
        console.error("Failed to add note:", error);
      }
    }
  };





  const noteExists = (note, set) => {
    for (let n of set) {
      if (n.text === note.text) {
        return true;
      }
    }
    return false;
  };
  const noteExistsID = (note, set) => {
    if (!note.id) return false;  // Optional: Also check if the input note has an 'id'

    for (let n of set) {
      if (n.id && n.id === note.id) {
        return true;
      }
    }
    return false;
  };

  const renderHeadline = (headline) => {
    const isKey = noteExists(headline, keyNotes);

    return (
      <Paper
        key={headline.id || `fallback-${headline.text}-${new Date(headline.date).getTime()}`}
        elevation={isKey ? 10 : 0} // Shadow effect for key notes
        sx={{
          width: 250, // Set width of the paper
          minWidth: 250,
          maxWidth: 250, // Maximum width
          height: 200, // Fixed height
          margin: '7px',
          padding: 1.5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: isKey ? '0 0 5px 1.5px rgba(255, 165, 0, 0.6)' : '0 0 4px 1.5px rgb(0,0,0,0.15)'
        }}
      >
        <div style={{
          overflowY: 'auto', // Allow vertical scrolling
          flexGrow: 1, // Allows this div to grow and take available space
        }}>
          <Typography variant="body2" sx={{ fontSize: '0.96em' }}><strong>{formatDate(headline.date)}:</strong> {headline.text}</Typography>
        </div>

        <div style={{
          marginTop: '2.2px',
          marginLeft: '1.7px',
          display: 'flex',
          alignItems: 'center', justifyContent: 'space-between', marginBottom: '-10px'
        }}>
          <Typography sx={{ fontSize: '0.95em' }}><FavoriteIcon sx={{ opacity: '0.75', fontSize: '0.89em', marginBottom: '-1.5px' }} /> {headline.likes}</Typography>


          <IconButton
            variant="outlined"
            sx={{
              width: '20%', marginBottom: '3px', marginRight: '-3px',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } // Hover effect
            }}
            onClick={() => toggleKeyNote(headline)}>
            {isKey ? <FlagIcon /> : <FlagOutlinedIcon />}
          </IconButton>
        </div>
      </Paper>
    );
  };
  const renderNote = (note) => {
    const isKey = noteExists(note, keyNotes);

    return (
      <Paper
        key={note.id}
        elevation={isKey ? 10 : 0}
        sx={{
          width: 250,
          minWidth: 250,
          maxWidth: 250,
          height: 200,
          margin: '7px',
          padding: 1.5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: isKey ? '0 0 5px 1.5px rgba(0, 0, 255, 0.6)' : '0 0 4px 1.5px rgb(0,0,0,0.15)',
          overflow: 'hidden'
        }}
      >
        <div style={{ overflowY: 'auto', flexGrow: 1 }}>
          {editMode === note.id ? (
            <>
              <TextField
                value={editText}
                onChange={e => setEditText(e.target.value)}
                margin="normal"
                fullWidth
              />
              <TextField
                type="date"
                value={editDate.split('T')[0]}
                onChange={e => setEditDate(e.target.value)}
                margin="normal"
                fullWidth
              />
              <Button onClick={() => saveNoteChanges(note.id)} color="secondary">
                Save
              </Button>
            </>
          ) : (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>

                <Typography variant="body2" sx={{ fontSize: '0.96em' }}>
                  <strong>{formatDateSafely(new Date(note.date).toLocaleDateString())}:</strong> {note.text}
                </Typography>
                <div style={{
                  marginTop: '2.2px',
                  marginLeft: '1.7px',
                  display: 'flex',
                  alignItems: 'center', justifyContent: 'space-between', marginBottom: '-10px'
                }}>
                  <IconButton onClick={() => setEditMode(note.id)}><EditIcon /></IconButton>
                  <IconButton onClick={() => deleteNote(note.id)}><ClearIcon /></IconButton>
                  <IconButton
                    sx={{
                      align: 'right', marginBottom: 0.5,
                      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } // Hover effect
                    }}
                    onClick={() => toggleKeyNote(note)}
                  >
                    {isKey ? <FlagIcon /> : <FlagOutlinedIcon />}
                  </IconButton>
                </div>
              </div>
            </>
          )}
        </div>

      </Paper>
    );
  };


  const sortedKeyNotes = useMemo(() => {
    return [...keyNotes].sort((a, b) => {
      if (a.tentative && !b.tentative) {
        return -1;
      } else if (!a.tentative && b.tentative) {
        return 1;
      }
      // If both are tentative, sort by 'date' property
      if (a.tentative && b.tentative) {
        if (a.date && b.date) {
          return new Date(b.date) - new Date(a.date); // Most recent first
        }
        if (a.date && !b.date) {
          return -1; // A has a date and B doesn't, A goes first
        }
        if (!a.date && b.date) {
          return 1; // B has a date and A doesn't, B goes first
        }
        return 0; // Neither has a date, keep them in their original order
      }
      if (a.key_note_added && b.key_note_added) {
        return new Date(b.key_note_added) - new Date(a.key_note_added);
      }
      return 0;
    });
  }, [keyNotes]);


  const renderKeyNote = (note) => {
    const isTentative = note.isTentative;

    const borderColor = note.type.toLowerCase() === 'headline' ? 'rgba(255, 165, 0, 0.6)' : // Orange
      note.type.toLowerCase() === 'earnings' ? 'rgba(255, 0, 0, 0.6)' : // Red
        note.type.toLowerCase() === 'custom_note' ? 'rgba(0, 0, 255, 0.6)' : // Blue
          note.type.toLowerCase() === 'ai' ? 'black' : // Gradient simulation
            'rgba(0, 0, 0, 0.6)'; // Black

    const keyNotesStyles = {
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      height: 245,
      margin: '5px',
      padding: 1.5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxShadow: note.type.toLowerCase() === 'ai' ?
        `0 0 4px rgba(255, 105, 135, .5), 0 0 7px rgba(255, 85, 55, .5)` : // Specific multi-layer shadow for AI
        `0 0 3px 0.5px ${borderColor}`, // Single color shadow for others
      border: 'none',
      overflow: 'hidden',
      position: 'relative',
    };

    const textContainerStyles = {
      overflowY: 'auto',
      flexGrow: 1,
      padding: '8px'
    };

    const tagStyles = {
      backgroundColor: note.type.toLowerCase() === 'earnings' ? 'red' :
        note.type.toLowerCase() === 'headline' ? 'yellow' :
          note.type.toLowerCase() === 'custom_note' ? 'blue' : 'transparent',
      color: 'white',
      padding: '3px 8px',
      borderRadius: '8px',
      fontSize: '0.8em',
      opacity: '0.99', // Adjust opacity as needed
      margin: '0 0 8px auto', // right-aligned
      display: 'block',
      width: 'fit-content',
      background: note.type.toLowerCase() === 'disconfirming_evidence' || note.type.toLowerCase() === 'ai' ?
        'linear-gradient(to right, rgba(255, 105, 135, 0.85), rgba(255, 85, 55, 0.85))' : borderColor,
    };

    const parseNoteText = (text) => {
      if (text.startsWith('Disconfirming Evidence:')) {
        note.type = 'disconfirming_evidence';
        return text.replace('Disconfirming Evidence:', '').trim();
      }
      return text;
    };

    const getTagText = () => {
      switch (note.type.toLowerCase()) {
        case 'earnings':
          return 'EARNINGS';
        case 'disconfirming_evidence':
          return 'DISCONFIRMING EVIDENCE';
        case 'headline':
          return 'HEADLINE';
        case 'custom_note':
          return 'CUSTOM';
        default:
          return '';
      }
    };

    return (
      <Paper key={note.id} style={keyNotesStyles}>
        <div style={textContainerStyles}>
          {getTagText() && <div style={tagStyles}>{getTagText()}</div>}
          <Typography variant="body1" style={{ fontSize: '0.97em' }}>{parseNoteText(note.text)}</Typography>
          {(note.type.toLowerCase() !== 'earnings') && (
            <Typography variant="body2">Date: {format(new Date(note.date), 'MMM. d, yyyy')}</Typography>)}
        </div>
        <div style={{ textAlign: 'center', padding: '10px' }}>
          {note.tentative ? (
            <>
              <IconButton color="rgba(255,0,0,0.5)" onClick={() => confirmKeyNote(note)}><CheckCircleIcon /></IconButton>
              <IconButton color="rgba(0,255,0,0.5)" onClick={() => denyKeyNote(note)}><CancelIcon /></IconButton>
            </>
          ) : (
            <IconButton onClick={() => toggleKeyNote(note)}><FlagIcon /> </IconButton>
          )}
        </div>
      </Paper>
    );
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut(auth);
    navigate('/');  // Redirect to login page after sign out
  };

  useEffect(() => {
    const results = searchTerm ? Object.keys(jsonData).filter(company => {
      if (!jsonData[company]) return false;

      // Access the first date key available for the company
      const firstDateKey = Object.keys(jsonData[company])[0];
      const companyData = jsonData[company][firstDateKey];
      // Check if full_name exists and include it in the filter
      const matchesTicker = company.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFullName = companyData && companyData.full_name && companyData.full_name.toLowerCase().includes(searchTerm.toLowerCase());

      return matchesTicker || matchesFullName;
    }) : [];
    setFilteredCompanies(results);
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  useEffect(() => {
    const calculateRatios = async () => {
      const sortedEarnings = Object.keys(data).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
      const lastEarningsDate = sortedEarnings.find(date => isBefore(new Date(date), new Date(mostRecentDate)));

      if (lastEarningsDate) {
        const earningsData = data[lastEarningsDate];
        const previousDayPrice = data[mostRecentDate].previous_day_price;
        const dilutedShares = earningsData.diluted_shares;
        const ttmRevenue = earningsData.ttm_revenue;
        const ttmNetIncome = earningsData.ttm_net_income;

        if (previousDayPrice && dilutedShares && ttmRevenue && ttmNetIncome) {
          const marketCap = previousDayPrice * dilutedShares;
          setupSetPeRatio((marketCap / ttmNetIncome).toFixed(2));
          setupSetPTtmRevenue((marketCap / ttmRevenue).toFixed(2));
        }
      }
    };

    calculateRatios();
  }, [data, mostRecentDate]);


  const toggleStar = async () => {
    if (!auth.currentUser) return;
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    const docSnapshot = await getDoc(userDocRef);
    let currentStars = docSnapshot.exists() && docSnapshot.data().starredStocks ? docSnapshot.data().starredStocks : [];

    if (currentStars.includes(companyName)) {
      currentStars = currentStars.filter(stock => stock !== companyName); // Remove stock
    } else {
      currentStars.push(companyName); // Add stock
    }

    await setDoc(userDocRef, { starredStocks: currentStars }, { merge: true });
    setIsStarred(!isStarred);
  };


  const handleNoteChange = (id) => {
    const note = notes.find(n => n.id === id);
    setEditMode(id);
    setEditText(note.text);
    setEditDate(note.date);
  };

  const saveNoteChanges = async (id) => {
    const updatedNotes = notes.map(note => {
      if (note.id === id) {
        const validDate = new Date(editDate) > new Date() ? format(new Date(), 'yyyy-MM-dd') : editDate;
        return { ...note, text: editText, date: validDate };
      }
      return note;
    });

    const notesRef = doc(db, "users", auth.currentUser.uid, "notes", companyName);
    await setDoc(notesRef, { notes: updatedNotes, thesis: currentThesisId });
    setNotes(updatedNotes);

    // update the keyNotes
    if (noteExistsID(id, keyNotes)) {
      console.log("UPDATING CUSTOM NOTE IN KEY NOTES! One found!");
      const updatedKeyNotes = keyNotes.map(note => {
        if (note.id === id) {
          return { ...note, text: editText, date: editDate };
        }
        return note;
      });
      setKeyNotes(updatedKeyNotes);
      const keyNotesRef = doc(db, "users", auth.currentUser.uid, "keyNotes", companyName, "confirmed", id);
      await updateDoc(keyNotesRef, { text: editText, date: editDate });
    }

    setEditMode(null);
  };


  const handleAddOrUpdateNote = async (noteText, setAsThesis = false, type = 'custom_note') => {
    let newNote = {
      id: new Date().getTime().toString(),
      text: noteText,
      date: new Date().toISOString(),
      type: type // Default to 'custom_note' unless specified otherwise
    };

    if (type.toLowerCase() === 'custom_note') {
      newNote.author = auth.currentUser.displayName; // Set author name if necessary
    }

    const formattedNote = formatNoteForStorage(newNote);

    let updatedNotes = [...notes, formattedNote];

    let newThesisId = setAsThesis ? formattedNote.id : currentThesisId;
    if (setAsThesis && !currentThesisId) {
      newThesisId = formattedNote.id;
      setCurrentThesisId(newThesisId); // Set the newly added note as the current thesis
    }

    const notesRef = doc(db, "users", auth.currentUser.uid, "notes", companyName);
    await setDoc(notesRef, { notes: updatedNotes, thesis: newThesisId });

    setNotes(updatedNotes);
    setNewNoteText("");
    setEditMode(null); // Reset edit mode to ensure the text box disappears
  };

  const deleteNote = async (noteId) => {
    const updatedNotes = notes.filter(note => note.id !== noteId);
    const notesRef = doc(db, "users", auth.currentUser.uid, "notes", companyName);
    await setDoc(notesRef, { notes: updatedNotes, thesis: noteId === currentThesisId ? null : currentThesisId });
    setNotes(updatedNotes);
    if (noteId === currentThesisId) {
      setCurrentThesisId(null);
    }
  };

  const unsetThesis = async () => {
    const notesRef = doc(db, "users", auth.currentUser.uid, "notes", companyName);
    await updateDoc(notesRef, { thesis: null });
    setCurrentThesisId(null);
  };

  const handleSetThesis = async (id) => {
    const notesRef = doc(db, "users", auth.currentUser.uid, "notes", companyName);
    await setDoc(notesRef, { thesis: id }, { merge: true });
    setCurrentThesisId(id);
  };



  const headlineItemStyle = {
    width: '300px',
    minWidth: '300px',  // Ensuring minimum width
    height: '250px',
    margin: '0 10px',
    border: '1px solid #ccc',
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexShrink: 0  // This line ensures the item does not shrink
  };

  const containerStyle = {
    display: 'flex',
    minWidth: '100%', // Ensures the container is at least as wide as the viewport
    overflowX: 'auto'
  };

  const headlineContainerStyle = {
    display: 'flex',
    overflowX: 'scroll', // Ensure this is 'scroll' not 'auto'
    padding: '5px 0',
  };

  // Define your styles using the sx prop as before
  const headlineItemSx = {
    width: '300px',
    minWidth: '300px',
    height: '250px',
    margin: '0 10px',
    border: '1px solid #ccc',
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexShrink: 0,
  };

  const containerSx = {
    display: 'flex',
    minWidth: '100%',
    overflowX: 'auto',
  };

  const headlineContainerSx = {
    display: 'flex',
    overflowX: 'scroll',
    padding: '5px 0',
  };

  const paragraphStyle = {
    margin: '2px 0'  // Reduces vertical spacing to 2px above and below each paragraph
  };



  // Ensure 'tweets' is always an array
  useEffect(() => {
    fetch(`/tweets/by_company/${companyName}_tweets.json`)
      .then(response => response.json())
      .then(data => {
        const formattedHeadlines = data.map(tweet => ({
          id: tweet.id || new Date().getTime().toString(),
          text: tweet.text || '',
          type: 'headline',
          date: tweet.created_at || new Date().toISOString(),
          author: tweet.author_username || '',
          likes: tweet.likes || 0
        }));
        console.log("FORMATTED HEADLINES: ", formattedHeadlines)

        setHeadlines(Array.isArray(formattedHeadlines) ? formattedHeadlines : []);
      })
      .catch(error => {
        console.error('Error fetching tweets:', error);
        setHeadlines([]); // Set to empty array in case of error
      });
  }, [companyName]);


  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };


  const getEarningsDate = (ticker) => {
    const events = allUpcomingEvents[ticker];
    if (events) {
      const earningsEvent = events.find(event => event.event_name.toLowerCase().includes('earnings'));
      if (earningsEvent) {
        return earningsEvent.event_time === 'Time Not Supplied' ?
          { date: earningsEvent.event_date, estimate: true } :
          { date: earningsEvent.event_date, estimate: false };
      }
    }
    return { date: 'N/A', estimate: false };
  };

  const earningsInfo = getEarningsDate(companyName);


  const formatRevenue = (num) => {
    if (num == null) {
      return 'N/A';
    } else if (num >= 1e9) {
      return `$${(num / 1e9).toFixed(1)}B`;
    } else if (num >= 1e6) {
      return `$${(num / 1e6).toFixed(1)}M`;
    } else {
      return `$${num}`;
    }
  };
  const formatMarketCap = (num) => {
    if (!num) return 'N/A';
    if (num >= 1e12) return `$${(num / 1e12).toFixed(2)}T`;
    return `$${(num / 1e9).toFixed(1)}B`;
  };

  const formatPercentage = (num) => num ? `${num.toFixed(0)}%` : 'N/A';

  const formatYoYChange = (num) => {
    if (num === undefined || num === null) return '';
    const sign = num > 0 ? '+' : '-';  // Use the correct assignment operator '='
    return `(${sign}${Math.abs(num).toFixed(0)}%)`;
  };

  const formatYoYChangeNoParenthesis = (num) => {
    if (num === undefined || num === null) return '';
    if (num > 200) {
      const normalizedValue = (((num + 100) / Math.floor((num + 100) / 100)) - 100); // stock splits 
      return `${normalizedValue.toFixed(2)}%`;
    }
    const sign = num > 0 ? '+' : '-';  // Use the correct assignment operator '='
    return `${sign}${Math.abs(num).toFixed(1)}%`;
  };

  // Function to fetch whether the stock is starred and fetch notes
  useEffect(() => {
    const fetchData = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(db, "users", auth.currentUser.uid);
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          setIsStarred(docSnapshot.data().starredStocks.includes(companyName));
          const notesSnapshot = await getDoc(doc(db, "users", auth.currentUser.uid, "notes", companyName));
          if (notesSnapshot.exists()) {
            const notesData = notesSnapshot.data().notes || [];
            setNotes(notesData);
            setCurrentThesisId(notesSnapshot.data().thesis);
          } else {
            setNotes([]);
          }
        }
      }
    };
    fetchData();
  }, [companyName, db]);

  const formatDateSafely = (dateStr) => {
    const date = new Date(dateStr);
    return !isNaN(date) ? format(date, 'MMM dd, yyyy') : "No date";
  };


  const formatRelativeReturn = (num) => {
    if (num === undefined || num === null) return 'N/A';
    const sign = num > 0 ? '+' : '';
    return `${sign}${(num * 100).toFixed(1)}%`;
  };

  const formatOneDayPriceChange = (num) => {
    if (num === undefined || num === null) return 'N/A';
    const sign = num > 0 ? '+' : '';
    return `${sign}${num.toFixed(1)}%`;
  };


  useEffect(() => {
    // This example assumes `auth.currentUser` changes are correctly handled elsewhere.
    onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
      if (!currentUser) {
        // navigate('/'); // Ensures redirection if user logs out or is not authenticated
      }
    });
  }, [navigate]);

  useEffect(() => {
    // Update selected data when the selected date changes
    const updateSelectedData = () => {
      const newSelectedData = data[selectedDate];
      if (newSelectedData) {
        setSelectedData(newSelectedData);
      }
    };

    updateSelectedData();
  }, [selectedDate]);


  const [selectedData, setSelectedData] = useState(data && data[selectedDate]);
  const [companyData, setCompanyData] = useState(null);
  // Fetch company data when companyName changes
  // useEffect(() => {
  //   const data = jsonData[companyName];
  //   console.log("SENSED COMPANY NAME CHANGED!!!!!!")
  //   if (data) {
  //     const sortedDates = Object.keys(data).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
  //     const mostRecentDate = sortedDates[0];
  //     setSelectedDate(mostRecentDate);
  //     setCompanyData(data);
  //     console.log("New company and date: ", companyName, mostRecentDate)
  //   } else {
  //     navigate('/not-found'); // Redirect to a not-found page or handle missing data gracefully
  //   }
  // }, [companyName, navigate]);

  const [isMinimized, setIsMinimized] = useState(false);

  const handleMinimizeClick = () => {
    setIsMinimized(!isMinimized);
  };

  const formatAIResponseText = (text) => {
    const lines = text.split('\n');
    const formattedItems = lines.map((item, index) => {
      // Determine if the line is a bullet point
      const isBulletPoint = item.trim().startsWith('-');
      const cleanText = isBulletPoint ? item.trim().substring(1).replace('**', '').replace('**', '').trim() : item.replace('**', '').replace('**', '').trim();
      // const cleanText = item.replace('- **', '').replace('**', '').trim();
      const splitIndex = cleanText.indexOf(':');
      const keyText = splitIndex > -1 ? cleanText.substring(0, splitIndex + 1) : ""; // Key text up to colon
      const restText = splitIndex > -1 ? cleanText.substring(splitIndex + 1).trim() : cleanText; // Text after the colon

      if (index === 0) { // First line with the icon
        return (
          <ListItem key={index} sx={{ pl: 0.5, mt: 0.5, mb: -1.25, display: isMinimized ? 'none' : 'flex' }}>
            <ListItemIcon sx={{ minWidth: 'auto', mr: 1, color: 'secondary.main', cursor: 'pointer' }}
              // onMouseOver={e => setShowTooltip(true)}
              // onMouseOut={e => setShowTooltip(false)}
              onClick={() => setIsMinimized(true)}>
              <AutoAwesome />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ component: 'div' }}>
              {keyText ? <strong>{keyText}</strong> : ''} {restText}
            </ListItemText>
          </ListItem>
        );
      } else if (isBulletPoint) { // Standard bullet points for other items, with indent
        return (
          <ListItem key={index} sx={{ pl: 4, alignItems: 'flex-start', mb: -0.1, display: 'flex' }}>
            <ListItemIcon sx={{ minWidth: 'auto', mr: 1, fontSize: '1.6em', color: 'black', verticalAlign: 'middle', mt: -0.45 }}>
              • {/* Unicode bullet point */}
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ component: 'div' }} sx={{ mb: -0.1, display: 'flex' }}>
              <strong>{keyText}</strong> {restText}
            </ListItemText>
          </ListItem>
        );
      } else { // Handle lines that do not start with a bullet point
        return (
          <ListItemText key={index} sx={{ pl: 4, mt: 0.5, display: 'flex' }}> {/* Slightly less indent for non-bullet text */}
            {item}
          </ListItemText>
        );
      }
    });

    // Filter out null entries if some lines do not conform to expected formats
    return <List dense>{formattedItems.filter(item => item)}</List>;
  };

  // Parsing function to extract numbers from financials strings
  const parseFinancials = (financialString) => {
    const regex = /(-?\d+\.?\d*%?)|N\/A/g;
    let values = financialString.match(regex);
    return values.map(value => {
      if (value === "N/A") {
        return value;
      } else if (value.includes('%')) {
        return parseFloat(value); // Keep percentages as they are for now.
      }
      return parseFloat(value); // Parse all other numbers.
    });
  };

  // Parsing each part of the financials
  const currentSetupValues = parseFinancials(fullTrade.financials.currentSetup);
  const numbersIntoPrint = parseFinancials(fullTrade.financials.numbersIntoPrint);
  const reportedValues = parseFinancials(fullTrade.financials.reported);
  console.log("SETZUP", currentSetupValues, numbersIntoPrint, reportedValues)


  const handleCompanySelect = (company) => {
    navigate(`/company/${company}`, { state: { company } });
    setSearchTerm('');  // Clear the search term to hide the search results
  };

  const marketCap = selectedData.next_day_price * selectedData.diluted_shares;

  const grossMargin = (selectedData.gross_profit / selectedData.revenue) * 100;
  const operatingMargin = (selectedData.operating_income / selectedData.revenue) * 100;

  const pTtmRevenue = marketCap / selectedData.ttm_revenue;
  const peRatio = marketCap / selectedData.ttm_net_income;

  const mostRecentData = data[mostRecentDate];
  // const setupStockReturn = formatOneDayPriceChange(mostRecentData.stock_return_since);
  const setupStockReturnVsQQQ = formatOneDayPriceChange(mostRecentData.stock_return_since - mostRecentData.qqq_return_since);
  const setupStockReturnVsSPY = formatOneDayPriceChange(mostRecentData.stock_return_since - mostRecentData.spy_return_since);


  const adjustedPeRatio = peRatio * (1 + mostRecentData.stock_return_since / 100);
  const adjustedPTtmRevenue = pTtmRevenue * (1 + mostRecentData.stock_return_since / 100);

  // const setupQqqReturn = formatOneDayPriceChange(mostRecentData.qqq_return_since);
  // const setupSpyReturn = formatOneDayPriceChange(mostRecentData.spy_return_since);

  const contentStyle = {
    marginTop: '40px' // Adjust based on your nav bar height
  };

  return (

    <div style={{ width: '100%', overflow: 'hidden', boxSizing: 'border-box', maxWidth: '100%', backgroundColor: '#f8f7f7', borderRadius: '8px' }}>

      <Paper style={{ maxWidth: '100%', backgroundColor: '#f8f7f7', borderRadius: '8px' }}>{/* Constrain width and handle overflow */}
        <Grid container spacing={2} sx={{ p: 5 }}>
          {/* FIRST LEVEL: NAME AN DATES */}
          <Grid item xs={12} md={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography variant="h5" style={{ marginRight: '30px' }}>
                  {jsonData[companyName] ? jsonData[companyName][Object.keys(jsonData[companyName])[0]].full_name : "Unknown"} ({companyName})

                  <IconButton size="large" sx={{
                    color: isStarred ? 'warning.main' : 'action.active', marginTop: '-5px', marginLeft: '5px'
                  }}>
                    {isStarred ? <Star fontSize="inherit" /> : <StarBorder fontSize="inherit" />}
                  </IconButton>
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', marginBottom: '6px' }}>
                  {sortedDates.map(date => (
                    <CustomButton
                      key={date}
                      disabled
                      variant={date === selectedDate ? "contained" : "outlined"}
                      // onClick={() => setSelectedDate(date)}
                      sx={{
                        backgroundColor: date === selectedDate ? "#C9C9C9" : getColorForPriceChange(data[date].price_change),
                      }}
                      bgcolor={getColorForPriceChange(data[date].price_change * 1.5)}
                    >
                      {formatDate(date)}
                    </CustomButton>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* ENTIRE LEFT HALFF!!!!  */}
          <Grid item xs={12} md={8.5}>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Paper sx={{
                  p: 2, boxShadow: showPersonalAnalysis ? '0 0 4px rgba(255, 105, 135, .5), 0 0 7px rgba(255, 85, 55, .5)' : 1,
                  transition: 'box-shadow 0.3s ease-in-out'
                }} >
                  {/* <Paper sx={{ p: 2, boxShadow: showPersonalAnalysis ? '0 0 6px rgba(255, 105, 135, .5), 0 0 10px rgba(255, 85, 55, .5)' :  hiddenTheses ? '0 0 1.5px rgba(255, 105, 135, .5), 0 0 8px rgba(255, 85, 55, .5)' : '0 0 3px rgba(255, 105, 135, .5), 0 0 4px rgba(255, 85, 55, .5)',
    transition: 'box-shadow 0.3s ease-in-out' }} > */}
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" gutterBottom style={{ marginRight: '18px', marginTop: '3px' }}>
                      Key Theses
                    </Typography>
                    <div style={{ pointerEvents: 'none', opacity: 0.5 }}>


                      <AI_Opinion
                        companyName={companyName}
                        keyDebates={selectedData.debates}
                        newsHeadlines={selectedData.headlines}
                        financialData='N/A'
                        numbersIntoPrint='N/A'
                        currentSetupNumbers='N/A'
                        bullThesis={cleanText(selectedData.street_cases?.bull)}
                        bearThesis={cleanText(selectedData.street_cases?.bear)}
                        personalThesis={personalThesis}
                        customNotes={notes}
                        onOpinionReceived={handleOpinionReceived}
                      />
                    </div>
                    {/* {showPersonalAnalysis && (
                      <Button
                        size="small"
                        // variant="outlined"
                        onClick={() => {
                          setShowBullAnalysis(false);
                          setShowBearAnalysis(false);
                          setShowPersonalAnalysis(false);
                          setHiddenTheses(true);
                        }}
                        sx={{
                          marginLeft: '15px',
                          color: 'primary.main',
                          borderColor: 'primary.main'
                        }}
                      >
                        Hide
                      </Button>
                    )}
                    {hiddenTheses && (
                      <Button
                        size="small"
                        // variant="outlined"
                        onClick={() => {
                          setShowBullAnalysis(true);
                          setShowBearAnalysis(true);
                          setShowPersonalAnalysis(true);
                          setHiddenTheses(false);
                        }}
                        sx={{
                          marginLeft: '15px',
                          color: 'primary.main',
                          borderColor: 'primary.main'
                        }}
                      >
                        Show
                      </Button>
                    )} */}
                  </Box>
                  <Box mt={2}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" style={{ marginRight: '6px', marginBottom: '10px', flexShrink: 0, width: '128px' }}>Street Bull Thesis: </Typography>
                      <Typography variant="h7" style={{ fontSize: '1.05rem', marginBottom: '10px' }} component="span">
                        {cleanText(selectedData.street_cases?.bull)}
                      </Typography>
                    </Box>
                    <Collapse in={showBullAnalysis}>
                      <List style={{ marginBottom: '-7px', marginTop: '-10px', marginLeft: '15px' }}>
                        {formatThesisText(bullAnalysis)}
                      </List>
                    </Collapse>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" style={{ marginRight: '6px', marginBottom: '10px', flexShrink: 0, width: '128px' }}>Street Bear Thesis: </Typography>
                      <Typography variant="h7" style={{ fontSize: '1.05rem', marginBottom: '10px' }} component="span">
                        {cleanText(selectedData.street_cases?.bear)}
                      </Typography>
                    </Box>
                    <Collapse in={showBearAnalysis}>
                      <List style={{ marginBottom: '-10px', marginTop: '-10px', marginLeft: '15px' }}>
                        {formatThesisText(bearAnalysis)}
                      </List>
                    </Collapse>
                    {(personalThesis !== "N/A") && (<Box display="flex" alignItems="center">
                      <Typography variant="body2" style={{ marginRight: '6px', marginBottom: '7px', flexShrink: 0, width: '128px' }}>Personal Thesis:</Typography>

                      <Typography variant="h7" style={{ fontSize: '1.05rem', marginBottom: '10px' }}>{personalThesis === "N/A" ? "" : `${personalThesis.text}`}</Typography>
                      <IconButton size="small" sx={{ marginBottom: '10px', marginLeft: 0.5, fontSize: '0.9em' }} onClick={() => handleNoteChange(currentThesisId)} disabled><EditIcon /></IconButton>
                      <IconButton size="small" sx={{ marginBottom: '10px', marginRight: 2.2, fontSize: '0.9em' }} onClick={() => deleteNote(currentThesisId)} disabled><ClearIcon /></IconButton>

                    </Box>)}
                    <Collapse in={showPersonalAnalysis}>
                      <List style={{ marginBottom: '-6px', marginTop: '-10px', marginLeft: '15px' }}>
                        {formatThesisText(personalAnalysis)}
                      </List>
                    </Collapse>
                  </Box>
                </Paper>
              </Grid>

              {/* AI CHAT ITEM  */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, boxShadow: displayingChat ? '0 0 6px rgba(255, 105, 135, .5), 0 0 10px rgba(255, 85, 55, .5)' : 1, transition: 'box-shadow 0.3s ease-in-out' }}>
                  {/* <Typography variant="h6">AI Chat</Typography> */}
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                    <GradientTextField
                      fullWidth
                      value={query}
                      // onChange={handleInputChange}
                      placeholder="Ask any question..."
                      multiline
                      minRows={1}
                      maxRows={4}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <GradientButton
                              disabled
                              startIcon={<AutoAwesome />}
                            >
                              {'Ask AI'}
                            </GradientButton>
                          </InputAdornment>
                        )
                      }}
                      className="gradient-input"
                      disabled
                    />
                    <Box sx={{ mt: -2.5, mb: 0, overflow: 'auto' }}>
                      {isMinimized && (
                        <Button onClick={handleMinimizeClick} startIcon={<KeyboardArrowRightIcon sx={{ color: 'secondary.main', mt: 1.3, ml: -1.8, paddingTop: 1 }} />}
                          sx={{
                            position: 'relative',
                            padding: '-30px -50px -50px -50px', // Adjust these values to center the hover effect around the icon
                            '&:hover': {
                              backgroundColor: 'rgba(0,0,0,0)', // Example hover effect
                              // Ensure the hover effect is centered by adjusting padding if necessary
                            }
                          }}>
                        </Button>
                      )}
                      <Collapse in={!isMinimized}>
                        {fullTrade.aiChat && formatAIResponseText(fullTrade.aiChat)}
                      </Collapse>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6" component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Debates
                    <Box>
                      <Button onClick={toggleQuotes}>{showQuotes ? 'Hide Quotes' : 'Show Quotes'}</Button>
                    </Box>
                  </Typography>
                  {selectedData.debates && selectedData.debates.map((debate, index) => (
                    <Box key={index}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: getColorForSentiment(selectedData.sentiments[index]),
                        p: 1,
                        borderRadius: 1.4,
                        boxShadow: 0.75,
                        mt: 0.3
                      }}>
                        <IconButton onClick={() => handleToggleDebate(index)}>
                          {openDebates[index] ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                        <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>{debate.title}</Typography>

                      </Box>
                      <Collapse in={openDebates[index] !== undefined ? openDebates[index] : showQuotes} sx={{ ml: 4.65, mr: 3 }}>
                        {debate.key_quotes.map((quote, quoteIndex) => (
                          <React.Fragment key={quoteIndex}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: 1, mt: 1.5, mb: 1.5 }}>
                              <Typography sx={{
                                '& em': { fontStyle: 'italic' },
                                '& strong': { whiteSpace: 'nowrap' }
                              }}>
                                “<em>{quote.quote}</em>” <strong>— {cleanSpeakerName(quote.speaker)}</strong>
                              </Typography>

                            </Box>

                          </React.Fragment>
                        ))}

                      </Collapse>
                    </Box>
                  ))}
                </Paper>

              </Grid>




            </Grid>
          </Grid>
          {/* SECOND LEVEL: KEY THESES AND FINANCIALS  */}


          {/* ENTIRE RiGHT HALFF!!!!  */}
          <Grid item xs={12} md={3.5}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Paper sx={{ p: 2, minHeight: 180 }}>
                  <Typography variant="h6">Into Print</Typography>
                  {numbersIntoPrint[0] !== 'N/A' && !isNaN(numbersIntoPrint[0]) && (
                    <Typography><strong>P/E:</strong> {numbersIntoPrint[0]}</Typography>
                  )}
                  {numbersIntoPrint[1] !== 'N/A' && !isNaN(numbersIntoPrint[1]) && (
                    <Typography><strong>P/TTM Revenue:</strong> {numbersIntoPrint[1]}</Typography>
                  )}
                  {numbersIntoPrint[3] !== 'N/A' && !isNaN(numbersIntoPrint[3]) && (
                    <Tooltip title={`as of ${formatDate(selectedDate)}`} placement='bottom'>
                      <Typography><strong>3M vs. QQQ:</strong> {numbersIntoPrint[3]}%</Typography>
                    </Tooltip>
                  )}
                  {numbersIntoPrint[5] !== 'N/A' && !isNaN(numbersIntoPrint[5]) && (
                    <Tooltip title={`as of ${formatDate(selectedDate)}`} placement='bottom'>
                      <Typography><strong>3M vs. SPY:</strong> {numbersIntoPrint[5]}%</Typography>
                    </Tooltip>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} md={12}>
                <Paper sx={{ p: 2, minHeight: 180 }}>
                  <Tooltip title={`as of ${formatDate(selectedDate)}`} placement='bottom'>
                    <Typography variant="h6">Financials</Typography>
                  </Tooltip>
                  <Typography><strong>Market Cap:</strong> {formatMarketCap(reportedValues[0])}</Typography>
                  <Typography>
                    <strong>Revenue:</strong> {'$'}{reportedValues[1]}B {' '}
                    <Typography component="span" sx={{ fontSize: '0.9rem', fontWeight: 'bold', color: 'darkblue' }}>
                      ({reportedValues[2] > 0 ? '+' : ''}{reportedValues[2]}%)
                    </Typography>
                  </Typography>
                  <Typography>
                    <strong>Gross Margin:</strong> {reportedValues[3]}%{' '}
                    <Typography component="span" sx={{ fontSize: '0.9rem', fontWeight: 'bold', color: 'darkblue' }}>
                      ({reportedValues[4] > 0 ? '+' : ''}{reportedValues[4]}%)
                    </Typography>
                  </Typography>
                  <Typography>
                    <strong>Op. Margin:</strong> {reportedValues[5]}%{' '}
                    <Typography component="span" sx={{ fontSize: '0.9rem', fontWeight: 'bold', color: 'darkblue' }}>
                      ({reportedValues[6] > 0 ? '+' : ''}{reportedValues[6]}%)
                    </Typography>
                  </Typography>
                  <Typography><strong>Shares YoY:</strong> {reportedValues[7]}%</Typography>
                  <Typography><strong>1-Day Price Change:</strong> {reportedValues[9] > 0 ? '+' : ''}{reportedValues[9]}%</Typography>
                </Paper>
              </Grid>


              <Grid item xs={12} md={12}>
                <Paper sx={{ p: 2, minHeight: 180 }}>
                  <Typography variant="h6">
                    Setup <span style={{ fontSize: '0.9rem' }}>(as of {formatDate(fullTrade.dateOpened)})</span>
                  </Typography>
                  {selectedDate === mostRecentDate && currentSetupValues[0] && currentSetupValues[0] !== 'N/A' && (
                    <Typography><strong>P/E:</strong> {currentSetupValues[0].toFixed(1)}</Typography>
                  )}
                  {selectedDate === mostRecentDate && currentSetupValues[1] && currentSetupValues[1] !== 'N/A' && (
                    <Typography><strong>P/TTM Revenue:</strong> {currentSetupValues[1].toFixed(1)}</Typography>
                  )}
                  <Tooltip title={`Since ${formatDate(selectedDate)}`} placement='bottom'>
                    <Typography><strong>Stock vs. QQQ:</strong> {(currentSetupValues[2] / 100).toFixed(1)}%</Typography>
                  </Tooltip>
                  <Tooltip title={`Since ${formatDate(selectedDate)}`} placement='bottom'>
                    <Typography><strong>Stock vs. SPY:</strong> {(currentSetupValues[3] / 100).toFixed(1)}%</Typography>
                  </Tooltip>
                </Paper>
              </Grid>



              {/*  KEY NOTES */}

              {/* <Grid item xs={12}>
                <Paper sx={{ p: 2, maxHeight: '400px', overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                    <Typography variant="h6" style={{ margin: '10px 0' }}>Key Notes</Typography>
                    <Button
                      onClick={toggleKeyNotesSection}
                      sx={{ ml: '50px' }}
                    >
                      {showKeyNotesSection ? 'Hide' : 'Show'}
                    </Button>
                  </div>

                  <Collapse in={showKeyNotesSection}>
                    <div style={{
                      display: 'flex', overflowX: 'auto',
                      // flexWrap: 'wrap',
                      // justifyContent: 'center',
                      padding: '5px'
                    }}>
                      {!isLoading ? keyNotes.map(renderKeyNote) : <Typography>Loading...</Typography>}
                    </div></Collapse>
                </Paper>
              </Grid> */}


            </Grid>
          </Grid>
          {/* ^^ ending tags for right half  */}



          {/* <Grid item xs={12}>
            <Paper sx={{ p: 2, overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ mb: 1, ml: 0 }}>Headlines</Typography>
                <Button
                  onClick={toggleHeadlinesSection}
                  sx={{ ml: '50px' }}
                >
                  {showHeadlinesSection ? 'Hide' : 'Show'}
                </Button>
              </div>
              <Collapse in={showHeadlinesSection}>
              <div style={{ display: 'flex', overflowX: 'scroll', maxWidth: '100%' }}>
                                          {headlines.map(renderHeadline)}
                                        </div>
              </Collapse>
            </Paper>
          </Grid> */}

          {/* <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', overflowX: 'auto', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'left' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ mb: 1 }}>Custom Notes</Typography>
                <Button
                  onClick={toggleCustomNotesSection}
                  sx={{ ml: '50px' }}
                >
                  {showCustomNotes ? 'Hide' : 'Show'}
                </Button>
              </div>
              <Collapse in={showCustomNotes}>
                <div style={{ display: 'flex', overflowX: 'auto' }}>
                  <Paper
                    sx={{
                      width: 250,
                      minWidth: 250,
                      maxWidth: 250,
                      height: 200,
                      margin: '7px',
                      padding: 1.5,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      boxShadow: '0 0 4px 1.5px rgb(0,0,0,0.15)',
                      overflow: 'hidden'
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      multiline
                      value={newNoteText}
                      onChange={e => setNewNoteText(e.target.value)}
                      placeholder="Add a new note..."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddOrUpdateNote(newNoteText)}
                    >
                      Create
                    </Button>
                  </Paper>
                  {notes.map(note => renderNote(note))}
                </div>
              </Collapse>

            </Paper>
          </Grid> */}

          <Grid item xs={12}>
            <Paper sx={{ p: 2, overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ mb: 1, ml: 0 }}>Headlines</Typography>
                <Tooltip title="For Subscribers Only" placement="left" arrow>
                  <span>
                    <Button
                      onClick={toggleHeadlinesSection}
                      sx={{ ml: '50px' }}
                      disabled
                    >
                      Show
                    </Button>
                  </span>
                </Tooltip>
              </div>
              <Collapse in={showHeadlinesSection} disabled>
                {/* <div style={{ display: 'flex', overflowX: 'auto' }}>
                        {headlines.map(renderHeadline)}
                    </div> */}
              </Collapse>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 2, overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ mb: 1 }}>Full Transcript</Typography>
                <Tooltip title="For Subscribers Only" placement="left" arrow>
                  <span>
                    <Button onClick={toggleTranscript} sx={{ ml: '50px' }} disabled> Show </Button>
                  </span>
                </Tooltip>

              </div>
              <Collapse in={showTranscript} disabled>
                {/* ## TODO: GET THE transcript of the company here  */}
                {/* ## display it in material ui beauty  */}
                {/* <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: insertParagraphs(formatLastPass(formatTranscript(transcript))) }} /> */}

              </Collapse>

            </Paper>
          </Grid>



        </Grid>
      </Paper>
    </div>

  );
}

export default CompanyPageMini;