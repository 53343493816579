import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db } from '../firebaseConfig';
import { set } from 'date-fns';

const Trade_Button = ({ open, handleClose, stockName, personalThesis, aiOpinion, aiChatQuestion, aiChat, infoDate, financials }) => {
  const [title, setTitle] = useState(`Long ${stockName} into Earnings`);
  const [dateOpened, setDateOpened] = useState(new Date().toISOString().slice(0, 10));
  const [dateClosed, setDateClosed] = useState('');
  const [price, setPrice] = useState('');
  const [priceClosed, setPriceClosed] = useState('');
  const [quantity, setQuantity] = useState('');
  const [prediction, setPrediction] = useState('');
  const [outcome, setOutcome] = useState('');
  const [tradeType, setTradeType] = useState('Long');

  const handleSave = async () => {
    const tradeData = {
      title,
      dateOpened,
      dateClosed,
      price: parseFloat(price),
      priceClosed: parseFloat(priceClosed),
      quantity: parseInt(quantity),
      prediction,
      outcome,
      stockName,
      tradeType,
      personalThesis,
      aiOpinion,
      aiChatQuestion,
      aiChat,
      infoDate, 
      financials,
      createdAt: serverTimestamp(),
      transactions: [{ // Add a transactions array that includes the initial transaction
        price: parseFloat(price),
        quantity: parseInt(quantity),
        type: tradeType === 'Long' ? 'Buy' : 'Sell', // If the trade is a long, the initial transaction is a buy, otherwise it's a sell
        date: dateOpened
      }]
    };

    try {
      await addDoc(collection(db, `users/${auth.currentUser.uid}/trades`), tradeData);
      setPrice('');
      setQuantity('');
      setPrediction('');
      setOutcome('');
      setTitle(`Long ${stockName} into Earnings`);
      handleClose();
    } catch (error) {
      console.error("Failed to save trade:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Add a New Trade</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="space-between" >
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          variant="standard"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <Box sx={{ flexGrow: 1, ml: 1, display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            sx={{
              mt: 2,
              mb: 2,
              mr: 1,
              bgcolor: tradeType === 'Long' ? 'rgba(25, 118, 210, 0.8)' : 'rgba(189, 189, 189, 0.7)',
              color: 'background.paper',
              fontSize: '0.855rem',
              '&:hover': { bgcolor: 'rgba(25, 118, 210, 1)' }
            }}
            variant="contained"
            onClick={() => setTradeType('Long')}
          >
            Long
          </Button>
          {/* <Tooltip title="Temporarily Disabled"> */}
            {/* <span> */}
                <Button
                sx={{
                    mt: 2,
                    mb: 2,
                    bgcolor: 'rgba(189, 189, 189, 0.7)',
                    color: 'background.paper',
                    fontSize: '0.855rem',
                    '&:hover': { bgcolor: 'rgba(189, 189, 189, 0.7)' } // No change on hover
                }}
                variant="contained"
                >
                Short
                </Button>
            {/* </span>
            </Tooltip> */}
        </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" my={2}>
          <TextField
            margin="dense"
            id="dateOpened"
            label="Date Opened"
            type="date"
            variant="standard"
            value={dateOpened}
            onChange={e => setDateOpened(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '48%' }}
          />
          <Tooltip title="For later">
            {/* <span> */}
              <TextField
                disabled
                margin="dense"
                id="dateClosed"
                label="Date Closed"
                type="text"
                variant="standard"
                value={''}
                onChange={e => setDateClosed(e.target.value)}
                sx={{ width: '48%' }}
              />
            {/* </span> */}
          </Tooltip>
        </Box>
        <Box display="flex" justifyContent="space-between" my={2}>
          <TextField
            margin="dense"
            id="price"
            label="Price Opened ($)"
            type="text"
            variant="standard"
            value={price}
            onChange={e => setPrice(e.target.value)}
            sx={{ width: '48%' }}
          />
          <Tooltip title="For later">
            {/* <span> */}
              <TextField
                disabled
                margin="dense"
                id="priceClosed"
                label="Price Closed ($)"
                type="text"
                variant="standard"
                value={priceClosed}
                onChange={e => setPriceClosed(e.target.value)}
                sx={{ width: '48%' }}
              />
            {/* </span> */}
          </Tooltip>
        </Box>
        <TextField
          margin="dense"
          id="quantity"
          label="Quantity"
          type="number"
          fullWidth
          variant="standard"
          value={quantity}
          onChange={e => setQuantity(e.target.value)}
        />
        <TextField
          margin="dense"
          id="prediction"
          label="What I Think Will Happen:"
          type="text"
          fullWidth
          multiline
          maxRows={4}
          variant="standard"
          value={prediction}
          onChange={e => setPrediction(e.target.value)}
        //   sx={{ minHeight: '100px' }}
        />
        <Tooltip title="For later">
          <span>
            <TextField
              disabled
              margin="dense"
              id="outcome"
              label="What Actually Happened:"
              type="text"
              fullWidth
              multiline
              maxRows={4}
              variant="standard"
              value={outcome}
              onChange={e => setOutcome(e.target.value)}
            //   sx={{ minHeight: '100px' }}
            />
          </span>
        </Tooltip>
      </DialogContent>
      <DialogActions>
        
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Trade_Button;
