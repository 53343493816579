// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { app } from './firebaseConfig'; // Ensure Firebase is initialized
import posthog from 'posthog-js'

posthog.init('phc_W6dUZmF6IN6K9P7iiX678Ks5A2ufsoGJ26BiliRmQxy',
  {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
  }
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);


