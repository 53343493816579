// firebaseConfig.js
import { getApps, getApp, initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDmFH8OT2etXScq7u13sqcImkMZa7tmwx4",
  authDomain: "calypso-56f64.firebaseapp.com",
  projectId: "calypso-56f64",
  storageBucket: "calypso-56f64.appspot.com",
  messagingSenderId: "698107973724",
  appId: "1:698107973724:web:b2e22099196bf4d505d699",
  measurementId: "G-TGQ3GP6RRC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
const functions = getFunctions(app);
// const facebookProvider = new FacebookAuthProvider();

export { app, auth, provider, db, functions };
