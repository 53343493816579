import React, { useEffect, useState } from "react";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { doc, getDoc, setDoc, query, collection, where, getDocs, getFirestore, Timestamp } from "firebase/firestore";
import { auth } from '../firebaseConfig'; // Adjust path as needed
import { Button, CircularProgress } from '@mui/material';
import AutoAwesome from '@mui/icons-material/AutoAwesome'; // A possible "AI sparkle" icon
import { styled } from '@mui/material/styles';

// Styled Button with gradient and hover effect
const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  // background: 'black',
  color: 'white',
  transition: '0.5s',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
}));

const AI_Opinion = ({
    companyName = '',
    keyDebates = '',
    newsHeadlines = '',
    financialData = '',
    numbersIntoPrint = '',
    currentSetupNumbers = '',
    bullThesis = '',
    bearThesis = '',
    personalThesis = '',
    customNotes = '',
    onOpinionReceived: handleOpinionReceived = () => {}
  }) => {
  const [opinion, setOpinion] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpinionAvailable, setIsOpinionAvailable] = useState(false);
  const [opinionCountToday, setOpinionCountToday] = useState(0);
  const [isProfessional, setIsProfessional] = useState(false); // State to keep track of professional status
  const db = getFirestore();
  
  useEffect(() => {
    const checkOpinions = async () => {
        if (!auth.currentUser) return;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const userRef = doc(db, "users", auth.currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && userDoc.data().professional) {
          setIsProfessional(true);
        }

        const opinionsRef = collection(db, "users", auth.currentUser.uid, "opinions");
        const querySnapshot = await getDocs(query(opinionsRef, where("date", "==", today.toISOString().substring(0, 10))));

        let totalRequestsToday = 0;
        querySnapshot.forEach(doc => {
          totalRequestsToday += doc.data().countToday || 1; // Add each document's count or 1 if not specified
        });

        setOpinionCountToday(totalRequestsToday);

        const companyOpinionRef = doc(db, "users", auth.currentUser.uid, "opinions", companyName);
        const docSnap = await getDoc(companyOpinionRef);
        if (docSnap.exists()) {
            const isChat = false; 
            const isNew = false; 
            const hasQuestion = false;
            handleOpinionReceived(docSnap.data().text, isNew, isChat, hasQuestion);
            console.log("SENDDDDDDDDDDDING OPINION!");
            console.log("--new company opinion", docSnap.data().text);
            console.log("--new company", companyName);
            setIsOpinionAvailable(true);
        } else {
          handleOpinionReceived('', false, false, false);
        }
    };

    checkOpinions();
  }, [companyName, db]);

  
  const fetchAIResponse = async () => {
    if (loading) return;
    if (!companyName || !financialData) {
      console.error('Mandatory fields are missing');
      return;
    }
    console.log('Fetching AI Opinion with data:', {
        companyName,
        keyDebates,
        newsHeadlines,
        financialData,
        numbersIntoPrint,
        currentSetupNumbers,
        bullThesis,
        bearThesis,
        personalThesis,
        customNotes
      });

    
    
    setLoading(true);
    const today = new Date().toISOString().substring(0, 10);
    try {
      const functions = getFunctions();
      if (window.location.hostname === "localhost") {
        connectFunctionsEmulator(functions, "localhost", 5001);
      }

      const getAIOpinion = httpsCallable(functions, 'getAIOpinion');
      const result = await getAIOpinion({
        companyName,
        keyDebates,
        newsHeadlines,
        financialData,
        numbersIntoPrint,
        currentSetupNumbers,
        bullThesis,
        bearThesis,
        personalThesis,
        customNotes
      });

      console.log('AI Opinion Result:', result.data);
      if (result.data.error) {
        alert('AI Opinion Error: ' + result.data.error);
        console.error('AI Opinion Error:', result.data.error);
      } else {
        handleOpinionReceived(result.data, true, false, false);
        setOpinion(result.data);
        setIsOpinionAvailable(true);

        // Update Firestore with the new opinion
        const companyOpinionRef = doc(db, "users", auth.currentUser.uid, "opinions", companyName);
        const docSnap = await getDoc(companyOpinionRef);
        
        if (docSnap.exists() && docSnap.data().date === today) {
          // Document exists and is for today, increment the count
          await setDoc(companyOpinionRef, { text: result.data, timestamp: Timestamp.now(), date: today, countToday: (docSnap.data().countToday || 0) + 1 }, { merge: true });
        } else {
          // New document or existing document is not from today, reset the count
          await setDoc(companyOpinionRef, { text: result.data, timestamp: Timestamp.now(), date: today, countToday: 1 });
        }
        setOpinionCountToday(prev => prev + 1);
      }
    } catch (error) {
      console.error('Failed to fetch AI Opinion:', error);
      alert('Error fetching AI Opinion: ' + error.message);
    }
    setLoading(false);
  };
  
  // const formatOpinion = (opinion) => {
  //   return (
  //     // <div>
  //     //     <h4>AI Opinion Result</h4>
  //     //     <div>
  //     //         {opinion.split('\n').map((line, index) => (
  //     //             <p key={index}>{line}</p>
  //     //         ))}
  //     //     </div>
  //     // </div>
  //   );
  // };

  return (
    <GradientButton 
      onClick={fetchAIResponse} 
      disabled={loading || opinionCountToday >= (isProfessional ? 75 : 20)} 
      startIcon={loading ? <CircularProgress size={24} /> : <AutoAwesome />} 
      title={opinionCountToday >= (isProfessional ? 75 : 20) ? "Daily rate limit reached" : "AI Opinion"}
    >
      {loading ? 'Loading...' : 'AI Opinion'}
    </GradientButton>
  );
};

export default AI_Opinion;
