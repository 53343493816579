import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import format from 'date-fns/format';
import { doc, onSnapshot } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import jsonData from '../api_responses.json';
import { Table, TableBody, TableCell, TableContainer, Collapse, Tooltip, TableHead, TableRow, Paper, Box, Typography, IconButton, Alert, Button } from '@mui/material';
import NavigationBar from './NavigationBar';
import InfoIcon from '@mui/icons-material/Info';
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import { tableCellClasses } from '@mui/material/TableCell';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import eventsData from '../all_upcoming_events.json';
import allCalypsoTickers from '../all_calypso_tickers.json';



function EventsDashboard() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [starredStocks, setStarredStocks] = useState([]);
    const [eventFilter, setEventFilter] = useState('all'); // 'all', 'earningsCall', 'earningsRelease'
    const [sortedEvents, setSortedEvents] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'event_date', direction: 'ascending' });
    const [showStarredOnly, setShowStarredOnly] = useState(false);
    const [showSP500Only, setShowSP500Only] = useState(false);
    const [showNasdaq100Only, setShowNasdaq100Only] = useState(false);
    const [showEarningsOnly, setShowEarningsOnly] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const indexOfFirstEvent = currentPage * rowsPerPage;
    const indexOfLastEvent = indexOfFirstEvent + rowsPerPage;
    const currentEvents = sortedEvents.slice(indexOfFirstEvent, indexOfLastEvent);
    // const [hoveredRowIndex, setHoveredRowIndex] = useState(null); // State to track the hovered row index


    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };


    // Check button disabled status
    const isNextDisabled = indexOfLastEvent >= sortedEvents.length;
    const isPrevDisabled = currentPage === 0;

    useEffect(() => {
        console.log("USER CHANGED SOMEHOW");
        const unsubscribeAuth = auth.onAuthStateChanged(currentUser => {
            console.log(currentUser);  // Log the currentUser object to inspect it
            setUser(currentUser);
            if (!currentUser) {
                console.log("NO CURRENT USER, so clearing stocks");
                setStarredStocks([]);  // Clear starred stocks if user signs out
            }
        });

        if (user) {
            console.log("USER found");
            const userDoc = doc(db, "users", user.uid);
            const unsubscribe = onSnapshot(userDoc, (doc) => {
                const data = doc.data();
                console.log("data found: ", data)
                if (JSON.stringify(data?.starredStocks) !== JSON.stringify(starredStocks)) {
                    console.log("USER SETTING STARS ", data.starredStocks);
                    setStarredStocks(data ? data.starredStocks : []);
                }
            });

            return () => unsubscribe();
        }
        return () => unsubscribeAuth();
    }, [user]);

    // useEffect(() => {
    //     const unsubscribeAuth = auth.onAuthStateChanged(currentUser => {
    //         setUser(currentUser);
    //         if (currentUser) {
    //             const userDoc = doc(db, "users", currentUser.uid);
    //             onSnapshot(userDoc, (doc) => {
    //                 setStarredCompanies(doc.data()?.starredCompanies || []);
    //             });
    //         }
    //     });
    //     return () => unsubscribeAuth();
    // }, []);

    const handleSort = (key) => {
        setSortConfig(prevConfig => {
            const isAsc = prevConfig.key === key && prevConfig.direction === 'ascending';
            return { key, direction: isAsc ? 'descending' : 'ascending' };
        });
    };

    useEffect(() => {
        const today = new Date().setHours(0, 0, 0, 0);
        let filteredEvents = Object.entries(eventsData)
            .filter(([ticker]) => showStarredOnly ? (starredStocks && starredStocks.length > 0 && starredStocks.includes(ticker)) : true)
            .filter(([ticker]) => !showSP500Only || allCalypsoTickers[ticker]?.indices?.includes('SPY'))
            .filter(([ticker]) => !showNasdaq100Only || allCalypsoTickers[ticker]?.indices?.includes('QQQ'))
            .flatMap(([ticker, events]) => events
                // .filter(event => !event.event_name.includes('Earnings Call'))
                .map(event => ({
                    ...event,
                    ticker,
                    event_date: displayDate(event.event_date),
                    day_of_week: getDayOfWeek(event.event_date)
                })))
            .filter(event => {
                const eventDate = new Date(event.event_date).setHours(0, 0, 0, 0);
                return eventDate >= today && (
                    eventFilter === 'all' ||
                    (eventFilter === 'earningsCall' && event.event_name.includes('Earnings Call')) ||
                    (eventFilter === 'earningsRelease' && event.event_name.includes('Earnings Release'))
                );
            })
            .filter(event => !showEarningsOnly || (event.event_name.includes('Earnings Call') || event.event_name.includes('Earnings Release')));

        if (showEarningsOnly) {
            const uniqueEvents = {};
            filteredEvents.forEach(event => {
                const key = `${event.ticker}-${event.event_date}`;
                console.log("FILTERING: EVent", event)
                console.log("FILTERING: KEY", key)
                if (!uniqueEvents[key]) {
                    uniqueEvents[key] = event;
                } else if (!uniqueEvents[key].event_time.includes("Market")) {
                    uniqueEvents[key] = event;
                }
            });
            filteredEvents = Object.values(uniqueEvents);
        }

        filteredEvents.sort((a, b) => {
            if (sortConfig.key === 'event_date') {
                const dateA = new Date(a.event_date);
                const dateB = new Date(b.event_date);
                return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
            } else {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                return 0;
            }
        });

        setSortedEvents(filteredEvents);
    }, [starredStocks, eventFilter, sortConfig, showStarredOnly, showSP500Only, showNasdaq100Only, showEarningsOnly]);


    const getDayOfWeek = (dateStr) => {
        const date = new Date(dateStr + 'T12:00:00Z');
        return format(date, 'EEEE');
    };

    // NAV BAR BEGINNING
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const companies = Object.keys(jsonData);

    useEffect(() => {
        const results = searchTerm ? companies.filter(company => {
            if (!jsonData[company]) return false;
            const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
            const firstDateKey = sortedDates[0];
            const companyData = jsonData[company][firstDateKey];
            const matchesTicker = company.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFullName = companyData && companyData.full_name && companyData.full_name.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesTicker || matchesFullName;
        }) : [];
        setFilteredCompanies(results);
    }, [searchTerm, companies, jsonData]);
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleCompanySelect = (company) => {
        navigate(`/company/${company}`, { state: { company } });
        setSearchTerm('');  // Clear the search term to hide the search results
    };
    const handleSignOut = async () => {
        await signOut(auth);
        navigate('/');  // Redirect to login after sign out
    };
    const convertToISOFormat = (dateStr) => {
        if (!dateStr) return null; // Return null if dateStr is empty
        const parts = dateStr.split('-');
        if (parts.length !== 3) return null; // Return null if dateStr format is not as expected

        // Assuming date is in YY-MM-DD format and needs conversion to YYYY-MM-DD
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        const fullYear = year.length === 2 ? (parseInt(year, 10) > 50 ? `19${year}` : `20${year}`) : year;

        return `${fullYear}-${month}-${day}`; // Return the date in ISO format (YYYY-MM-DD)
    };

    const displayDate = (dateStr) => {
        try {

            const date = new Date(dateStr + 'T18:00:00Z');
            if (isNaN(date.getTime())) {
                console.error("Invalid Date Object from ISO String:", date); // More specific logging
                return 'Invalid date';
            }

            return format(date, 'MMM d, yyyy');
        } catch (error) {
            console.error(`Error in formatDate function: ${dateStr}`, error);
            return 'Invalid date';
        }
    }
    const formatDate = (dateStr) => {
        try {
            const isoDateStr = convertToISOFormat(dateStr);
            if (!isoDateStr) {
                console.error("Invalid ISO Date:", dateStr); // Log bad inputs
                return 'Invalid date';
            }

            const date = new Date(dateStr + 'T18:00:00Z');
            if (isNaN(date.getTime())) {
                console.error("Invalid Date Object from ISO String:", isoDateStr); // More specific logging
                return 'Invalid date';
            }

            return format(date, 'MMM d, yyyy');
        } catch (error) {
            console.error(`Error in formatDate function: ${dateStr}`, error);
            return 'Invalid date';
        }
    };


    // Row click
    const handleRowClick = (company) => {
        if (jsonData[company]) {
            window.scrollTo(0, 0);  // Scroll to the top of the window
            navigate(`/company/${company}`);
        } else {
            console.log('Ticker not found in API responses');
        }
    };

    const contentStyle = {
        marginTop: '77px' // Adjust based on your nav bar height
    };

    return (
        <div style={contentStyle}>
            <NavigationBar
                user={user}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                filteredCompanies={filteredCompanies}
                jsonData={jsonData}
                handleCompanySelect={handleCompanySelect}
                setShowUserMenu={setShowUserMenu}
                showUserMenu={showUserMenu}
                handleSignOut={handleSignOut}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1.5, mr: 3, ml: 3, mb: -1.5 }}>
                <Button variant="outlined" onClick={() => setShowStarredOnly(prev => !prev)} sx={{ mr: 2 }}>
                    {showStarredOnly ? 'Show All Companies' : 'Show Starred Companies'}
                </Button>
                <Button variant="outlined" onClick={() => setShowSP500Only(prev => !prev)} sx={{ mr: 2 }}>
                    {showSP500Only ? 'Show All Companies' : 'Show S&P 500'}
                </Button>
                <Button variant="outlined" onClick={() => setShowNasdaq100Only(prev => !prev)} sx={{ mr: 2 }}>
                    {showNasdaq100Only ? 'Show All Companies' : 'Show Nasdaq 100'}
                </Button>
                <Button variant="outlined" onClick={() => setShowEarningsOnly(prev => !prev)} sx={{ mr: 2 }}>
                    {showEarningsOnly ? 'Show All Events' : 'Show Earnings Only'}
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ mt: 3, mr: 3, ml: 3, maxWidth: '97%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => handleSort('ticker')} sx={{ cursor: 'pointer' }}>Company Ticker</TableCell>
                            <TableCell onClick={() => handleSort('event_name')} sx={{ cursor: 'pointer' }}>Event Name</TableCell>
                            <TableCell onClick={() => handleSort('event_date')} sx={{ cursor: 'pointer' }}>Event Date</TableCell>
                            <TableCell>Day of the Week</TableCell>
                            <TableCell>Event Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentEvents.map((event, index) => {
                            const isClickable = !!jsonData[event.ticker];
                            //    const rowClassName = isClickable ? 'clickable-row' : 'non-clickable-row';
                            let displayTime = event.event_time;
                            if (event.event_time === 'TAS') {
                                const sameDayEvents = eventsData[event.ticker].filter(e => e.event_date === event.event_date && e.event_time !== 'TAS');
                                // Consider expanding this list if there are other valid times
                                const validTimes = ['Before Market Open', 'After Market Close', 'Some Other Time'];
                                const replacementTime = sameDayEvents.find(e => validTimes.includes(e.event_time));

                                displayTime = replacementTime ? replacementTime.event_time : 'On I.R. Website';
                            } else if (event.event_time === "Time Not Supplied") {
                                displayTime = "To Be Announced"
                            }

                            const fullCompanyName = allCalypsoTickers[event.ticker]?.full_company_name || event.ticker;
                            const displayName = `${fullCompanyName} (${event.ticker})`;

                            return (
                                <TableRow
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'action.hover', // Using theme values for consistency
                                            color: 'primary.main', // Change text color on hover
                                            cursor: 'pointer', // Only if row is clickable
                                        },
                                        cursor: isClickable ? 'pointer' : 'default', // Conditional cursor style
                                    }}
                                    onClick={isClickable ? () => handleRowClick(event.ticker) : null}
                                >
                                    <TableCell>{displayName}</TableCell>
                                    <TableCell>{event.event_name}</TableCell>
                                    <TableCell>
                                        {displayTime === 'To Be Announced' ? (
                                            <Tooltip title="Estimate" placement="top">
                                                <span>{`${event.event_date}*`}</span>
                                            </Tooltip>
                                        ) : (
                                            event.event_date
                                        )}
                                    </TableCell>
                                    <TableCell>{event.day_of_week}</TableCell>

                                    <TableCell>{displayTime}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                    <Button onClick={handlePreviousPage} disabled={currentPage === 0}>
                        Previous
                    </Button>
                    <Button onClick={handleNextPage} disabled={indexOfLastEvent >= sortedEvents.length}>
                        Next
                    </Button>
                </Box>
                <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                        Calypso only displays events at most 90 days in the future.
                    </Typography>
                </Box>
            </TableContainer>

        </div>
    );
}

export default EventsDashboard;
