import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import format from 'date-fns/format';
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import jsonData from '../api_responses.json';
import { Table, TableBody, TableCell, TableContainer, Button, Collapse, Tooltip, TableHead, TableRow, Paper, Box, Typography, IconButton, Alert } from '@mui/material';
import NavigationBar from './NavigationBar';
import InfoIcon from '@mui/icons-material/Info';
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import { tableCellClasses } from '@mui/material/TableCell';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import allUpcomingEvents from '../all_upcoming_events.json'; // Make sure to import your JSON correctly
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AI_MegaChat from './AI_MegaChat';



function Dashboard() {
    const [starredStocks, setStarredStocks] = useState([]);
    const [starredStocksDetails, setStarredStocksDetails] = useState([]);
    const [sortedItems, setSortedItems] = useState([]);
    const [user, setUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const companies = Object.keys(jsonData);
    const [showOptions, setShowOptions] = useState(false);
    const [personalThesisMap, setPersonalThesisMap] = useState({});
    const [tentativeKeyNotesMap, setTentativeKeyNotesMap] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [hoverStates, setHoverStates] = useState({}); // This will track hover state by company ticker or ID
    const [showFullDashboard, setShowFullDashboard] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const indexOfFirstCompany = currentPage * rowsPerPage;
    const indexOfLastCompany = indexOfFirstCompany + rowsPerPage;
    const currentCompanies = sortedItems.slice(indexOfFirstCompany, indexOfLastCompany);

    const windowWidth = useWindowWidth(); // Use custom hook
    const isMobile = windowWidth < 800;

    function useWindowWidth() {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      
        useEffect(() => {
          const handleResize = () => {
            setWindowWidth(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);

        
      
        return windowWidth;
    }
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
      };
      
      const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
      };
      
      const handleRowsPerPageChange = (number) => {
        setRowsPerPage(number);
        setCurrentPage(0); // Reset to the first page when changing the number of rows per page
      };

    const navigate = useNavigate();
    

    const requestSort = (key) => {
        console.log("Current sort config before setting:", sortConfig);
        setSortConfig(prevConfig => {
            const isAsc = prevConfig.key === key && prevConfig.direction === 'ascending';
            let newDirection = isAsc ? 'descending' : 'ascending';
            if (!prevConfig.key || prevConfig.key != key) {
                newDirection = 'descending'
            }
            console.log(`Requesting sort on ${key}. Current: ${prevConfig.direction}, New: ${newDirection}`);
            return { key, direction: newDirection };
        });
    };

    const toggleFullDashboard = () => {
        setShowFullDashboard(prev => !prev);
        setCurrentPage(0);  // Reset to the first page when toggling between full and starred dashboards
    };

    const getEarningsDate = (ticker) => {
        const events = allUpcomingEvents[ticker];
        if (events) {
            const earningsEvent = events.find(event => event.event_name.toLowerCase().includes('earnings'));
            if (earningsEvent) {
                return earningsEvent.event_time === 'Time Not Supplied' ? 
                    { date: earningsEvent.event_date, estimate: true } : 
                    { date: earningsEvent.event_date, estimate: false };
            }
        }
        return { date: 'N/A', estimate: false };
    };

    useEffect(() => {
        const results = searchTerm ? companies.filter(company => {
            if (!jsonData[company]) return false;
            const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
            const firstDateKey = sortedDates[0];
            const companyData = jsonData[company][firstDateKey];
            const matchesTicker = company.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFullName = companyData && companyData.full_name && companyData.full_name.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesTicker || matchesFullName;
        }) : [];
        setFilteredCompanies(results);
    }, [searchTerm, companies, jsonData]);

    useEffect(() => {
        const updateStarredStocksDetails = () => {
            const stocks = companies.map(companyKey => {
                const sortedDates = Object.keys(jsonData[companyKey]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
                const firstDateKey = sortedDates[0];
                const companyData = jsonData[companyKey][firstDateKey];
                const stock = {
                    companyName: companyData.full_name,
                    ticker: companyKey,
                    pe: calculatePE(companyKey),
                    opMargin: getOpMargin(companyKey),
                    pTtmRevenue: calculatePTtmRevenue(companyKey),
                    revenueYoy: getRevenueYoy(companyKey),
                    sharesYoy: getSharesYoy(companyKey),
                    bullThesis: cleanText(companyData.street_cases.bull),
                    bearThesis: cleanText(companyData.street_cases.bear),
                    date: getEarningsDate(companyKey).date,
                    expansionYoy: getOpMarginExpansion(companyKey)
                };
                console.log("re-populated a stock: ", stock);  // Log each stock to inspect values
                return stock;
            }).filter(stock => starredStocks.includes(stock.ticker));;
            console.log("about to try to set Starred stocks!");
            if (JSON.stringify(stocks) !== JSON.stringify(starredStocksDetails)) {
                console.log("setting Starred stocks!");
                // only include the stocks that are in starredStocks 
                // AAPL is the only one starred stocks but stocks ight have both AAPL and META
                // TODO 
                setStarredStocksDetails(stocks);
            }
           
        };
        // if (companies.length > 0) {
        //     updateStarredStocks();
        // }
        console.log("COMPANIE before updating starred: ", companies);
        updateStarredStocksDetails();
        console.log("COMPANIE after updating starred: ", companies);

        // setSortStars(false);
    }, [starredStocks]);

    const safelyFormatNumber = (number) => {
        return number ? number.toFixed(1) : 'N/A';
    };


    useEffect(() => {
        let items = []; 
        console.log("STARRED STOCKS BEFORE Sorting: ", starredStocksDetails);
        if (showFullDashboard){
            items.push(...companies.map(companyKey => {
                const sortedDates = Object.keys(jsonData[companyKey]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
                const firstDateKey = sortedDates[0];
                const companyData = jsonData[companyKey][firstDateKey];
                const stock = {
                    companyName: companyData.full_name,
                    ticker: companyKey,
                    pe: calculatePE(companyKey),
                    opMargin: getOpMargin(companyKey),
                    pTtmRevenue: calculatePTtmRevenue(companyKey),
                    revenueYoy: getRevenueYoy(companyKey),
                    sharesYoy: getSharesYoy(companyKey),
                    bullThesis: cleanText(companyData.street_cases.bull),
                    bearThesis: cleanText(companyData.street_cases.bear),
                    expansionYoy: getOpMarginExpansion(companyKey),
                    date: getEarningsDate(companyKey).date
                };
                console.log("re-populated a stock: ", stock);  // Log each stock to inspect values
                return stock;
            }));
        } else {
            items = JSON.parse(JSON.stringify(starredStocksDetails));
        }
        if (sortConfig.key) {
            console.log("STARTING a sort with the key of ", sortConfig.key);
            items.sort((a, b) => {
                if (sortConfig.key === 'date') {
                    // Convert date strings to Date objects for comparison
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
                } else {
                    const valueA = a[sortConfig.key] || 0;
                    const valueB = b[sortConfig.key] || 0;
            
                    if (valueA < valueB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (valueA > valueB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
            console.log("finished SORTING!!!!!! new order: ", items);
        } 
        console.log("ABOUT TO SET SORTED ITEMS!!!!!!!!!!!!!!!!!!!!!!!!!", items);
        setSortedItems(items);
        console.log("LINE 116: THIS IS WHERE IT CRASHES FOR SOME REASON???")
    }, [starredStocksDetails, sortConfig, showFullDashboard]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const convertToISOFormat = (dateStr) => {
        if (!dateStr) return null; // Return null if dateStr is empty
        const parts = dateStr.split('-');
        if (parts.length !== 3) return null; // Return null if dateStr format is not as expected
      
        // Assuming date is in YY-MM-DD format and needs conversion to YYYY-MM-DD
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        const fullYear = year.length === 2 ? (parseInt(year, 10) > 50 ? `19${year}` : `20${year}`) : year;
      
        return `${fullYear}-${month}-${day}`; // Return the date in ISO format (YYYY-MM-DD)
      };
      
      const displayDate = (dateStr) => {
        try {   
            
            const date = new Date(dateStr + 'T12:00:00Z');
            if (isNaN(date.getTime())) {
              console.error("Invalid Date Object from ISO String:", date); // More specific logging
              return 'Invalid date';
            }
      
            return format(date, 'MMM d, yyyy');
          } catch (error) {
            console.error(`Error in formatDate function: ${dateStr}`, error);
            return 'Invalid date';
          }
    }

    const formatDate = (dateStr) => {
        try {
          const isoDateStr = convertToISOFormat(dateStr);
          if (!isoDateStr) {
            console.error("Invalid ISO Date:", dateStr); // Log bad inputs
            return 'Date N/A';
          }
    
          const date = new Date(isoDateStr);
          if (isNaN(date.getTime())) {
            console.error("Invalid Date Object from ISO String:", isoDateStr); // More specific logging
            return 'Invalid date';
          }
    
          return format(date, 'MMM d, yyyy');
        } catch (error) {
          console.error(`Error in formatDate function: ${dateStr}`, error);
          return 'Invalid date';
        }
      };

    const calculatePE = (company) => {
        const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
        const mostRecentDate = sortedDates[0];
        const mostRecentData = jsonData[company][mostRecentDate];
        return ((mostRecentData.next_day_price * mostRecentData.diluted_shares) / mostRecentData.ttm_net_income) * ((1 + (mostRecentData.stock_return_since / 100)));
    };
    const calculatePTtmRevenue = (company) => {
        const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
        const mostRecentDate = sortedDates[0];
        const mostRecentData = jsonData[company][mostRecentDate];
        return ((mostRecentData.next_day_price * mostRecentData.diluted_shares) / mostRecentData.ttm_revenue) * ((1 + (mostRecentData.stock_return_since / 100)));
    };
    const getOpMargin = (company) => {
        const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
        const mostRecentDate = sortedDates[0];
        const mostRecentData = jsonData[company][mostRecentDate];
        return (mostRecentData.operating_income / mostRecentData.revenue) * 100;
    };
    const getOpMarginExpansion = (company) => {
        const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
        const mostRecentDate = sortedDates[0];
        const mostRecentData = jsonData[company][mostRecentDate];
        return (mostRecentData.yoy_change_operating_margin);
    };
    const getSharesYoy = (company) => {
        const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
        const mostRecentDate = sortedDates[0];
        const mostRecentData = jsonData[company][mostRecentDate];
        return (mostRecentData.yoy_change_shares) * 100;
    };
    const getRevenueYoy = (company) => {
        const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
        const mostRecentDate = sortedDates[0];
        const mostRecentData = jsonData[company][mostRecentDate];
        return (mostRecentData.yoy_change_revenue) * 100;
    }



    const handleRowClick = (company) => {
        window.scrollTo(0, 0);  // Scroll to the top of the window
        navigate(`/company/${company}`);
    };

    const navigateToAccount = () => {
        navigate('/account');
        setShowOptions(false);
    };

    const formatYoYChangeNoParenthesis = (num) => {
        if (num === undefined || num === null) return '';
        if (num > 70000) {
            const normalizedValue = (((num + 10000) / Math.floor((num + 10000) / 10000))-10000)/100; // stock splits 
            const sign = num > 0 ? '' : '(';  // Use the correct assignment operator '='
            const sign2 = num < 0 ? ')' : '';
            return `${sign}${normalizedValue.toFixed(2)}%${sign2}`;
        }
        const sign = num >= -0.5 ? '' : '(';  // Use the correct assignment operator '='
        const sign2 = num < 0 ? ')' : '';  // Use the correct assignment operator '='
        // ## if num is NaN
        if (isNaN(num)) {
            return 'N/A';
        }

        return `${sign}${Math.abs(num/100).toFixed(1)}%${sign2}`;
      };

      const formatOpMargin = (num) => {
        if (num === undefined || num === null) return '';
        const sign = num > -0.5 ? '' : '(';  // Use the correct assignment operator '='
        const sign2 = num < 0 ? ')' : ''; 
        return `${sign}${Math.abs(num).toFixed(0)}%${sign2}`;
      };
      const formatOpMarginExpansion = (num) => {
        if (num === undefined || num === null) return '';
        const sign = num > 0 ? '+' : '(';  // Use the correct assignment operat or '='
        const sign2 = num < 0 ? ')' : ''; 
        if (isNaN(num) || num === null || num === undefined) {
            return 'N/A';
        }
        return `${sign}${Math.abs(num).toFixed(1)}%${sign2}`;
      };

    const handleSignOut = async () => {
        await signOut(auth);
        navigate('/');  // Redirect to login after sign out
    };

    useEffect(() => {
        console.log("Setting up auth state listener");
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                console.log("Authenticated user:", currentUser);
                setUser(currentUser);  // Set the authenticated user to state
            } else {
                console.log("No user authenticated");
                setUser(null);  // Set user to null if not authenticated
            }
        });
    
        // Cleanup function to unsubscribe from the listener when the component unmounts
        return () => {
            console.log("Cleaning up auth listener");
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!user) {
            console.log("User is not logged in or not set.");
            return;
        }
    
        console.log("---------Setting up listener for starred stocks for user:", user.uid);
        const userDocRef = doc(db, "users", user.uid);
    
        // This will listen for any change in the user's document
        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            const data = doc.data();
            console.log("---------****Received updated data from Firestore:", data);
            
            // Check specifically for changes in starredStocks
            if (data && Array.isArray(data.starredStocks)) {
                console.log("---------********$$$$Updating local starred stocks based on Firestore changes:", data.starredStocks);
                setStarredStocks(data.starredStocks);
            }
            // if there is no data in starred stocks, then set showflldashboard to true
            if ((data && !data.starredStocks) || data.starredStocks.length === 0) {
                setShowFullDashboard(true);
            }
        });
    
        // Cleanup function to unsubscribe when the component unmounts or user logs out
        return () => {
            console.log("---------*!*##$*#Cleaning up: unsubscribing from Firestore updates.");
            unsubscribe();
        };
    }, [user, db]);  


    const handleCompanySelect = (company) => {
        navigate(`/company/${company}`, { state: { company } });
        setSearchTerm('');  // Clear the search term to hide the search results
      };

      // Fetch personal thesis and tentative key notes for each company when user or companies change
    useEffect(() => {
        if (user) {
            const fetchNotesData = async () => {
                let newPersonalThesisMap = {};
                let newTentativeKeyNotesMap = {};
                for (const company of starredStocks) {
                    // Fetch personal thesis
                    const personalThesis = await getPersonalThesis(company, db);
                    newPersonalThesisMap[company] = personalThesis;

                    // Fetch tentative key notes existence
                    const hasTentativeKeyNotes = await discoverTentativeKeyNotes(company, db);
                    newTentativeKeyNotesMap[company] = hasTentativeKeyNotes;
                }
                setPersonalThesisMap(newPersonalThesisMap);
                setTentativeKeyNotesMap(newTentativeKeyNotesMap);
            };
            fetchNotesData();
        }
    }, [starredStocks]);

    async function getPersonalThesis(company, db) {
        // Fetch the company document
        const companyDocRef = doc(db, "users", auth.currentUser.uid, "notes", company);
        const docSnapshot = await getDoc(companyDocRef);
    
        // Check if the document exists and has the notes field
        if (docSnapshot.exists() && docSnapshot.data().notes) {
            const notes = docSnapshot.data().notes;
            // Find a note with personalThesisID set to true
            const personalThesisNote = notes.find(note => note.id === docSnapshot.data().thesis);
            return personalThesisNote ? personalThesisNote.text : "N/A";
        }
        return "N/A";
    }
    
    // Function to discover if there are any tentative key notes for a company
    async function discoverTentativeKeyNotes(company, db) {
        const keyNotesRef = collection(db, "users", auth.currentUser.uid, "keyNotes", company, "confirmed");
        const q = query(keyNotesRef, where("tentative", "==", true));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
        return;
    }

    const cleanText = (text) => {
        if (!text) {
            return "N/A";
        } else {
            // Normalize space around colons and hyphens, then trim whitespace
            text = text.replace(/['\(\)]/g, "")  // Remove parentheses and quotes
                .replace(/^:\s*|\s*:\s*$/g, "")  // Remove leading/trailing colons
                .trim();
    
            // Remove leading and trailing quotes
            text = text.replace(/^["']+|["']+$/g, "");
    
            return text;
        }
    };
      
    const [showTheses, setShowTheses] = useState(false);
    const handleShowTheses = () => {
        setShowTheses(!showTheses);
    }
    const [showDisconfirming, setShowDisconfirming] = useState(true);
    const handleShowDisconfirming = () => {
        setShowDisconfirming(!showDisconfirming);
    }

    const contentStyle = {
        marginTop: '77px' // Adjust based on your nav bar height
      };

      const [displayingChat, setDisplayingChat] = useState(false);
    //   const [aiChatAnswer, setAiChatAnswer] = useState("");
    //   const [aiChatQuestion, setAiChatQuestion] = useState("");


      const handleChatReceived = (opinionText, newResponse, questionText) => {
          console.log("Chat returned. Text: ", opinionText);
          setDisplayingChat(true);
        //   setAiChatAnswer(opinionText);
        //   setAiChatQuestion(questionText)
    
      };

    return (
        <div style={contentStyle}>
            
            <NavigationBar 
        user={user}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        filteredCompanies={filteredCompanies}
        jsonData={jsonData}
        handleCompanySelect={handleCompanySelect}
        setShowUserMenu={setShowUserMenu}
        showUserMenu={showUserMenu}
        handleSignOut={handleSignOut}
      />
{/* 
<Paper sx={{ p: 2, boxShadow: displayingChat ? '0 0 6px rgba(255, 105, 135, .5), 0 0 10px rgba(255, 85, 55, .5)' : 1, ml: 2, mr: 2, transition: 'box-shadow 0.3s ease-in-out' }}>
                 <Typography variant="h6">AI MEGA CHAT!!!!!!!!!</Typography>
                  <AI_MegaChat
                    onOpinionReceived={handleChatReceived}
                  />
                </Paper> */}



<Box sx={{ display: 'flex', alignItems: 'center', mt: 1.5, mr: 3, ml: 3, mb: -1.5 }}>
                <Button variant="outlined" onClick={toggleFullDashboard} sx={{ mr: 2 }}>
                    {showFullDashboard ? 'Show Starred Only' : 'Show All Companies'}
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ mt: 3, mr: 3, ml: 3, maxWidth: '97%' }}>

{/* <TableContainer component={Paper} sx= {{mt: 3, mr: 3, ml: 3, maxWidth: '97%'}}> */}
            
        <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => requestSort('companyName')}>Company Name</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => requestSort('pe')}>P/E</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => requestSort('opMargin')}>Operating Margin</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => requestSort('pTtmRevenue')}>P/TTM Revenue</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => requestSort('revenueYoy')}>Revenue Growth Rate YoY</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => requestSort('sharesYoy')}>Diluted Shares YoY</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => requestSort('expansionYoy')}>Operating Margin Expansion</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => handleShowTheses()}>Key Theses (Show/Hide)</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => handleShowDisconfirming()}>Disconfirming Evidence</TableCell>
                <TableCell style={{ top: 0, position: 'sticky', zIndex: 999, backgroundColor: '#fff', cursor: 'pointer' }} align="center" onClick={() => requestSort('date')}>Next Earnings Date</TableCell>
            </TableRow>
        </TableHead>
                    {/* <TableBody>
                        {starredStocks.map((company) => (
                            <TableRow
                            key={company}
                            sx={{ '&:hover': { backgroundColor: 'rgba(217, 217, 217, 0.49)', cursor: 'pointer' } }}
                            onClick={() => navigate(`/company/${company}`)}
                        >
                                <TableCell align="center">{jsonData[company][Object.keys(jsonData[company])[0]].full_name} ({company})</TableCell>
                                <TableCell align="center">{calculatePE(company).toFixed(1)}</TableCell>
                                <TableCell align="center">{formatOpMargin(getOpMargin(company))}</TableCell>
                                <TableCell align="center">{calculatePTtmRevenue(company).toFixed(1)}</TableCell>
                                <TableCell align="center">{formatYoYChangeNoParenthesis(getRevenueYoy(company))}</TableCell>
                                <TableCell align="center">{formatYoYChangeNoParenthesis(getSharesYoy(company))}</TableCell>
                                <TableCell align="left">
                                    <div> 
                                        <div>Street Bull Thesis: {cleanText(jsonData[company][Object.keys(jsonData[company])[0]].street_cases?.bull)}</div>
                                        <div>Street Bear Thesis: {cleanText(jsonData[company][Object.keys(jsonData[company])[0]].street_cases?.bear)}</div>
                                        <div>Personal Thesis: {personalThesisMap[company] || "Loading..."} </div>
                                    </div>
                                </TableCell>
                                <TableCell align="center">
                                    {tentativeKeyNotesMap[company] && <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning">Check Notes!</Alert>}
                                </TableCell>
                                <TableCell align="center">N/A</TableCell>
                            </TableRow>
                        ))}
                    </TableBody> */}
                    <TableBody>
                    {currentCompanies.map((company, index) => {
                            const earningsInfo = getEarningsDate(company.ticker);
                            return (

                        <TableRow
                            key={company.ticker || index}
                            sx={{ '&:hover': { backgroundColor: 'rgba(217, 217, 217, 0.49)', cursor: 'pointer' } }}
                            onClick={() => handleRowClick(company.ticker)}
                        >
                            <TableCell align="center">{company.companyName} ({company.ticker})</TableCell>
                            <TableCell align="center">{safelyFormatNumber(company.pe)}</TableCell>
                            <TableCell align="center">{formatOpMargin(company.opMargin)}</TableCell>
                            <TableCell align="center">{safelyFormatNumber(company.pTtmRevenue)}</TableCell>
                            <TableCell align="center">{formatYoYChangeNoParenthesis(company.revenueYoy)}</TableCell>
                            <TableCell align="center">{formatYoYChangeNoParenthesis(company.sharesYoy)}</TableCell>
                            <TableCell align="center">{formatOpMarginExpansion(company.expansionYoy)}</TableCell>
                            <TableCell align="left">
                            <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                                    {!showTheses && (
                                    <Typography variant="body2" style={{ margin: 'auto 0' }}>
                                        <VisibilityOffIcon />
                                    </Typography>
                                    )}
                                </Box>
                                <Collapse in={showTheses}>
                            <Box display="flex" alignItems="center">

                            <Typography variant="body3" style={{ marginRight: '5px', marginBottom: '2.5px', flexShrink: 0, width: '128px'}}>Street Bull Thesis: </Typography>
                      <Typography variant="body3" style={{ fontSize: '0.97rem', marginBottom: '7.5px' }} component="span">
                      {company.bullThesis || "Loading..."}
                      </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                      <Typography variant="body3" style={{ marginRight: '5px', marginBottom: '0px', flexShrink: 0, width: '128px' }}>Street Bear Thesis: </Typography>
                      <Typography variant="body3" style={{ fontSize: '0.97rem', marginBottom: '2.5px' }} component="span">
                      {company.bearThesis || "Loading..."}
                      </Typography>
                      </Box>
                      {!showFullDashboard && (
                      <Box display="flex" alignItems="center">
                      <Typography variant="body3" style={{ marginRight: '5px', marginTop: '2.5px', marginBottom: '2.5px', flexShrink: 0, width: '128px' }}>Personal Thesis: </Typography>
                      <Typography variant="body3" style={{ marginTop: '5px', fontSize: '0.97rem' }} component="span">
                      {personalThesisMap[company.ticker] || "Loading..."}
                      </Typography>
                      </Box>)}
                      </Collapse>

                                {/* Bull Thesis: {company.bullThesis || "Loading..."}
                                Bear Thesis: {company.bearThesis || "Loading..."}
                                Personal Thesis: {personalThesisMap[company.ticker] || "Loading..."}  */}
                            </TableCell>
                            <TableCell align="center"
                                onMouseEnter={() => setHoverStates(prev => ({ ...prev, [company.ticker]: true }))}
                                onMouseLeave={() => setHoverStates(prev => ({ ...prev, [company.ticker]: false }))}                                
                                style={{
                                    position: 'relative', // Needed for absolute positioning of the alert
                                    // display: 'flex', // Use flexbox for centering
                                    flexDirection: 'column', // Stack children vertically
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically
                                    height: '100%', // Take full height to center content properly
                                    // border: 'none', // Ensure no borders are affecting the appearance
                                    // padding: 0, // Remove padding to prevent layout shifts
                                }}
                            >
                                                                   {!showDisconfirming && (
                                    <Typography variant="body2" style={{ }}>
                                        <VisibilityOffIcon />
                                    </Typography>
                                    )}
                    
                                <Collapse in={showDisconfirming}  sx={{height: '100%'}}>
                                {/* {tentativeKeyNotesMap[company.ticker] && <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning">Check Notes!</Alert>} */}
                                {tentativeKeyNotesMap[company.ticker] &&
                                // <Tooltip title="Check Notes!" placement="top">
                                <Alert style={{ cursor: 'pointer', width: '20px', marginLeft: '28%'}} severity="warning" />
                                }
                                {hoverStates[company.ticker] && tentativeKeyNotesMap[company.ticker] && (
                                        <Alert
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                whiteSpace: 'nowrap',
                                                fontSize: 'small'
                                            }}
                                            severity="warning"
                                        >
                                            Check Key Notes!
                                        </Alert>
                                    )} 
                               </Collapse>
                                </TableCell>
                                <TableCell align="center">
                                        {earningsInfo.estimate ? (
                                            <Tooltip title="Estimate">
                                                <span>{displayDate(earningsInfo.date)}*</span>
                                            </Tooltip>
                                        ) : (
                                            <span>{displayDate(earningsInfo.date)}</span>
                                        )}
                                    </TableCell>
                        </TableRow>
                        );
                    })}
                </TableBody>
                </Table>
<Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
  <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%'  }}>
    <Button 
      onClick={handlePreviousPage} 
      disabled={currentPage === 0} // Disable if on the first page
      startIcon={<ArrowBackIosNewIcon />}
    >
      Previous
    </Button>
    <Button 
      onClick={handleNextPage} 
      disabled={indexOfLastCompany >= sortedItems.length} // Disable if no more companies to show
      endIcon={<ArrowForwardIosIcon />}
    >
      Next
    </Button>
  </Box>
  <Box sx={{
  display: 'flex',
  alignItems: 'center', // Ensures vertical alignment is centered
  justifyContent: 'flex-end',
  color: 'action.disabled', // Applies a grey color similar to disabled buttons
}}>
  <Typography sx={{ marginRight: 1 }}>Show:</Typography>
  <Button onClick={() => handleRowsPerPageChange(50)}  sx={{ color: 'text.tertiary' }}>
    50
  </Button>
  <Typography sx={{ marginX: 1 }}>|</Typography>
  <Button onClick={() => handleRowsPerPageChange(100)} sx={{ color: 'text.tertiary' }}>
    100
  </Button>
  <Typography sx={{ marginX: 1 }}>|</Typography>
  <Button onClick={() => handleRowsPerPageChange(200)} sx={{ color: 'text.tertiary' }}>
    200
  </Button>
</Box>
</Box>
            </TableContainer>
       
        </div>
    );
}

export default Dashboard;
