import React, { useEffect, useState } from 'react';
import { auth, db, functions } from '../firebaseConfig';
import format from 'date-fns/format';
import { doc, onSnapshot, collection, query, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import jsonData from '../api_responses.json';
import { Table, TableBody, FormControlLabel, IconButton, Checkbox, TableCell, TableContainer, Tooltip, Collapse, TableHead, TableRow, Paper, Box, Typography, Button, Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import NavigationBar from './NavigationBar';
import AddIcon from '@mui/icons-material/Add';  // Plus icon
import CheckIcon from '@mui/icons-material/Check';  // Checkmark icon
import EditIcon from '@mui/icons-material/Edit';  // Edit icon
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CompanyPageMini from './CompanyPageMini';
import CompanyPage from './CompanyPage';
import { httpsCallable } from 'firebase/functions';
import axios from 'axios';


import ShareIcon from '@mui/icons-material/Share';// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';



const useStyles = makeStyles({
    input: {
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.83) !important',
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.83) !important',
        },
    },
});



function TradesDashboard() {
    const classes = useStyles();


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // const SortIcon = ({ columnKey }) => {
    //     if (sortConfig.key !== columnKey) {
    //         return null;
    //     }
    //     return sortConfig.direction === 'ascending' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />;
    // };

    // Updated price
    // const getLastRow = httpsCallable(functions, 'getLastCsvRow');

    const [loading, setLoading] = useState(true);


    // NAV BAR ITEMS
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const companies = Object.keys(jsonData);
    const [dateOpened, setDateOpened] = useState(new Date().toISOString().slice(0, 10));


    useEffect(() => {
        const results = searchTerm ? companies.filter(company => {
            if (!jsonData[company]) return false;
            const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
            const firstDateKey = sortedDates[0];
            const companyData = jsonData[company][firstDateKey];
            const matchesTicker = company.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFullName = companyData && companyData.full_name && companyData.full_name.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesTicker || matchesFullName;
        }) : [];
        setFilteredCompanies(results);
    }, [searchTerm, companies, jsonData]);
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleCompanySelect = (company) => {
        navigate(`/company/${company}`, { state: { company } });
        setSearchTerm('');  // Clear the search term to hide the search results
    };
    const handleSignOut = async () => {
        await signOut(auth);
        navigate('/');  // Redirect to login after sign out
    };

    // TRADES DASHBOARD ITEMS 
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [trades, setTrades] = useState([]);
    const [openRowId, setOpenRowId] = useState(null);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [closeDialogOpen, setCloseDialogOpen] = useState(false);
    const [selectedTrade, setSelectedTrade] = useState(null);  // State to keep track of the trade being edited
    const [updateType, setUpdateType] = useState('Buy');
    const [openDetails, setOpenDetails] = useState({});  // State for collapse toggle

    // SORT FUNCTINALITY
    const [sortConfig, setSortConfig] = useState({ key: 'dateClosed', direction: 'ascending' });

    const sortedTrades = trades.sort((a, b) => {
        // Handling null or undefined dateClosed to appear first
        if (!a.dateClosed && b.dateClosed) return -1;
        if (a.dateClosed && !b.dateClosed) return 1;

        // If both are undefined or null, proceed to secondary sort
        if (!a.dateClosed && !b.dateClosed) {
            // console.log("BOTH DATES ARE NULL: ", a.stockName, b.stockName)
            if (new Date(a.dateOpened) > new Date(b.dateOpened)) return -1;
            if (new Date(a.dateOpened) < new Date(b.dateOpened)) return 1;
            return a.stockName.localeCompare(b.stockName); // Alphabetical order as last sort
        }

        // If dateClosed is defined for both, compare them
        const dateA = new Date(a.dateClosed);
        const dateB = new Date(b.dateClosed);
        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;

        // If dateClosed is the same, proceed to secondary sort by dateOpened
        if (new Date(a.dateOpened) > new Date(b.dateOpened)) return -1;
        if (new Date(a.dateOpened) < new Date(b.dateOpened)) return 1;

        // Finally, sort by stockName alphabetically if all else is the same
        return a.stockName.localeCompare(b.stockName);
    });


    // DELETE BUTTON
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [publishDialogOpen, setPublishDialogOpen] = useState(false);
    const [tradeToPublish, setTradeToPublish] = useState(null);
    const [tradeToDelete, setTradeToDelete] = useState(null);
    const handleDeleteTrade = async () => {
        if (tradeToDelete) {
            const tradeRef = doc(db, `users/${user.uid}/trades`, tradeToDelete.id);
            try {
                await deleteDoc(tradeRef);
                console.log("Trade deleted successfully!");
                setDeleteDialogOpen(false); // Close the dialog after deletion
            } catch (error) {
                console.error("Error deleting trade:", error);
            }
        }
    };

    // Toggle function for collapse sections
    const toggleDetails = (tradeId, section) => {
        const key = `${tradeId}-${section}`;
        setOpenDetails(prev => ({ ...prev, [key]: !prev[key] }));
    };

    // Example data retrieval and initial setup
    useEffect(() => {
        // Simulate fetching trades and initializing open state
        // const fetchedTrades = fetchTrades(); // This function would fetch trades
        // setTrades(fetchedTrades);
        const initialOpenDetails = {};
        trades.forEach(trade => {
            initialOpenDetails[`${trade.id}-transactions`] = true;
            initialOpenDetails[`${trade.id}-info`] = true;
        });
        setOpenDetails(initialOpenDetails);
    }, [trades]);


    // Handle opening the update dialog with trade details
    const handleOpenUpdateDialog = (trade) => {
        // selectedTrade.stockName = trade.stockName || 'N/A';
        setSelectedTrade(trade);
        setUpdateDialogOpen(true);
    };

    // Handle closing the update dialog and reset selected trade
    const handleCloseUpdateDialog = () => {
        setUpdateDialogOpen(false);
        setSelectedTrade(null);

    };

    const handleUpdateTrade = async () => {
        const priceInput = document.getElementById('price').value;
        const quantityInput = parseInt(document.getElementById('quantity').value, 10);
        const commentsInput = document.getElementById('comments').value;
        const dateInput = document.getElementById('dateOpened').value;

        const tradeRef = doc(db, `users/${user.uid}/trades`, selectedTrade.id);


        // Handle non-zero quantities, means adding to midTransactions
        const newTransaction = {
            price: priceInput,
            quantity: quantityInput,
            type: updateType, // 'Buy' or 'Sell'
            date: dateInput || Date().toISOString(), // Timestamp of the transaction
            comments: commentsInput
        };

        // Append new transaction to existing transactions
        const updatedTransactions = [...selectedTrade.transactions, newTransaction];

        try {
            await updateDoc(tradeRef, {
                transactions: updatedTransactions
            });
            if (selectedTrade.published_id) {
                const tradeRef2 = doc(collection(db, 'trades'), selectedTrade.published_id);
                await updateDoc(tradeRef2, {
                    transactions: updatedTransactions
                });
            }
            console.log("Trade updated successfully!");
        } catch (error) {
            console.error("Error updating trade:", error);
        }

        handleCloseUpdateDialog();
    };

    // Handle opening the close dialog
    const handleOpenCloseDialog = (trade) => {
        setSelectedTrade(trade);
        setCloseDialogOpen(true);
    };

    // Handle closing the close dialog and reset selected trade
    const handleCloseCloseDialog = () => {
        setSelectedTrade(null);
        setCloseDialogOpen(false);
        setDateOpened(new Date().toISOString().slice(0, 10));
    };
    const [editStates, setEditStates] = useState({});
    const [fieldValues, setFieldValues] = useState({});

    // Toggle between edit and save mode
    const toggleEdit = (field, tradeId) => {
        const isEditing = editStates[`${tradeId}_${field}`] || false;
        setEditStates({
            ...editStates,
            [`${tradeId}_${field}`]: !isEditing
        });

        // If toggling off edit mode (save mode), update Firestore
        if (isEditing) {
            const value = fieldValues[`${tradeId}_${field}`];
            saveField(tradeId, field, value);
        }
    };

    // Update field values
    const handleFieldChange = (field, tradeId, value) => {
        setFieldValues({
            ...fieldValues,
            [`${tradeId}_${field}`]: value
        });
    };

    // Save updated field to Firestore
    const saveField = async (tradeId, field, value) => {
        const tradeRef = doc(db, `users/${user.uid}/trades`, tradeId);
        try {
            await updateDoc(tradeRef, { [field]: value });
            if (selectedTrade.published_id) {
                const tradeRef2 = doc(collection(db, 'trades'), selectedTrade.published_id);
                await updateDoc(tradeRef2, { [field]: value });
            }
            console.log("Saved successfully!");
        } catch (error) {
            console.error("Error saving:", error);
        }
    };

    // Checkbox handler adjusted to correctly pass event and trade data
    const handleCheckboxChange = (event, tradeId) => {
        const checked = event.target.checked;  // Now correctly accessing 'checked'
        const tradeRef = doc(db, `users/${user.uid}/trades`, tradeId);
        updateDoc(tradeRef, { flagged_for_personal_AI: checked })
            .then(() => console.log("Firestore updated successfully!"))
            .catch(error => console.error("Error updating Firestore:", error));
        if (selectedTrade.published_id) {
            const tradeRef2 = doc(collection(db, 'trades'), selectedTrade.published_id);
            updateDoc(tradeRef2, { flagged_for_personal_AI: checked })
                .then(() => console.log("Firestore updated successfully!"))
                .catch(error => console.error("Error updating Firestore:", error));
        }
    };


    const renderTransactions = (transactions) => {
        // Sort transactions by date
        const sortedTransactions = transactions.sort((a, b) => new Date(a.date) - new Date(b.date));

        return sortedTransactions.map((tx, index) => {
            const dateStr = formatDate(tx.date);
            const action = tx.type === 'Buy' ? 'Purchased' : 'Sold';
            const quantity = tx.quantity || 'N/A';
            const stockName = tx.stockName || 'stock';
            const price = tx.price ? `$${tx.price}` : 'N/A per share';
            const comment = tx.comments ? `Comment: ${tx.comments}` : '';

            let transactionText = '';
            if (tx.type === 'Sell' && tx.specialType && tx.specialType === 'Close') {
                transactionText = `Closed trade at ${price}.`;
            } else if (tx.type === 'Note') {
                transactionText = comment;
            } else {
                transactionText = `${action} ${quantity} at ${price}. ${comment}`;
            }

            return (
                <div key={index} style={{ marginBottom: '1px' }}>
                    {index + 1}: <strong>{dateStr}:</strong> {transactionText}
                </div>
            );
        });
    };


    // Handle closing a trade
    const handleCloseTrade = async () => {
        const priceInput = document.getElementById('price').value;
        // const quantityInput = parseInt(document.getElementById('quantity').value, 10);
        const commentsInput = document.getElementById('comments').value;
        const dateInput = document.getElementById('dateOpened').value;

        const tradeRef = doc(db, `users/${user.uid}/trades`, selectedTrade.id);


        // Calculate the net quantity (sum of all buys minus all sells)
        const quantityInput = selectedTrade.transactions.reduce((acc, curr) => {
            return acc + (curr.type === 'Sell' ? -curr.quantity : curr.quantity);
        }, 0);
        // Handle non-zero quantities, means adding to midTransactions
        const newTransaction = {
            price: priceInput,
            quantity: quantityInput, // TODO: SUM OF ALL QUANTITIES, with any sales subtracted. 
            type: 'Sell', // 'Buy' or 'Sell'
            specialType: 'Close',
            date: dateInput || Date().toISOString(), // Timestamp of the transaction
        };

        // Append new transaction to existing transactions
        const updatedTransactions = [...selectedTrade.transactions, newTransaction];
        // TODO: Also priceClosed, dateClosed, and outcome in the tradeid with the input infrmation 
        // TODO: set flagged_for_personal_AI to true for the trade ID

        try {
            await updateDoc(tradeRef, {
                transactions: updatedTransactions,
                priceClosed: priceInput,
                dateClosed: dateInput,
                outcome: commentsInput,
                flagged_for_personal_AI: true // Set flagged for personal AI to true for the trade ID
            });
            if (selectedTrade.published_id) {
                const tradeRef2 = doc(collection(db, 'trades'), selectedTrade.published_id);
                await updateDoc(tradeRef2, {
                    transactions: updatedTransactions,
                    priceClosed: priceInput,
                    dateClosed: dateInput,
                    outcome: commentsInput,
                    flagged_for_personal_AI: true // Set flagged for personal AI to true for the trade ID
                });
            }
            console.log("Trade updated and closed successfully!");
        } catch (error) {
            console.error("Error updating trade:", error);
        }

        handleCloseCloseDialog();
    };

    const handlePublishTrade = async () => {
        if (tradeToPublish) {
            const tradeRef = doc(collection(db, 'trades'));
            const tradeId = tradeRef.id; // Firebase automatically generates a unique ID
            tradeToPublish.infoDate = tradeToPublish.infoDate.replace(/['"]+/g, ''); // Remove any quotes from the date string
            await setDoc(tradeRef, {
                ...tradeToPublish,
                published: true,
                publishDate: new Date(),
                publisher_name: user.displayName,
                stock_full_name: jsonData[tradeToPublish.stockName][tradeToPublish.infoDate.replace(/['"]+/g, '')].full_name,
                stock_debates: jsonData[tradeToPublish.stockName][tradeToPublish.infoDate.replace(/['"]+/g, '')].debates,
                stock_street_cases: jsonData[tradeToPublish.stockName][tradeToPublish.infoDate.replace(/['"]+/g, '')].street_cases,
                stock_sentiments: jsonData[tradeToPublish.stockName][tradeToPublish.infoDate.replace(/['"]+/g, '')].sentiments,
                averageCost: calculateAverageCost(tradeToPublish.transactions)
            });

            //update tradeToPublish with the newID 
            const tradeRef2 = doc(db, `users/${user.uid}/trades`, tradeToPublish.id);
            tradeToPublish.published_id = tradeId;
            await updateDoc(tradeRef2, {
                published_id: tradeId,
            });

            navigate(`/trade/${tradeId}`);
        }
    };

    const formatPercentage = (value) => {
        const formatted = parseFloat(value).toFixed(2);
        return formatted >= 0 ? `${formatted}%` : `(${Math.abs(formatted)}%)`;
    };

    const formatPnL = (value) => {
        // Parse the value to float and format it with two decimal places
        const parsedValue = parseFloat(value);
        const formattedValue = parsedValue.toFixed(2);

        // Create a number formatter for US locale to include commas
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        // Apply the formatter to the absolute value of the parsed value
        const formattedWithCommas = formatter.format(Math.abs(parsedValue));

        // Return formatted string with + for positive, and parentheses for negative
        return parsedValue >= 0 ? `+${formattedWithCommas}` : `(${formattedWithCommas})`;
    };


    const formatDateDisplay = (dateStr) => {
        try {
            return format(new Date(dateStr), 'MMM dd, yyyy');
        } catch (error) {
            console.error("Error formatting date:", error);
            return 'Invalid date';
        }
    };





    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged(currentUser => {
            setUser(currentUser);
            if (!currentUser) {
                navigate('/');
            }
        });
        return () => unsubscribeAuth();
    }, [navigate]);

    useEffect(() => {
        if (user) {
            const tradesRef = collection(db, `users/${user.uid}/trades`);
            const unsubscribe = onSnapshot(query(tradesRef), async (querySnapshot) => {
                const tradesPromises = querySnapshot.docs.map(async (doc) => {
                    const data = doc.data();
                    const defaultTransaction = {
                        price: data.price,
                        quantity: data.quantity,
                        type: 'Buy',
                        date: data.dateOpened
                    };
                    const trade = {
                        id: doc.id,
                        ...data,
                        transactions: data.transactions && data.transactions.length > 0 ? data.transactions : [defaultTransaction],
                        finalAmount: data.finalAmount || 0,
                        dateOpened: data.dateOpened || 'N/A',
                        stockName: data.stockName || 'N/A',
                        averageCost: calculateAverageCost(data.transactions),
                    };
                    console.log("NEAR CALCULATE LAST ROW: ", trade.stockName)
                    if (!trade.dateClosed) {
                        console.log("ABOUT TO SBUMIT REQUEST TO GET LAST ROW: ", trade.stockName)
                        const result = await getLastRow(trade.stockName);
                        trade.recentPrice = result?.data?.Close ? parseFloat(result.data.Close) : 0;
                    }
                    // Calculate return using the modified function
                    trade.return = calculateReturn(trade);
                    trade.irr = calculateIRR(trade);
                    trade.pnl = calculateNetPnL(trade);
                    console.log("LAST ROW: return, irr, pnl", trade.return, trade.irr, trade.pnl)
                    return trade;
                });

                // Wait for all promises to resolve
                const loadedTrades = await Promise.all(tradesPromises);
                setTrades(loadedTrades);
                setLoading(false);
            });
            return () => unsubscribe();
        }
    }, [user]);

    const handleRowClick = (id) => {
        setOpenRowId(openRowId === id ? null : id);
    };


    const convertToISOFormat = (dateStr) => {
        if (!dateStr) return null; // Return null if dateStr is empty
        const parts = dateStr.split('-');
        if (parts.length !== 3) return null; // Return null if dateStr format is not as expected

        // Assuming date is in YY-MM-DD format and needs conversion to YYYY-MM-DD
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        const fullYear = year.length === 2 ? (parseInt(year, 10) > 50 ? `19${year}` : `20${year}`) : year;

        return `${fullYear}-${month}-${day}`; // Return the date in ISO format (YYYY-MM-DD)
    };



    const formatDate = (dateStr) => {
        try {
            const isoDateStr = convertToISOFormat(dateStr);
            if (!isoDateStr) {
                console.error("Invalid ISO Date:", dateStr); // Log bad inputs
                return 'Date N/A';
            }

            const date = new Date(isoDateStr);
            if (isNaN(date.getTime())) {
                console.error("Invalid Date Object from ISO String:", isoDateStr); // More specific logging
                return 'Invalid date';
            }

            return format(date, 'MMM d, yyyy');
        } catch (error) {
            console.error(`Error in formatDate function: ${dateStr}`, error);
            return 'Invalid date';
        }
    };


    function calculateNetPnL(trade) {
        if (!jsonData[trade.stockName]) return 0;


        const filteredTransactions = trade.transactions.filter(tx => tx.specialType !== 'Close');
        const totalQuantity = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.quantity : curr.quantity), 0);

        if (totalQuantity === 0) totalQuantity = 1; // No holdings to compare returns against

        const totalCost = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.price * curr.quantity : curr.price * curr.quantity), 0);
        // const averageCost = totalCost / totalQuantity;

        // if (totalQuantity === 0) return 0; // Prevent division by zero (no holdings)
        if (trade.dateClosed) {
            const closedPrice = trade.priceClosed;
            const closedQuantity = trade.transactions.reduce((acc, curr) => acc + (curr.specialType === 'Close' ? curr.quantity : 0), 0);
            const closedProceeds = closedPrice * closedQuantity;
            return trade.tradeType === "Long" ? (closedProceeds - totalCost) : (totalCost - closedProceeds);
        } else {
            const currentMarketValue = totalQuantity * trade.recentPrice;
            return trade.tradeType === "Short" ? (totalCost + currentMarketValue) : (currentMarketValue - totalCost);
        }
    }


    function calculateAverageCost(transactions) {
        // Filter out transactions marked as 'Close'
        const filteredTransactions = transactions.filter(tx => tx.specialType !== 'Close');

        if (!filteredTransactions.length) return 0;

        const totalCost = filteredTransactions.reduce((acc, curr) => acc + (curr.price * curr.quantity), 0);
        const totalQuantity = filteredTransactions.reduce((acc, curr) => acc + curr.quantity, 0);

        return totalQuantity > 0 ? totalCost / totalQuantity : 0;
    }

    function calculateReturn(trade) {
        const filteredTransactions = trade.transactions.filter(tx => tx.specialType !== 'Close');
        const totalQuantity = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.quantity : curr.quantity), 0);

        if (totalQuantity === 0) return 1; // "No holdings to compare returns against"

        const totalCost = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.price * curr.quantity : curr.price * curr.quantity), 0);
        const averageCost = totalCost / totalQuantity;
        const tradeTypeFactor = trade.tradeType === "Short" ? -1 : 1;

        // Call cloud function to get the latest price
        if (!trade.dateClosed) {


            return tradeTypeFactor * ((trade.recentPrice - averageCost) / averageCost) * 100;
        } else {
            return tradeTypeFactor * ((trade.priceClosed - averageCost) / averageCost) * 100;
        }
    }

    function getLastRow(ticker) {
        return axios.get(`https://us-central1-calypso-56f64.cloudfunctions.net/getLastCsvRow?ticker=${ticker}`);
    }


    // async function calculateReturn(trade) {
    //     const filteredTransactions = trade.transactions.filter(tx => tx.specialType !== 'Close');
    //     const totalQuantity = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.quantity : curr.quantity), 0);

    //     if (totalQuantity === 0) return 1; // "No holdings to compare returns against"

    //     const totalCost = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.price * curr.quantity : curr.price * curr.quantity), 0);
    //     const averageCost = totalCost / totalQuantity;
    //     const tradeTypeFactor = trade.tradeType === "Short" ? -1 : 1;


    //     // const sortedDates = Object.keys(jsonData[trade.stockName]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
    //     // const mostRecentDate = sortedDates[0];
    //     // const mostRecentData = jsonData[trade.stockName][mostRecentDate];
    //     // const recentPrice = mostRecentData.next_day_price * (1 + mostRecentData.stock_return_since / 100);
    //     // let initialReturn = tradeTypeFactor * ((recentPrice - averageCost) / averageCost) * 100;
    //     // console.log("CALCULATE RETURN: INITIAL RETURN, ", initialReturn);
    //     // setUpdatedPrice(initialReturn); // Set initial return based on existing data

    //     function getLastRow(ticker) {
    //         return axios.get(`https://us-central1-calypso-56f64.cloudfunctions.net/getLastCsvRow?ticker=${ticker}`);
    //     }

    //     // Call cloud function to get the latest price
    //     getLastRow(trade.stockName)
    //         .then((response) => {
    //             const result = response.data;
    //             console.log("CALCULATE RETURN CLOUD FUNCTION: RESULT, ", result);
    //             if (result && result.Close) {
    //                 const updatedPrice = parseFloat(result.Close);
    //                 console.log("UPDATED PRICE: ", updatedPrice);
    //                 const updatedReturn = tradeTypeFactor * ((updatedPrice - averageCost) / averageCost) * 100;
    //                 return updatedReturn; // Update the price with new data
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error fetching latest price:', error);
    //         });
    // }



    function calculateIRR(trade) {
        const cashFlows = [];
        const dates = [];
        // Initial purchase
        cashFlows.push(-trade.transactions[0].price * trade.transactions[0].quantity);
        dates.push(new Date(trade.transactions[0].date));

        // Subsequent transactions
        trade.transactions.slice(1).forEach(tx => {
            const amount = tx.type === 'Sell' ? tx.price * tx.quantity : -tx.price * tx.quantity;
            cashFlows.push(amount);
            dates.push(new Date(tx.date));
        });

        // Add final cash flow for valuation if not closed
        if (!trade.dateClosed) {
            // Call cloud function to get the latest price
            const finalAmount = trade.recentPrice * trade.transactions.reduce((acc, curr) => acc + curr.quantity, 0);
            cashFlows.push(trade.tradeType === "Short" ? -finalAmount : finalAmount);  // Invert for short trades
            dates.push(new Date()); // Current date
        }
        if (trade.irr) return trade.irr; // Return the stored IRR if it exists (prevents recalculation on every render

        return actuallyCalculateIRR(cashFlows, dates);
    }

    function actuallyCalculateIRR(cashFlows, dates) {
        let guessRate = 0.1; // Start with a reasonable guess, like 10%
        const maxIterations = 1000;
        const tolerance = 0.000001; // Tolerance to decide when to stop iterating

        function npv(rate) {
            let totalValue = 0;
            const baseDate = new Date(dates[0]); // Convert string to date
            for (let i = 0; i < cashFlows.length; i++) {
                const days = (new Date(dates[i]) - baseDate) / (24 * 3600 * 1000);
                const years = days / 365.25;
                if (1 + rate <= 0 && years !== 0) { // Check to prevent math errors
                    return rate < 0 ? Infinity : -Infinity;
                }
                totalValue += cashFlows[i] / Math.pow(1 + rate, years);
            }
            return totalValue;
        }

        for (let i = 0; i < maxIterations; i++) {
            let npvValue = npv(guessRate);
            let increment = Math.max(Math.abs(guessRate * 0.01), 1e-6); // Prevent too small increment
            let npvPlus = npv(guessRate + increment);
            let derivative = (npvPlus - npvValue) / increment;

            if (Math.abs(derivative) < 1e-6) { // Prevent division by a very small number
                derivative = 1e-6 * (derivative < 0 ? -1 : 1);
            }

            let guessRateNew = guessRate - npvValue / derivative;

            console.log(`Iteration ${i}: Rate ${guessRate}, NPV ${npvValue}, Derivative ${derivative}, New Rate ${guessRateNew}`);

            if (Math.abs(guessRateNew - guessRate) < tolerance) {
                return guessRateNew; // Successful convergence
            }

            guessRate = guessRateNew;
            if (guessRate < -1) { // Unrealistic rate, possibly due to calculation issues
                return "-150";
            }
            if (guessRate > 2) { // Unrealistic rate, possibly due to calculation issues
                return "200";
            }
        }

        throw new Error("IRR calculation did not converge.");
    }



    return (
        <div style={{ marginTop: '77px' }}>
            <NavigationBar
                user={user}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                filteredCompanies={filteredCompanies}
                jsonData={jsonData}
                handleCompanySelect={handleCompanySelect}
                setShowUserMenu={setShowUserMenu}
                showUserMenu={showUserMenu}
                handleSignOut={handleSignOut}
            />
            <TableContainer component={Paper} sx={{ ml: 3, mr: 3, width: 'auto' }}>
                <Table sx={{ minWidth: '100%' }} aria-label="trades table">
                    <TableHead>
                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell sx={{ cursor: 'pointer', width: '25%' }} align="center" onClick={() => requestSort('title')}>
                                Trade
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} align="center" onClick={() => requestSort('dateOpened')}>
                                Date Opened
                            </TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} align="center" onClick={() => requestSort('dateClosed')}>
                                Date Closed
                            </TableCell>
                            {/* <TableCell align="center">Date Closed</TableCell> */}
                            <TableCell sx={{ cursor: 'pointer' }} align="center" onClick={() => requestSort('averageCost')}>Average Cost</TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} align="center" onClick={() => requestSort('return')}>Return</TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} align="center" onClick={() => requestSort('irr')}>IRR</TableCell>
                            <TableCell sx={{ cursor: 'pointer' }} align="center" onClick={() => requestSort('pnl')} >PnL</TableCell>
                            <TableCell align="center"> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={9} align="center">
                                    <em>Loading...</em>
                                </TableCell>
                            </TableRow>
                        ) : (
                            sortedTrades.map((trade) => (
                                <React.Fragment key={trade.id}>
                                    <TableRow
                                        sx={{
                                            alignItems: 'center',
                                            '&:hover': {
                                                backgroundColor: 'action.hover', // Using theme values for consistency
                                                color: 'primary.main', // Change text color on hover
                                                cursor: 'pointer', // Only if row is clickable
                                            },
                                            cursor: 'pointer', // Conditional cursor style
                                        }}
                                        onClick={() => handleRowClick(trade.id)}>
                                        <TableCell
                                            onClick={() => handleRowClick(trade.id)}
                                            sx={{
                                                cursor: 'pointer',
                                                // display: 'flex',
                                                flexDirection: 'row', // Ensures horizontal layout
                                                alignItems: 'center', // Vertically centers content
                                                padding: 0,
                                                // borderColor: 'transparent',
                                                width: '46px',  // Ensures the TableCell spans the full width available
                                                minHeight: '56px'  // Maintains a minimum height, adjust as needed
                                            }}
                                        >
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',  // Disables background color change on hover
                                                    },
                                                    mr: 2, // Right margin for spacing
                                                    height: '100%', // Fills the height to match the cell
                                                    width: '48px'  // Fixed width for the icon
                                                }}
                                            >
                                                {openRowId === trade.id ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                                            </IconButton>
                                        </TableCell>

                                        <TableCell>
                                            <Box sx={{
                                                flexGrow: 1,
                                                display: 'flex',
                                                flexDirection: 'column', // Allows multiple lines
                                                justifyContent: 'center', // Centers content horizontally for multi-line support
                                                alignItems: 'center', // Centers content vertically
                                                width: '100%', // Reduces width by icon width
                                                minHeight: '100%', // Ensures it takes up the minimum height
                                            }}>
                                                {trade.title}
                                            </Box>
                                        </TableCell>


                                        <TableCell align="center">{formatDateDisplay(trade.dateOpened)}</TableCell>
                                        <TableCell align="center" style={{ color: trade.dateClosed ? 'black' : 'black' }}>
                                            {trade.dateClosed ? formatDateDisplay(trade.dateClosed) : <em>—</em>}
                                        </TableCell>
                                        <TableCell align="center">${calculateAverageCost(trade.transactions).toFixed(2)}</TableCell>

                                        <TableCell align="center" style={{
                                            color: trade.return < 0 ? 'red' : 'limegreen',
                                            fontWeight: 'bold' // Make text bold
                                        }}>

                                            {!trade.dateClosed ? (
                                                <Tooltip title="Price data updated daily at 5pm EST" arrow>
                                                    <span>{formatPercentage(trade.return)}</span>
                                                </Tooltip>
                                            ) : (
                                                <span>{formatPercentage(trade.return)}</span>
                                            )}

                                        </TableCell>

                                        <TableCell align="center" style={{
                                            color: trade.irr < 0 ? 'red' : 'limegreen',
                                            fontWeight: 'bold' // Make text bold
                                        }}>
                                            {!trade.dateClosed ? (
                                                trade.quantity === 0 ? (
                                                    <Tooltip title="Quantity is zero, so no cash flows for IRR calculation" arrow>
                                                        <span>{trade.irr <= -100 ? '<(100%)' : (trade.irr >= 200 ? '>200%' : formatPercentage(trade.irr))}</span>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Prices updated daily at 5pm EST" arrow>
                                                        <span>{trade.irr <= -100 ? '<(100%)' : (trade.irr >= 200 ? '>200%' : formatPercentage(trade.irr))}</span>
                                                    </Tooltip>
                                                )
                                            ) : (
                                                trade.quantity === 0 ? (
                                                    <Tooltip title="Quantity is zero, so no cash flows for IRR calculation" arrow>
                                                        <span>{trade.irr <= -100 ? '<(100%)' : (trade.irr >= 200 ? '>200%' : formatPercentage(trade.irr))}</span>
                                                    </Tooltip>
                                                ) : (
                                                    <span>{trade.irr <= -100 ? '<(100%)' : (trade.irr >= 200 ? '>200%' : formatPercentage(trade.irr))}</span>
                                                )
                                            )}



                                        </TableCell>
                                        <TableCell align="center" style={{
                                            color: trade.pnl >= 0 ? 'limegreen' : 'red',
                                            fontWeight: 'bold' // Make text bold
                                        }}>
                                            {!trade.dateClosed ? (
                                                trade.quantity === 0 ? (
                                                    <Tooltip title="Quantity is zero, so no cash flows for PnL calculation" arrow>
                                                        <span>{formatPnL(trade.pnl)}</span>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Prices updated daily at 5pm EST" arrow>
                                                        <span>{formatPnL(trade.pnl)}</span>
                                                    </Tooltip>
                                                )
                                            ) : (
                                                trade.quantity === 0 ? (
                                                    <Tooltip title="Quantity is zero, so no cash flows for PnL calculation" arrow>
                                                        <span>{formatPnL(trade.pnl)}</span>
                                                    </Tooltip>
                                                ) : (
                                                    <span>{formatPnL(trade.pnl)}</span>
                                                )
                                            )}


                                            {/* {formatPnL(calculateNetPnL(trade))} */}
                                        </TableCell>
                                        <TableCell align="center" sx={{ padding: 0 }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                    mr: 1.5,
                                                    gap: 0.25,
                                                }}
                                            >
                                                {trade.dateClosed ? (
                                                    <Box
                                                        sx={{
                                                            width: '80px',
                                                            height: '25px',
                                                            background: 'linear-gradient(135deg, #00aaff 0%, #00ffaa 100%)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: '4px',
                                                            // mr: 1,
                                                            fontWeight: 'bold',
                                                            color: 'white',
                                                            textTransform: 'uppercase',
                                                        }}
                                                    >
                                                        FINAL
                                                    </Box>
                                                ) : (
                                                    <>
                                                        <Tooltip title="Update Trade" arrow>
                                                            <IconButton onClick={() => handleOpenUpdateDialog(trade)}><AddIcon /></IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Close Trade" sx={{ mr: 0.5 }} arrow>
                                                            <IconButton onClick={() => handleOpenCloseDialog(trade)}><CheckIcon /></IconButton>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                            <Collapse in={openRowId === trade.id} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                                                <Box margin={2}>
                                                    <div key={trade.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <TextField
                                                            fullWidth
                                                            multiline
                                                            label="What I Thought Would Happen:"
                                                            value={fieldValues[`${trade.id}_prediction`] || trade.prediction}
                                                            onChange={(e) => handleFieldChange('prediction', trade.id, e.target.value)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <IconButton onClick={() => toggleEdit('prediction', trade.id)}>
                                                                        {editStates[`${trade.id}_prediction`] ? <SaveIcon /> : <EditIcon />}
                                                                    </IconButton>
                                                                ),
                                                                classes: {
                                                                    input: classes.input,
                                                                },
                                                            }}
                                                            disabled={!editStates[`${trade.id}_prediction`]}
                                                            sx={{ width: '49%' }}
                                                        />
                                                        <Tooltip title="Close Trade to Decribe What Actually Happened" arrow>
                                                            <TextField
                                                                fullWidth
                                                                multiline
                                                                label="What Actually Happened:"
                                                                value={fieldValues[`${trade.id}_outcome`] || trade.outcome}
                                                                onChange={(e) => handleFieldChange('outcome', trade.id, e.target.value)}
                                                                InputLabelProps={{ shrink: true }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <IconButton onClick={() => toggleEdit('outcome', trade.id)} disabled={!trade.dateClosed}>
                                                                            {editStates[`${trade.id}_outcome`] ? <SaveIcon /> : <EditIcon />}
                                                                        </IconButton>
                                                                    ),
                                                                    classes: {
                                                                        input: classes.input,
                                                                    },
                                                                }}
                                                                disabled={!editStates[`${trade.id}_outcome`]}
                                                                sx={{ width: '49%' }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                                        <Tooltip title="Close Trade To Include in Training Data" arrow>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={trade.flagged_for_personal_AI || false}
                                                                        onChange={(event) => handleCheckboxChange(event, trade.id)}
                                                                        disabled={!trade.dateClosed}
                                                                        sx={{
                                                                            ml: 0.9,
                                                                            '&.Mui-checked': {
                                                                                color: 'grey.700', // Set the checked color to grey
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                label="Include this trade when teaching Calypso AI my personal trading style."
                                                                sx={{
                                                                    '& .MuiFormControlLabel-label': {
                                                                        typography: 'body2',
                                                                        mt: +0.25,
                                                                        color: 'grey.800',
                                                                        fontSize: '0.98em'
                                                                    },
                                                                }}
                                                            />
                                                        </Tooltip>
                                                        <Box sx={{ display: 'flex', gap: '4px', mt: 0.75 }}>

                                                            <Tooltip title={ (!trade.published_id ? "Publish Trade" : "Go to Published Trade")} arrow>
                                                                <span>
                                                                    <IconButton
                                                                        sx={{ mr: 0, color: 'inherit' }}
                                                                        onClick={() => {
                                                                            if (!trade.published_id) {
                                                                                setPublishDialogOpen(true);
                                                                                setTradeToPublish(trade);
                                                                            } else {
                                                                                navigate(`/trade/${trade.published_id}`);
                                                                            }
                                                                        }}
                                                                    >

                                                                        <ShareIcon />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Trade" arrow>

                                                                <IconButton sx={{ mr: 0.7 }} onClick={() => { setDeleteDialogOpen(true); setTradeToDelete(trade); }}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </div>
                                                    <TableRow hover onClick={() => toggleDetails(trade.id, 'transactions')} sx={{ width: '100%', display: 'flex', height: '41px', alignItems: 'center', borderRadius: '8px', cursor: 'pointer', mt: -0.2 }}>
                                                        <TableCell sx={{ display: 'flex', alignItems: 'center', width: '100%', borderColor: 'transparent', padding: '8px 16px' }} colSpan={9}>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                sx={{ '&:hover': { backgroundColor: 'transparent' }, mr: 2, ml: -1 }}
                                                            >
                                                                {openDetails[`${trade.id}-transactions`] ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                                                            </IconButton>
                                                            <Typography variant="body3" sx={{ flexGrow: 1, fontSize: '0.9rem' }}>Timeline</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%', borderColor: 'transparent' }} colSpan={9}>
                                                            <Collapse in={openDetails[`${trade.id}-transactions`]} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                                                                <Box margin={1} sx={{ width: '100%', ml: 2.2 }}>
                                                                    {renderTransactions(trade.transactions)}
                                                                </Box>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow hover onClick={() => toggleDetails(trade.id, 'info')} sx={{ width: '100%', display: 'flex', height: '41px', alignItems: 'center', borderRadius: '8px', cursor: 'pointer' }}>
                                                        <TableCell sx={{ display: 'flex', alignItems: 'center', width: '100%', borderColor: 'transparent', padding: '8px 16px' }} colSpan={9}>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                sx={{ '&:hover': { backgroundColor: 'transparent' }, mr: 2, ml: -1 }}
                                                            >
                                                                {openDetails[`${trade.id}-info`] ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                                                            </IconButton>
                                                            <Typography variant="body3" sx={{ flexGrow: 1, fontSize: '0.9rem' }}>Information at the Time</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{ mt: -5, backgroundColor: '#f8f7f7', borderRadius: '8px' }}>
                                                        <TableCell style={{ padding: 0, border: 'none', maxWidth: '100%', mt: -5, backgroundColor: '#f8f7f7', borderRadius: '8px' }} colSpan={9}>
                                                            <Collapse in={openDetails[`${trade.id}-info`]} timeout="auto" unmountOnExit>
                                                                <div style={{ display: 'flex', width: '100%', overflow: 'hidden', maxWidth: '100%', mt: -5, backgroundColor: '#f8f7f7', borderRadius: '8px' }}>
                                                                    <CompanyPageMini fullTrade={trade} />
                                                                </div>
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                    {/* <TableRow sx={{ width: '100%', display: 'flex', height: '20px', alignItems: 'center'}}>
                                                    <TableCell sx={{ display: 'flex', alignItems: 'center', width: '100%', borderColor: 'transparent' }} colSpan={9}>
                                                            <Typography variant="body2" sx={{ flexGrow: 1, fontSize: '0.9rem' }}>
                                                                Note: Market price data updated at Friday upon market close.
                                                            </Typography>
                                                    </TableCell>
                                                </TableRow> */}
                                                    {/*      
      <Typography variant="body1" sx={{ mt: 0.5 }}>Transaction History</Typography>
      <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
        {renderTransactions(trade.transactions)}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>Information at the Time</Typography>
      <Typography variant="body2">{`AI Opinion: ${trade.AI_opinion || 'No data'}`}</Typography>
      <Typography variant="body2">{`Financials: ${trade.financials || 'No data'}`}</Typography> */}
                                                </Box>
                                            </Collapse>
                                        </TableCell>

                                    </TableRow>
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={updateDialogOpen} onClose={handleCloseUpdateDialog}>
                <DialogTitle>Update Trade</DialogTitle>
                <DialogContent>
                    {/* Input fields and update functionality go here */}
                    <Box display="flex" justifyContent="space-between" >
                        {selectedTrade && (
                            <TextField
                                autoFocus
                                margin="dense"
                                id="price"
                                label="Price ($)"
                                type="number"
                                fullWidth
                                variant="outlined"
                                defaultValue=""
                            />)}
                        <Box sx={{ flexGrow: 1, ml: 1, display: 'flex', justifyContent: 'flex-start' }}>
                            <Button
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    mr: 1,
                                    bgcolor: updateType === 'Buy' ? 'rgba(25, 118, 210, 0.8)' : 'rgba(189, 189, 189, 0.7)',
                                    color: 'background.paper',
                                    fontSize: '0.855rem',
                                    '&:hover': { bgcolor: 'rgba(25, 118, 210, 1)' }
                                }}
                                variant="contained"
                                onClick={() => setUpdateType('Buy')}
                            >
                                Buy
                            </Button>
                            <Button
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    bgcolor: updateType === 'Sell' ? 'rgba(25, 118, 210, 0.8)' : 'rgba(189, 189, 189, 0.7)',
                                    color: 'background.paper',
                                    fontSize: '0.855rem',
                                    '&:hover': { bgcolor: 'rgba(25, 118, 210, 1)' }
                                }}
                                variant="contained"
                                onClick={() => setUpdateType('Sell')}
                            >
                                Sell
                            </Button>

                            <Button
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    ml: 1,
                                    bgcolor: updateType === 'Note' ? 'rgba(25, 118, 210, 0.8)' : 'rgba(189, 189, 189, 0.7)',
                                    color: 'background.paper',
                                    fontSize: '0.855rem',
                                    '&:hover': { bgcolor: 'rgba(25, 118, 210, 1)' }
                                }}
                                variant="contained"
                                onClick={() => setUpdateType('Note')}
                            >
                                Note
                            </Button>
                        </Box>
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="quantity"
                        label="Quantity"
                        type="number"
                        fullWidth
                        variant="outlined"
                        defaultValue=''
                    />
                    <TextField
                        margin="dense"
                        id="dateOpened"
                        label="Date"
                        type="date"
                        variant="standard"
                        value={dateOpened}
                        onChange={e => setDateOpened(e.target.value)}
                        defaultValue={new Date()}
                        fullWidth

                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="comments"
                        label="Comments"
                        type="text"
                        fullWidth
                        variant="outlined"
                        defaultValue=""
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpdateDialog}>Cancel</Button>
                    <Button onClick={handleUpdateTrade}>Update</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={closeDialogOpen} onClose={handleCloseCloseDialog}>
                <DialogTitle>Close Trade</DialogTitle>
                <DialogContent>
                    {/* Input fields and update functionality go here */}

                    <TextField
                        autoFocus
                        margin="dense"
                        id="price"
                        label="Price ($)"
                        type="number"
                        fullWidth
                        variant="outlined"
                        defaultValue=""
                    />


                    <TextField
                        margin="dense"
                        id="dateOpened"
                        label="Date"
                        type="date"
                        variant="standard"
                        value={dateOpened}
                        onChange={e => setDateOpened(e.target.value)}
                        defaultValue={new Date()}
                        fullWidth

                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="comments"
                        label="What Actually Happened:"
                        type="text"
                        fullWidth
                        variant="outlined"
                        defaultValue=""
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCloseDialog}>Cancel</Button>
                    <Button onClick={handleCloseTrade}>Close Trade</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Delete Trade</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this trade?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteTrade} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={publishDialogOpen} onClose={() => setPublishDialogOpen(false)}>
                <DialogTitle>Publish Trade</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to create a shareable link for this trade?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPublishDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handlePublishTrade}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default TradesDashboard;
