import { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { useParams } from 'react-router-dom';
import format from 'date-fns/format';
import { doc, onSnapshot, collection, query, getDoc, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import jsonData from '../api_responses.json';
import { Table, TableBody, FormControlLabel, AppBar, Toolbar, IconButton, Checkbox, TableCell, TableContainer, Tooltip, Collapse, TableHead, TableRow, Paper, Box, Typography, Button, Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import NavigationBar from './NavigationBar';
import AddIcon from '@mui/icons-material/Add';  // Plus icon
import CheckIcon from '@mui/icons-material/Check';  // Checkmark icon
import EditIcon from '@mui/icons-material/Edit';  // Edit icon
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CompanyPageMini from './CompanyPageMini';
import CompanyPage from './CompanyPage';
import ShareIcon from '@mui/icons-material/Share';
import axios from 'axios';

const useStyles = makeStyles({
    input: {
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.83) !important',
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.83) !important',
        },
    },
});


function TradeDetails() {
    const { tradeId } = useParams();
    const [trade, setTrade] = useState(null);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [tradeExpanded, setTradeExpanded] = useState(true);
    const classes = useStyles();

    // might delete
    const [editStates, setEditStates] = useState({});
    // For collapse
    const [fieldValues, setFieldValues] = useState({});
    // Update field values
    const handleFieldChange = (field, tradeId, value) => {
        setFieldValues({
            ...fieldValues,
            [`${tradeId}_${field}`]: value
        });
    };

    // DATE


    // NAV BAR ITEMS
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const companies = Object.keys(jsonData);
    const [dateOpened, setDateOpened] = useState(new Date().toISOString().slice(0, 10));


    useEffect(() => {
        const results = searchTerm ? companies.filter(company => {
            if (!jsonData[company]) return false;
            const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
            const firstDateKey = sortedDates[0];
            const companyData = jsonData[company][firstDateKey];
            const matchesTicker = company.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFullName = companyData && companyData.full_name && companyData.full_name.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesTicker || matchesFullName;
        }) : [];
        setFilteredCompanies(results);
    }, [searchTerm, companies, jsonData]);
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleCompanySelect = (company) => {
        navigate(`/company/${company}`, { state: { company } });
        setSearchTerm('');  // Clear the search term to hide the search results
    };
    const handleSignOut = async () => {
        await signOut(auth);
        navigate('/');  // Redirect to login after sign out
    };

    // FORMATTING 
    const formatPercentage = (value) => {
        const formatted = parseFloat(value).toFixed(2);
        return formatted >= 0 ? `${formatted}%` : `(${Math.abs(formatted)}%)`;
    };

    const formatPnL = (value) => {
        const formatted = parseFloat(value).toFixed(2);
        return value >= 0 ? `+$${formatted}` : `($${Math.abs(formatted)})`;
    };

    const formatDateDisplay = (dateStr) => {
        try {
            return format(new Date(dateStr), 'MMM dd, yyyy');
        } catch (error) {
            console.error("Error formatting date:", error);
            return 'Invalid date';
        }
    };

    const handleRowClick = () => {
        setTradeExpanded(!tradeExpanded);
    };

    function calculateNetPnL(trade) {
        if (!jsonData[trade.stockName]) return 0;


        const filteredTransactions = trade.transactions.filter(tx => tx.specialType !== 'Close');
        const totalQuantity = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.quantity : curr.quantity), 0);

        if (totalQuantity === 0) totalQuantity = 1; // No holdings to compare returns against

        const totalCost = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.price * curr.quantity : curr.price * curr.quantity), 0);
        // const averageCost = totalCost / totalQuantity;

        // if (totalQuantity === 0) return 0; // Prevent division by zero (no holdings)
        if (trade.dateClosed) {
            const closedPrice = trade.priceClosed;
            const closedQuantity = trade.transactions.reduce((acc, curr) => acc + (curr.specialType === 'Close' ? curr.quantity : 0), 0);
            const closedProceeds = closedPrice * closedQuantity;
            return trade.tradeType === "Long" ? (closedProceeds - totalCost) : (totalCost - closedProceeds);
        } else {
            const currentMarketValue = totalQuantity * trade.recentPrice;
            return trade.tradeType === "Short" ? (totalCost + currentMarketValue) : (currentMarketValue - totalCost);
        }
    }

    function calculateReturn(trade) {
        const filteredTransactions = trade.transactions.filter(tx => tx.specialType !== 'Close');
        const totalQuantity = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.quantity : curr.quantity), 0);

        if (totalQuantity === 0) return 1; // "No holdings to compare returns against"

        const totalCost = filteredTransactions.reduce((acc, curr) => acc + (curr.type === 'Sell' ? -curr.price * curr.quantity : curr.price * curr.quantity), 0);
        const averageCost = totalCost / totalQuantity;
        const tradeTypeFactor = trade.tradeType === "Short" ? -1 : 1;

        // Call cloud function to get the latest price
        if (!trade.dateClosed) {


            return tradeTypeFactor * ((trade.recentPrice - averageCost) / averageCost) * 100;
        } else {
            return tradeTypeFactor * ((trade.priceClosed - averageCost) / averageCost) * 100;
        }
    }

    
    function calculateIRR(trade) {
        const cashFlows = [];
        const dates = [];
        // Initial purchase
        cashFlows.push(-trade.transactions[0].price * trade.transactions[0].quantity);
        dates.push(new Date(trade.transactions[0].date));

        // Subsequent transactions
        trade.transactions.slice(1).forEach(tx => {
            const amount = tx.type === 'Sell' ? tx.price * tx.quantity : -tx.price * tx.quantity;
            cashFlows.push(amount);
            dates.push(new Date(tx.date));
        });

        // Add final cash flow for valuation if not closed
        if (!trade.dateClosed) {
            // Call cloud function to get the latest price
            const finalAmount = trade.recentPrice * trade.transactions.reduce((acc, curr) => acc + curr.quantity, 0);
            cashFlows.push(trade.tradeType === "Short" ? -finalAmount : finalAmount);  // Invert for short trades
            dates.push(new Date()); // Current date
        }
        if (trade.irr) return trade.irr; // Return the stored IRR if it exists (prevents recalculation on every render

        return actuallyCalculateIRR(cashFlows, dates);
    }
    

    function actuallyCalculateIRR(cashFlows, dates) {
        let guessRate = 0.1; // Start with a reasonable guess, like 10%
        const maxIterations = 1000;
        const tolerance = 0.000001; // Tolerance to decide when to stop iterating

        function npv(rate) {
            let totalValue = 0;
            const baseDate = new Date(dates[0]); // Convert string to date
            for (let i = 0; i < cashFlows.length; i++) {
                const days = (new Date(dates[i]) - baseDate) / (24 * 3600 * 1000);
                const years = days / 365.25;
                if (1 + rate <= 0 && years !== 0) { // Check to prevent math errors
                    return rate < 0 ? Infinity : -Infinity;
                }
                totalValue += cashFlows[i] / Math.pow(1 + rate, years);
            }
            return totalValue;
        }

        for (let i = 0; i < maxIterations; i++) {
            let npvValue = npv(guessRate);
            let increment = Math.max(Math.abs(guessRate * 0.01), 1e-6); // Prevent too small increment
            let npvPlus = npv(guessRate + increment);
            let derivative = (npvPlus - npvValue) / increment;

            if (Math.abs(derivative) < 1e-6) { // Prevent division by a very small number
                derivative = 1e-6 * (derivative < 0 ? -1 : 1);
            }

            let guessRateNew = guessRate - npvValue / derivative;

            console.log(`Iteration ${i}: Rate ${guessRate}, NPV ${npvValue}, Derivative ${derivative}, New Rate ${guessRateNew}`);

            if (Math.abs(guessRateNew - guessRate) < tolerance) {
                return guessRateNew; // Successful convergence
            }

            guessRate = guessRateNew;
            if (guessRate < -1) { // Unrealistic rate, possibly due to calculation issues
                return "-150";
            }
            if (guessRate > 2) { // Unrealistic rate, possibly due to calculation issues
                return "200";
            }
        }

        throw new Error("IRR calculation did not converge.");
    }




    const convertToISOFormat = (dateStr) => {
        if (!dateStr) return null; // Return null if dateStr is empty
        const parts = dateStr.split('-');
        if (parts.length !== 3) return null; // Return null if dateStr format is not as expected

        // Assuming date is in YY-MM-DD format and needs conversion to YYYY-MM-DD
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        const fullYear = year.length === 2 ? (parseInt(year, 10) > 50 ? `19${year}` : `20${year}`) : year;

        return `${fullYear}-${month}-${day}`; // Return the date in ISO format (YYYY-MM-DD)
    };



    const formatDate = (dateStr) => {
        try {
            const isoDateStr = convertToISOFormat(dateStr);
            if (!isoDateStr) {
                console.error("Invalid ISO Date:", dateStr); // Log bad inputs
                return 'Date N/A';
            }

            const date = new Date(isoDateStr);
            if (isNaN(date.getTime())) {
                console.error("Invalid Date Object from ISO String:", isoDateStr); // More specific logging
                return 'Invalid date';
            }

            return format(date, 'MMM d, yyyy');
        } catch (error) {
            console.error(`Error in formatDate function: ${dateStr}`, error);
            return 'Invalid date';
        }
    };
    
    const [openDetails, setOpenDetails] = useState({});  // State for collapse toggle
    const [publishDate, setPublishDate] = useState('');  // State for formatted date
    // Example data retrieval and initial setup
    useEffect(() => {

        const initialOpenDetails = {};
        if (trade) {
            initialOpenDetails[`${trade.id}-transactions`] = true;
            initialOpenDetails[`${trade.id}-info`] = true;

            setOpenDetails(initialOpenDetails);

            setPublishDate(trade.publishDate);

        
        }
    }, [trade]);

    const toggleDetails = (tradeId, section) => {
        const key = `${tradeId}-${section}`;
        setOpenDetails(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const renderTransactions = (transactions) => {
        // Sort transactions by date
        const sortedTransactions = transactions.sort((a, b) => new Date(a.date) - new Date(b.date));

        return sortedTransactions.map((tx, index) => {
            const dateStr = formatDate(tx.date);
            const action = tx.type === 'Buy' ? 'Purchased' : 'Sold';
            const quantity = tx.quantity || 'N/A';
            const stockName = tx.stockName || 'stock';
            const price = tx.price ? `$${tx.price}` : 'N/A per share';
            const comment = tx.comments ? `Comment: ${tx.comments}` : '';

            let transactionText = '';
            if (tx.type === 'Sell' && tx.specialType && tx.specialType === 'Close') {
                transactionText = `Closed trade at ${price}.`;
            } else if (tx.type === 'Note') {
                transactionText = comment;
            } else {
                transactionText = `${action} ${quantity} at ${price}. ${comment}`;
            }

            return (
                <div key={index} style={{ marginBottom: '1px' }}>
                    {index + 1}: <strong>{dateStr}:</strong> {transactionText}
                </div>
            );
        });
    };


    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged(currentUser => {
            setUser(currentUser);
            // if (!currentUser) {
            //     navigate('/login');
            // }
        });
        return () => unsubscribeAuth();
    }, [navigate]);

    useEffect(() => {
        const fetchTrade = async () => {
            const tradeRef = doc(db, 'trades', tradeId);
            const tradeSnap = await getDoc(tradeRef);

            if (tradeSnap.exists()) {
                let fetchedTrade = tradeSnap.data();

                if (!fetchedTrade.dateClosed) {
                    console.log("ABOUT TO SUBMIT REQUEST TO GET LAST ROW: ", fetchedTrade.stockName);
                    const result = await getLastRow(fetchedTrade.stockName);
                    fetchedTrade.recentPrice = result?.data?.Close ? parseFloat(result.data.Close) : 0;
                }
                    // Calculate return using the modified function
                fetchedTrade.return = calculateReturn(fetchedTrade);
                fetchedTrade.irr = calculateIRR(fetchedTrade);
                fetchedTrade.pnl = calculateNetPnL(fetchedTrade);
                // console.log("LAST ROW: return, irr, pnl", fetchedTrade.return, fetchedTrade.irr, fetchedTrade.pnl);
               
                setTrade(fetchedTrade);
            } else {
                console.log('No such trade!');

            }
        };

        fetchTrade();
    }, [tradeId]);

    function getLastRow(ticker) {
        return axios.get(`https://us-central1-calypso-56f64.cloudfunctions.net/getLastCsvRow?ticker=${ticker}`);
    }

    if (!trade) return <div></div>;

    return (
        <div style={{ marginTop: '77px' }}>
            {user ? (<NavigationBar
                user={user}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                filteredCompanies={filteredCompanies}
                jsonData={jsonData}
                handleCompanySelect={handleCompanySelect}
                setShowUserMenu={setShowUserMenu}
                showUserMenu={showUserMenu}
                handleSignOut={handleSignOut}
            />)
                : (
                    <AppBar position="fixed" color="default" elevation={1}>
                        <Toolbar>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Box sx={{ flexGrow: 0.125 }}>
                                    <Button color="inherit" onClick={() => window.location.href = 'https://calypsocopilot.com'} sx={{ ml: 0, mr: 0, minWidth: '115px' }}>
                                        <Typography
                                            variant="h6"
                                            onClick={() => window.location.href = 'https://calypsocopilot.com'}
                                            style={{
                                                cursor: 'pointer', mr: 6, ml: 6,
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    transform: 'scale(1.1)',
                                                    transition: 'transform 0.2s, background-color 0.2s'
                                                }
                                            }}
                                        >
                                            <img src="/logo512.png" alt="Calypso CoPilot Logo" style={{ width: 60, marginBottom: -15, marginTop: -5, marginRight: -20, cursor: 'pointer' }} />
                                        </Typography>
                                    </Button>
                                </Box>





                                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="outline"
                                        onClick={() => navigate('/')}
                                        style={{
                                            //   display: 'block',
                                            //   backgroundColor: '#9e9e9e',  // Normal state gray color
                                            color: '#000',               // Text color
                                            //   fontWeight: 'bold',          // Makes the text bold
                                            borderRadius: '10px',        // Rounded corners with smaller radius
                                            padding: '5px 13px',         // Smaller padding for less height
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',  // Subtle shadow
                                            textTransform: 'none',       // Avoids uppercase text transform
                                            transition: 'all 0.1s ease', // Smooth transition for hover effects
                                            position: 'relative',        // Required for absolute positioning of the dot
                                            overflow: 'hidden',          // Ensures the dot does not escape the button boundaries
                                            fontSize: '0.89em'
                                        }}
                                        sx={{
                                            '&:hover': {
                                                // background: 'linear-gradient(45deg, #21CBF3 30%, limegreen 90%)', // Gradient on hover including green
                                                boxShadow: '0 3px 5px 2px linear-gradient(45deg, #21CBF3 30%, limegreen 90%) !important',  // Enhanced shadow on hover
                                                // color: 'white !important',
                                                // fontWeight: 'bold'
                                            },

                                        }}

                                    >
                                        Get Started
                                    </Button>
                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                )}

            <TableContainer component={Paper} sx={{ ml: 3, mr: 3, width: 'auto' }}>
                <Table sx={{ minWidth: '100%' }} aria-label="trades table">
                    <TableHead>
                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell sx={{ width: '25%' }} align="center">
                                Trade
                            </TableCell>
                            <TableCell align="center" >
                                Date Opened
                            </TableCell>
                            <TableCell align="center" >
                                Date Closed
                            </TableCell>
                            {/* <TableCell align="center">Date Closed</TableCell> */}
                            <TableCell align="center" >Average Cost</TableCell>
                            <TableCell align="center" >Return</TableCell>
                            <TableCell align="center" >IRR</TableCell>
                            <TableCell align="center"  >PnL</TableCell>
                            <TableCell align="center"> </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{
                                alignItems: 'center',
                                '&:hover': {
                                    backgroundColor: 'action.hover', // Using theme values for consistency
                                    color: 'primary.main', // Change text color on hover
                                    cursor: 'pointer', // Only if row is clickable

                                },
                                cursor: 'pointer', // Conditional cursor style
                            }}
                            onClick={() => handleRowClick()}>
                            <TableCell
                                onClick={() => handleRowClick()}
                                sx={{
                                    cursor: 'pointer',
                                    // display: 'flex',
                                    flexDirection: 'row', // Ensures horizontal layout
                                    alignItems: 'center', // Vertically centers content
                                    padding: 0,
                                    // borderColor: 'transparent',
                                    width: '46px',  // Ensures the TableCell spans the full width available
                                    minHeight: '56px'  // Maintains a minimum height, adjust as needed
                                }}
                            >
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'transparent',  // Disables background color change on hover
                                        },
                                        mr: 2, // Right margin for spacing
                                        height: '100%', // Fills the height to match the cell
                                        width: '48px'  // Fixed width for the icon
                                    }}
                                >
                                    {tradeExpanded ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                                </IconButton>
                            </TableCell>

                            <TableCell>
                                <Box sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    flexDirection: 'column', // Allows multiple lines
                                    justifyContent: 'center', // Centers content horizontally for multi-line support
                                    alignItems: 'center', // Centers content vertically
                                    width: '100%', // Reduces width by icon width
                                    minHeight: '100%', // Ensures it takes up the minimum height
                                }}>
                                    {trade.title}
                                </Box>
                            </TableCell>


                            <TableCell align="center">{formatDateDisplay(trade.dateOpened)}</TableCell>
                            <TableCell align="center" style={{ color: trade.dateClosed ? 'black' : 'black' }}>
                                {trade.dateClosed ? formatDateDisplay(trade.dateClosed) : <em>—</em>}
                            </TableCell>
                            <TableCell align="center">${trade.averageCost}</TableCell>

                            <TableCell align="center" style={{
                                color: trade.return < 0 ? 'red' : 'limegreen',
                                fontWeight: 'bold' // Make text bold
                            }}>

                                <span>{formatPercentage(trade.return)}</span>


                            </TableCell>

                            <TableCell align="center" style={{
                                color: trade.irr < 0 ? 'red' : 'limegreen',
                                fontWeight: 'bold' // Make text bold
                            }}>

                                {!trade.dateClosed ? (
                                            trade.quantity === 0 ? (
                                                <Tooltip title="Quantity is zero, so no cash flows for IRR calculation" arrow>
                                                    <span>{trade.irr <= -100 ? '<(100%)' : (trade.irr >= 200 ? '>200%' : formatPercentage(trade.irr))}</span>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Prices updated daily at 5pm EST" arrow>
                                                    <span>{trade.irr <= -100 ? '<(100%)' : (trade.irr >= 200 ? '>200%' : formatPercentage(trade.irr))}</span>
                                                </Tooltip>
                                            )
                                        ) : (
                                            trade.quantity === 0 ? (
                                                <Tooltip title="Quantity is zero, so no cash flows for IRR calculation" arrow>
                                                    <span>{trade.irr <= -100 ? '<(100%)' : (trade.irr >= 200 ? '>200%' : formatPercentage(trade.irr))}</span>
                                                </Tooltip>
                                            ) : (
                                                <span>{trade.irr <= -100 ? '<(100%)' : (trade.irr >= 200 ? '>200%' : formatPercentage(trade.irr))}</span>
                                            )
                                        )}


                            </TableCell>
                            <TableCell align="center" style={{
                                color: trade.pnl >= 0 ? 'limegreen' : 'red',
                                fontWeight: 'bold' // Make text bold
                            }}>
                                {
                                    trade.quantity === 0 ? (
                                        <Tooltip title="Quantity is zero, so no cash flows for PnL calculation" arrow>
                                            <span>{formatPnL(trade.pnl)}</span>
                                        </Tooltip>
                                    ) : (
                                        <span>{formatPnL(trade.pnl)}</span>
                                    )
                                }

                            </TableCell>
                            <TableCell align="center" sx={{ padding: 0 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        mr: 1.5,
                                        gap: 0.25,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '80px',
                                            height: '25px',
                                            background: 'linear-gradient(135deg, #00aaff 0%, #00ffaa 100%)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '4px',
                                            // mr: 1,
                                            fontWeight: 'bold',
                                            color: 'white',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        FINAL
                                    </Box>

                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                <Collapse in={tradeExpanded} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                                    <Box margin={2}>
                                        <div key={trade.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                label={`What ${trade.publisher_name.split(' ')[0]} Thought Would Happen:`}
                                                value={fieldValues[`${trade.id}_prediction`] || trade.prediction}
                                                onChange={(e) => handleFieldChange('prediction', trade.id, e.target.value)}
                                                InputProps={{
                                                    // endAdornment: (
                                                    //     // <IconButton onClick={() => toggleEdit('prediction', trade.id)} disabled>
                                                    //     //     {editStates[`${trade.id}_prediction`] ? <SaveIcon /> : <EditIcon />}
                                                    //     // </IconButton>
                                                    // ),
                                                    classes: {
                                                        input: classes.input,
                                                    },
                                                }}
                                                disabled={!editStates[`${trade.id}_prediction`]}
                                                sx={{ width: '49%' }}
                                            />
                                            <TextField
                                                fullWidth
                                                multiline
                                                label="What Actually Happened:"
                                                value={fieldValues[`${trade.id}_outcome`] || trade.outcome}
                                                onChange={(e) => handleFieldChange('outcome', trade.id, e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={{
                                                    // endAdornment: (
                                                    //     <IconButton onClick={() => toggleEdit('outcome', trade.id)} disabled={!trade.dateClosed}>
                                                    //         {editStates[`${trade.id}_outcome`] ? <SaveIcon /> : <EditIcon />}
                                                    //     </IconButton>
                                                    // ),
                                                    classes: {
                                                        input: classes.input,
                                                    },
                                                }}
                                                disabled={!editStates[`${trade.id}_outcome`]}
                                                sx={{ width: '49%' }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={trade.flagged_for_personal_AI || false}
                                                        // onChange={(event) => handleCheckboxChange(event, trade.id)}
                                                        disabled
                                                        sx={{
                                                            ml: 0.9,
                                                            '&.Mui-checked': {
                                                                color: 'grey.700', // Set the checked color to grey
                                                            }
                                                        }}
                                                    />
                                                }
                                                label="Include this trade when teaching Calypso AI my personal trading style."
                                                sx={{
                                                    '& .MuiFormControlLabel-label': {
                                                        typography: 'body2',
                                                        mt: +0.25,
                                                        color: 'grey.800',
                                                        fontSize: '0.98em'
                                                    },
                                                }}
                                            />
                                            <Box sx={{ display: 'flex', gap: '4px', mt: 0.75 }}>

                                                <Tooltip title="Trade Already Published" arrow>
                                                    <span>
                                                        <IconButton
                                                            disabled={!trade.dateClosed}
                                                            sx={{ mr: 0, color: !trade.dateClosed ? 'rgba(0, 0, 0, 0.26)' : 'inherit' }}
                                                            disabled
                                                        >
                                                            <ShareIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Delete Trade" arrow>

                                                    <IconButton sx={{ mr: 0.7 }} disabled >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </div>
                                        <TableRow hover onClick={() => toggleDetails(trade.id, 'transactions')} sx={{ width: '100%', display: 'flex', height: '39px', alignItems: 'center', borderRadius: '8px', cursor: 'pointer', mt: -0.2 }}>
                                            <TableCell sx={{ display: 'flex', alignItems: 'center', width: '100%', borderColor: 'transparent', padding: '8px 16px' }} colSpan={9}>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    sx={{ '&:hover': { backgroundColor: 'transparent' }, mr: 2, ml: -1 }}
                                                >
                                                    {openDetails[`${trade.id}-transactions`] ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                                                </IconButton>
                                                <Typography variant="body3" sx={{ flexGrow: 1, fontSize: '0.9rem' }}>Timeline</Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%', borderColor: 'transparent' }} colSpan={9}>
                                                <Collapse in={openDetails[`${trade.id}-transactions`]} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                                                    <Box margin={1} sx={{ width: '100%', ml: 2.2 }}>
                                                        {renderTransactions(trade.transactions)}
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow hover onClick={() => toggleDetails(trade.id, 'info')} sx={{ width: '100%', display: 'flex', height: '39px', alignItems: 'center', borderRadius: '8px', cursor: 'pointer' }}>
                                            <TableCell sx={{ display: 'flex', alignItems: 'center', width: '100%', borderColor: 'transparent', padding: '8px 16px' }} colSpan={9}>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    sx={{ '&:hover': { backgroundColor: 'transparent' }, mr: 2, ml: -1 }}
                                                >
                                                    {openDetails[`${trade.id}-info`] ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                                                </IconButton>
                                                <Typography variant="body3" sx={{ flexGrow: 1, fontSize: '0.9rem' }}>Information at the Time</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ mt: -5, backgroundColor: '#f8f7f7', borderRadius: '8px' }}>
                                            <TableCell style={{ padding: 0, border: 'none', maxWidth: '100%', mt: -5, backgroundColor: '#f8f7f7', borderRadius: '8px' }} colSpan={9}>
                                                <Collapse in={openDetails[`${trade.id}-info`]} timeout="auto" unmountOnExit>
                                                    <div style={{ display: 'flex', width: '100%', overflow: 'hidden', maxWidth: '100%', mt: -5, backgroundColor: '#f8f7f7', borderRadius: '8px' }}>
                                                        <CompanyPageMini fullTrade={trade} />
                                                    </div>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>

                                    </Box>
                                </Collapse>
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            {/* // Cell that is 9 columns and says trade published by display name for XYZ  */}
                            <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '0.9em' }}>
                                    <em>Trade published by {trade.publisher_name} </em>
                                </Typography>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TradeDetails;