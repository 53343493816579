import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth, functions, db } from '../firebaseConfig';
import format from 'date-fns/format';
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { Button, Container, Typography, CircularProgress, Avatar, Box } from '@mui/material';
import NavigationBar from './NavigationBar'; // Assuming NavigationBar is properly implemented
import jsonData from '../api_responses.json';
import {  TextField, Checkbox, FormControlLabel, FormGroup, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function AccountPage() {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasPaid, setHasPaid] = useState(false);
    const [hasCanceled, setHasCanceled] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const companies = Object.keys(jsonData);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [user, setUser] = useState(null);
    const [userSettings, setUserSettings] = useState({ earnings_previews_setting: false, earnings_updates_setting: false, earnings_evidence_setting: false });
    const [tickerRequest, setTickerRequest] = useState('');
    const [bugReport, setBugReport] = useState('');

    useEffect(() => {
        if (auth.currentUser) {
            const userRef = doc(db, "users", auth.currentUser.uid);
            getDoc(userRef).then(docSnap => {
                if (docSnap.exists()) {
                    setUserSettings(docSnap.data());
                }
            });
        } else {
            navigate('/'); // Redirect if not logged in
        }
    }, [navigate]);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setUserSettings(prev => ({ ...prev, [name]: checked }));
        const userRef = doc(db, "users", auth.currentUser.uid);
        updateDoc(userRef, { [name]: checked });
    };

    const handleSubmitFeedback = (type, value) => {
        const userRef = doc(db, "users", auth.currentUser.uid);
        updateDoc(userRef, { [type]: arrayUnion(value) });
        if (type === 'ticker_requests') setTickerRequest('');
        else setBugReport('');
    };

    useEffect(() => {
        // This example assumes `auth.currentUser` changes are correctly handled elsewhere.
        const unsubscribe = auth.onAuthStateChanged(currentUser => {
            setUser(currentUser);
            if (!currentUser) {
                navigate('/'); // Redirects to home if not authenticated
            }
        });
    
        return () => unsubscribe();
      }, [navigate]);


    useEffect(() => {
        const results = searchTerm ? companies.filter(company => {
            if (!jsonData[company]) return false;
            const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
            const firstDateKey = sortedDates[0];
            const companyData = jsonData[company][firstDateKey];
            const matchesTicker = company.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFullName = companyData && companyData.full_name && companyData.full_name.toLowerCase().includes(searchTerm.toLowerCase());
            return matchesTicker || matchesFullName;
        }) : [];
        setFilteredCompanies(results);
    }, [searchTerm, companies, jsonData]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    const convertToISOFormat = (dateStr) => {
        if (!dateStr) return null; // Return null if dateStr is empty
        const parts = dateStr.split('-');
        if (parts.length !== 3) return null; // Return null if dateStr format is not as expected
      
        // Assuming date is in YY-MM-DD format and needs conversion to YYYY-MM-DD
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        const fullYear = year.length === 2 ? (parseInt(year, 10) > 50 ? `19${year}` : `20${year}`) : year;
      
        return `${fullYear}-${month}-${day}`; // Return the date in ISO format (YYYY-MM-DD)
      };
      

    const formatDate = (dateStr) => {
    try {
      const isoDateStr = convertToISOFormat(dateStr);
      if (!isoDateStr) {
        console.error("Invalid ISO Date:", dateStr); // Log bad inputs
        return 'Invalid date';
      }

      const date = new Date(isoDateStr + 'T18:00:00Z');
      if (isNaN(date.getTime())) {
        console.error("Invalid Date Object from ISO String:", isoDateStr); // More specific logging
        return 'Invalid date';
      }

      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error(`Error in formatDate function: ${dateStr}`, error);
      return 'Invalid date';
    }
  };
    

    const handleCompanySelect = (company) => {
        navigate(`/company/${company}`, { state: { company } });
        setSearchTerm('');  // Clear the search term to hide the search results
      };



    useEffect(() => {
        const getUserSubscriptionStatus = async () => {
            const userRef = doc(db, "users", auth.currentUser.uid);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                setHasPaid(docSnap.data().hasPaid);
                setHasCanceled(docSnap.data().hasCanceled);
            } else {
                console.log("No such document!");
            }
        };
        getUserSubscriptionStatus();
    }, []);

    const handleLogout = async () => {
        await signOut(auth);
        navigate('/');
    };

    const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
    const handleCancellation = () => {
        setIsLoading(true);
        cancelSubscription().then(result => {
            console.log(result.data.message);
            setMessage(result.data.message);
            setIsLoading(false);
            setHasCanceled(true);
        }).catch(error => {
            console.error("Error canceling subscription:", error);
            setMessage(`Error: ${error.message}`);
            setIsLoading(false);
        });
    };

    const renewSubscription = httpsCallable(functions, 'renewSubscription');
    const handleRenew = () => {
        // Replace with the actual URL to renew the subscription
        const userRef = doc(db, "users", auth.currentUser.uid);
        updateDoc(userRef, { hasCanceled: false });
        setIsLoading(true);
        renewSubscription().then(result => {
            console.log(result.data.message);
            setMessage(result.data.message);
            setIsLoading(false);
            setHasCanceled(false);
        }).catch(error => {
            console.error("Error renewing subscription:", error);
            setMessage(`Error: ${error.message}`);
            setIsLoading(false);
        });
        // window.location.href = 'https://buy.stripe.com/fZe2aM2JogZL5ya288';
    };
    

    const handleSignOut = async () => {
        await signOut(auth);
        navigate('/');  // Redirect to login after sign out
    };
    
    const contentStyle = {
        marginTop: '77px' // Adjust based on your nav bar height
      };
    
    return (
        <div style={contentStyle}>
        <Box sx={{ flexGrow: 1 }}>
              <NavigationBar 
        user={user}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        filteredCompanies={filteredCompanies}
        jsonData={jsonData}
        handleCompanySelect={handleCompanySelect}
        setShowUserMenu={setShowUserMenu}
        showUserMenu={showUserMenu}
        handleSignOut={handleSignOut}
      />
            <Container maxWidth="md">
            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <Typography variant="h4" gutterBottom>Account</Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Emails</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={userSettings.earnings_previews_setting} onChange={handleCheckboxChange} name="earnings_previews_setting" />}
                                label="Earnings Previews"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={userSettings.earnings_updates_setting} onChange={handleCheckboxChange} name="earnings_updates_setting" />}
                                label="Earnings Updates"
                            />
                             <FormControlLabel
                                control={<Checkbox checked={userSettings.earnings_evidence_setting} onChange={handleCheckboxChange} name="earnings_evidence_setting" />}
                                label="New Disconfirming Evidence"
                            />
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Feedback</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <TextField
                                label="Ticker Requests"
                                variant="outlined"
                                fullWidth
                                value={tickerRequest}
                                onChange={e => setTickerRequest(e.target.value)}
                                placeholder="Ticker requests..."
                                sx={{ marginBottom: 2 }}
                            />
                            <Button variant="contained" onClick={() => handleSubmitFeedback('ticker_requests', tickerRequest)}>Submit</Button>
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                            <TextField
                                label="Bug or Feature Requests"
                                variant="outlined"
                                fullWidth
                                value={bugReport}
                                onChange={e => setBugReport(e.target.value)}
                                placeholder="Bug report or feature requests..."
                            />
                            <Button variant="contained" onClick={() => handleSubmitFeedback('bugs_or_features', bugReport)} sx={{ marginTop: 1 }}>Submit</Button>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Billing</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {hasPaid && !hasCanceled && (
                    <Button variant="contained" color="error" onClick={handleCancellation} disabled={isLoading} sx={{ margin: 1 }}>
                        {isLoading ? <CircularProgress size={24} /> : 'Cancel Subscription'}
                    </Button>
                    )}
                    {hasPaid && hasCanceled && (
                        <Button variant="contained" color="primary" onClick={handleRenew} sx={{ margin: 1 }}>
                            Renew Subscription
                        </Button>
                    )}
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Container>
        </Box>
    </div>

    );
}

export default AccountPage;
