import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider } from '../firebaseConfig';
import { signInWithPopup, onAuthStateChanged, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { doc, getFirestore, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import {
  Button, Container, Typography, Box, styled, Tooltip, CircularProgress
} from '@mui/material';

import GoogleRainbowIcon from '../GoogleRainbowIcon.png';
import FacebookIcon from '../MetaIcon.png';
import StripeIcon from '../StripeIcon.jpg';
import ThunderstormRoundedIcon from '@mui/icons-material/ThunderstormRounded';



import { signOut } from "firebase/auth";


// import FacebookIcon from '@mui/icons-material/Facebook';
// import { facebookProvider } from '../firebaseConfig';


const Logo = styled('img')({
  filter: 'grayscale(100%) contrast(0.84)',
  opacity: 0.97,
  // width: '100px', // Adjust the width as needed
});


// Styled components
// const GradientBackground = styled('div')({
//   backgroundImage: 'linear-gradient(45deg, rgba(254, 107, 139, 0.055) 30%, rgba(255, 142, 83, 0.055) 90%)',
//   backgroundSize: 'cover',
//   height: '100vh',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center'
// });
// Styled components
const GradientBackground = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  paddingBottom: 'env(safe-area-inset-bottom)', // Add padding at the bottom
  [theme.breakpoints.up('600')]: {
    flexDirection: 'row',
  },
}));

const CarouselContainer = styled('div')({
  position: 'relative',
  // height: '300px', // Adjust based on your preference
  overflow: 'hidden',
  justifyContent: 'center', 
  display: 'flex'
});

const GradientOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '150px',
  // marginBottom: '10px',
  background: 'linear-gradient(to bottom, #F3F4F5, transparent)',
});

const GradientBottomOverlay = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100px',
  background: 'linear-gradient(to top, #F3F4F5, transparent)',
});

const Carousel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '800px',
  animation: 'scroll 85s linear infinite',
  '@keyframes scroll': {
    '0%': { transform: 'translateY(000%)' },
    '100%': { transform: 'translateY(-100%)' },
  },
});

const Screenshot = styled('img')({
  width: '100%',
  marginBottom: '20px',
});



const StyledContainer = styled(Container)({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '13px',
  padding: '2rem',
  boxShadow: '0 3px 11px rgba(0, 0, 0, 0.175)',
  border: '2px solid rgba(0, 0, 0, 0.00)', // Use the shorthand property
  paddingBottom: '1.6rem',
  width: '100%', // Ensures it fills the parent container
  maxWidth: '600px', // Or whatever width suits your design
});

const LoginButton = styled(Button)({
  background: 'white',
  border: '1px solid #d3d3d3',
  // fontWeight: 'bold',
  padding: '10px 24px',
  marginTop: '12px',
  width: '100%',
  textTransform: 'none',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '16.5px', // Increase the font size
  alignItems: 'center',
  gap: '0px',
  color: 'black', // Set the font color to black
});

const FooterLogos = styled(Box)({
  textAlign: 'center',
  width: '100%', // Ensures it spans the full width of its container
  position: 'absolute', // Keeps it at the bottom
  bottom: 'calc(75px + env(safe-area-inset-bottom))', // Adjust this to raise the footer
  left: 0, // Ensures it starts from the far left of the RightPanel
  right: 0, // Ensures it ends at the far right of the RightPanel
  // color: 'grey',
});

const FooterText = styled(Typography)({
  textAlign: 'center',
  width: '100%', // Ensures it spans the full width of its container
  position: 'absolute', // Keeps it at the bottom
  bottom: 'calc(15px + env(safe-area-inset-bottom))', // Use safe area inset to prevent hiding
  left: 0, // Ensures it starts from the far left of the RightPanel
  right: 0, // Ensures it ends at the far right of the RightPanel
  color: 'grey',
});

// const LeftPanel = styled('div')(({ theme }) => ({
//   flex: 1,
//   display: 'none',  // This line sets the default display to none
//   backgroundColor: '#f0f0f0',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   flexDirection: 'column',
//   padding: '20px',
//   [theme.breakpoints.up('600')]: {
//     display: 'flex',  // This line makes it flex only when the width is 600px or more
//   },
// }));

const LeftPanel = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F3F4F5',
  padding: '20px',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.up('600')]: {
    display: 'flex',
  },
}));


const RightPanel = styled('div')({
  flex: 1,
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Centers content vertically
  alignItems: 'flex-start', // Aligns content to the left
  padding: '2rem',
  position: 'relative', // New line to add
});

// Landing Page Component
const LandingPage = ({ user, hasPaid, onLogout }) => {
  const navigate = useNavigate();
  const [hasPaidAlready, setHasPaidAlready] = useState(false);
  const facebookProvider = new FacebookAuthProvider();
  facebookProvider.addScope('email');
  const [referralText, setReferralText] = useState('Continue with one of the following:');
  const [hasNotPaidText, setHasNotPaidText] = useState('Activate with Stripe:');
  const [hasPaidText, setHasPaidText] = useState('Continue to the Dashboard:');
  const [isLoading, setIsLoading] = useState(false);  // New loading state


  const windowWidth = useWindowWidth(); // Use custom hook
  const isMobile = windowWidth < 1100;
  function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
  }

  useEffect(() => {
    // const unsubscribe = onAuthStateChanged(auth, (user) => {
    //   if (user) {
    //     navigate('/dashboard', { replace: true });
    //   } else {
    //     navigate('/', { replace: true });
    //   }
    // });

    // checkAuth();

    const referrer = document.referrer;
    if ((referrer.includes('calypsocopilot.com') || referrer.includes('calypsocopilot.com/pricing')) && !referrer.includes('app.calypsocopilot.com')) {
      setReferralText('Continue with one of the following:');
    }
    // return () => unsubscribe();
  }, [navigate]);

  const [metaTooltipOpen, setMetaTooltipOpen] = useState(false);

  const handleMetaLogin = () => {
    setMetaTooltipOpen(true);
    setTimeout(() => {
      setMetaTooltipOpen(false);
    }, 2000);
  };

  useEffect(() => {
    if (user) {
      setIsLoading(true); // Start loading when the user is defined
      const db = getFirestore();
      const userDocRef = doc(db, "users", user.uid);
      
      const checkHasPaid = async () => {
        const docSnapshot = await getDoc(userDocRef);
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setHasPaidAlready(userData.hasPaid);
        }
        setIsLoading(false); // End loading after fetching data
      };

      checkHasPaid(); // Call it directly without setInterval
    }
  }, [user]);

  const handleLogin = async (provider) => {
    try {

      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const db = getFirestore();
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);



      if (!userDoc.exists() || !userDoc.data().email || userDoc.data().hasPaid === false) {
        setHasPaidAlready(false);
        await setDoc(userDocRef, {
          email: user.email,
          hasPaid: false,
          professional: false,
          signUpTime: serverTimestamp(), // Add server timestamp for sign-up time
          displayName: user.displayName
        }, { merge: true });
      }

      const docSnapshot = await getDoc(userDocRef);
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        if (userData.hasPaid) {
          setHasPaidAlready(true);
          navigate('/dashboard');
        } else {
          window.location.href = 'https://buy.stripe.com/4gw7vs9rjcMif5ebII';
        }
      } else {
        navigate('/payment');
      }
    } catch (error) {
      console.error('Error during sign in:', error);
    }
  };

  return (
    <GradientBackground>
      {!isMobile && (<LeftPanel>
        <CarouselContainer>
        <GradientOverlay />
        <Carousel>
          <Screenshot src="/screenshots/product2.png" alt="Product 2" />
          <Screenshot src="/screenshots/product3.png" alt="Product 3" />
          <Screenshot src="/screenshots/product4.png" alt="Product 4" />
          <Screenshot src="/screenshots/product5.png" alt="Product 5" />
          <Screenshot src="/screenshots/product2.png" alt="Product 2" />
          <Screenshot src="/screenshots/product3.png" alt="Product 3" />
          <Screenshot src="/screenshots/product4.png" alt="Product 4" />
          <Screenshot src="/screenshots/product5.png" alt="Product 5" />
        </Carousel>
        <GradientBottomOverlay />
      </CarouselContainer>
        <Typography variant="caption" sx={{fontSize:'0.9em', color:'rgb(0,0,0,0.7)', justifyContent: 'center', display: 'flex', marginTop: '5px'}}>TRUSTED BY PROFESSIONALS FROM:</Typography>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '2em', marginBottom: '15px', justifyContent: 'center'}}> 
        <Logo src="/logos/goldman.png" alt="Goldman Logo" sx={{height: '61px'}}/>
        <Logo src="/logos/point72.png" alt="Point72 Logo"sx={{height: '78px'}} />
        <Logo src="/logos/baird.png" alt="Baird Logo"sx={{height: '58px'}} />
        <Logo src="/logos/barings.png" alt="Barings Logo" sx={{height: '33px'}}/>
        <Logo src="/logos/bofa.png" alt="BofA Logo"sx={{height: '65px', marginLeft: '-17px'}} />
        </Box>
      </LeftPanel>)}
      <RightPanel>
      
      <StyledContainer maxWidth="xs">
      <Typography variant="h3" component="h1" gutterBottom style={{ textAlign: 'center', marginBottom: '-0.2px', marginTop: '-10px' }}>
  <img src="/logo512.png" alt="Calypso CoPilot Logo" style={{ width: 100, cursor: 'pointer' }} onClick={() => { window.location.href = 'https://calypsocopilot.com'; }} />
</Typography>
        
        <Typography variant="h6" gutterBottom>
          Welcome to Calypso!
        </Typography>

        {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
        {!user && <Typography variant="body1" style={{ marginTop: '14.5px', marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: referralText }} />}
        {user && !hasPaidAlready && <Typography variant="body1" style={{ marginTop: '14.5px', marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: hasNotPaidText }} />}
        {user && hasPaidAlready && <Typography variant="body1" style={{ marginTop: '14.5px', marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: hasPaidText }} />}


        {!user && (
          <>
            <LoginButton onClick={() => handleLogin(new GoogleAuthProvider())} startIcon={<img src={GoogleRainbowIcon} alt="Google" style={{ width: 24, height: 24, marginRight: -2 }} />}>
              Google
            </LoginButton>
            <Tooltip title="Meta Login Temporarily Disabled" open={metaTooltipOpen} disableFocusListener disableHoverListener disableTouchListener>
              <span>
                <LoginButton onClick={handleMetaLogin} startIcon={<img src={FacebookIcon} alt="Meta" style={{ width: 24, height: 24, marginRight: -2 }} />}>
                  Meta
                </LoginButton>
              </span>
            </Tooltip>
          </>

        )}

        {user && hasPaidAlready && (
          <>
            <LoginButton onClick={() => navigate('/dashboard')}>
              Dashboard
            </LoginButton>
            <LoginButton onClick={async () => {
              try {
                await signOut(auth);
              } catch (error) {
                console.error("Error signing out:", error);
              }
            }}>
              Sign Out
            </LoginButton>
          </>

        )}

        {user && !hasPaidAlready && (
          <>
            <LoginButton onClick={() => window.location.href = 'https://buy.stripe.com/4gw7vs9rjcMif5ebII'}>
            {/* <LoginButton
              onClick={() => window.location.href = 'https://buy.stripe.com/4gw7vs9rjcMif5ebII'}
              sx={{
                backgroundColor: '#FFC300', // Yellow with a hint of orange
                color: 'white', // White text color
                fontWeight: 'bold', // Makes text bold
                boxShadow: '2px 2px 4px rgba(255, 165, 0, 0.6)', // Orange transparent shadow
                border: 'none',
                borderRadius: '4px',
                padding: '10px 20px',
                cursor: 'pointer',
                display: 'flex', // Enables flex container
                alignItems: 'center', // Centers items vertically
                justifyContent: 'center', // Centers items horizontally
                '&:hover': {
                  backgroundColor: '#E6B300' // A slightly darker shade for hover
                }
              }}
            > */}

              Activate
              {/* <img src="/lightning.png" alt="Lightning Icon" style={{ marginLeft: '8px', height: '1.2rem' }} /> */}
            </LoginButton>
            <LoginButton onClick={async () => {
              try {
                await signOut(auth);
              } catch (error) {
                console.error("Error signing out:", error);
              }
            }}
            >
              Sign Out
            </LoginButton>
          </>

        )}
        </>
         )}

      </StyledContainer>

      {isMobile && (<FooterLogos>
        <Typography variant="caption" sx={{fontSize:'0.9em', color:'rgb(0,0,0,0.7)', justifyContent: 'center', display: 'flex', marginTop: '5px'}}>TRUSTED BY PROFESSIONALS FROM:</Typography>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '1.23em', marginBottom: '15px', justifyContent: 'center'}}> 
        <Logo src="/logos/goldman.png" alt="Goldman Logo" sx={{height: '46px'}}/>
        <Logo src="/logos/point72.png" alt="Point72 Logo"sx={{height: '60px'}} />
        <Logo src="/logos/baird.png" alt="Baird Logo"sx={{height: '45px'}} />
        <Logo src="/logos/barings.png" alt="Barings Logo" sx={{height: '25px'}}/>
        <Logo src="/logos/bofa.png" alt="BofA Logo"sx={{height: '50px', marginLeft: '-14px'}} />
        </Box>
      </FooterLogos>)}

      {isMobile && (<FooterText variant="body2" sx={{bottom: '33px'}}>
        Need help? Contact us at <a href="mailto:info@calypsocopilot.com">info@calypsocopilot.com</a><br />© Calypso, 2024
      </FooterText>)}
      {!isMobile && (<FooterText variant="body2" sx={{bottom: '13px'}}>
        Need help? Contact us at <a href="mailto:info@calypsocopilot.com">info@calypsocopilot.com</a><br />© Calypso, 2024
      </FooterText>)}
      
      </RightPanel>
    </GradientBackground>
  );
};

export default LandingPage;
