import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { auth, db, provider } from './firebaseConfig';
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { doc, getFirestore, onSnapshot, getDoc, updateDoc } from "firebase/firestore";
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';
import CompanyPage from './components/CompanyPage';
import jsonData from './api_responses.json';
import AccountPage from './components/AccountPage';
// import Professional from './components/Professional';
import EventsDashboard from './components/EventsDashboard';
import TradesDashboard from './components/TradesDashboard';
import TradeDetails from './components/TradeDetails';
import SearchResults from './components/SearchResults';
import posthog from 'posthog-js';


function App() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [hasPaid, setHasPaid] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                localStorage.setItem('user', JSON.stringify(currentUser));

                const userDocRef = doc(db, "users", currentUser.uid);
                const docSnapshot = await getDoc(userDocRef);

                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    const currentDate = new Date();
                    const lastPaidDate = new Date(data.lastPaid);

                    const diffTime = Math.abs(currentDate - lastPaidDate);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    // Identify the user in PostHog
                    posthog.identify(data.email);
                    posthog.capture('User Redirected at JS level', { email: data.email });

                    // Optionally capture a login event
                    // posthog.capture('User Logged In', { email: userEmail });

                    // if (diffDays > 30) {
                    //     // If it's been more than 30 days since last payment
                    //     // Manually get rid of people who churn for now
                    //     // await updateDoc(userDocRef, {
                    //     //     hasPaid: false
                    //     // });
                    //     // setHasPaid(false);
                    //     // if (location.pathname !== '/') {
                    //     //     navigate('/');
                    //     // }
                    // } else {
                    setHasPaid(!!data.hasPaid);
                    if (!data.hasPaid && location.pathname !== '/') {
                        navigate('/');
                    } else if (data.hasPaid && location.pathname === '/') {
                        navigate('/dashboard');
                    }
                    // }
                } else {
                    navigate('/');
                }
            } else {
                setUser(null);
                localStorage.removeItem('user');
                if (location.pathname.includes('/trade/') === false) {
                    navigate('/');
                }
            }
        });

        return () => unsubscribe();
    }, [navigate, location.pathname]);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            localStorage.removeItem('user');
            navigate('/');
        } catch (error) {
            console.error('Error during sign out:', error);
        }
    };

    return (
        <div>
            <Routes>
                <Route path="/" element={<LandingPage user={user} hasPaid={hasPaid} onLogout={handleSignOut} />} />
                <Route path="/dashboard" element={user && hasPaid ? <Dashboard /> : <Navigate to="/" replace />} />
                {Object.keys(jsonData).map(company => (
                    <Route key={company} path={`/company/:company`} element={user && hasPaid ? <CompanyPage /> : <Navigate to="/" replace />} />
                ))}
                <Route path="/results" element={user && hasPaid ? <SearchResults /> : <Navigate to="/" replace />} />
                <Route path="/account" element={user ? <AccountPage /> : <Navigate to="/" replace />} />
                {/* <Route path="/professional" element={<Professional user={user} hasPaid={hasPaid} onLogout={handleSignOut} />} /> */}
                <Route path="/events" element={user && hasPaid ? <EventsDashboard /> : <Navigate to="/" replace />} />
                <Route path="/trades" element={user && hasPaid ? <TradesDashboard /> : <Navigate to="/" replace />} />
                <Route path="/trade/:tradeId" element={<TradeDetails />} />
            </Routes>
        </div>
    );
}

export default App;
