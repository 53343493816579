// AI_Chat.js
import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { doc, getDoc, setDoc, updateDoc, collection, addDoc, arrayUnion } from "firebase/firestore";
import { auth, db } from '../firebaseConfig'; // Make sure to import auth and db appropriately
import { Button, Collapse, CircularProgress, Box, TextField, Typography, InputAdornment, List, ListItemText, ListItem, ListItemIcon } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import AutoAwesome from '@mui/icons-material/AutoAwesome'; // A possible "AI sparkle" icon
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Styled Button with gradient and hover effect
const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  color: 'white',
  transition: '0.05s',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  '&.Mui-disabled': {
    background: 'linear-gradient(45deg, #E0E0E0 30%, #BEBEBE 90%)',
    color: '#9E9E9E',
  },
}));

const GradientTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'purple',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
      // boxShadow: '0 0 5px rgba(254,107,139,0.5), 0 0 10px rgba(255,142,83,0.5)',
      boxShadow: '0 0 2px black, 0 0 4px grey',
      borderWidth: '1px',
    },
  },
  '& .MuiInputBase-inputMultiline': {
    minHeight: '50px',
    overflow: 'auto',
  },
});



const AI_Chat = ({
  companyName = '',
  keyDebates = '',
  newsHeadlines = '',
  financialData = '',
  numbersIntoPrint = '',
  currentSetupNumbers = '',
  bullThesis = '',
  bearThesis = '',
  personalThesis = '',
  customNotes = '',
  onOpinionReceived: handleOpinionReceived = () => { },
  isProfessional = false,
}) => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [requestsMade, setRequestsMade] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [sessionId, setSessionId] = useState(''); // State to store the session ID


  const handleMinimizeClick = () => {
    setIsMinimized(!isMinimized);
  };

  const [thankYouVisible, setThankYouVisible] = useState(false);
  const handleFeedback = async (positive) => {
    try {
      const sessionRef = doc(db, "users", auth.currentUser.uid, "ai_chats", companyName, "sessions", sessionId);
      console.log("Updating session ID:", sessionId); // Debug: Log the session ID being used

      await updateDoc(sessionRef, {
        ...(positive ? { sentPositiveFeedback: true } : { sentNegativeFeedback: true })
      });

      setThankYouVisible(true);
      setTimeout(() => {
        setThankYouVisible(false);
      }, 1000); // Tooltip disappears after 1 second
    } catch (error) {
      console.error("Error updating session feedback:", error); // Error handling
      alert("Failed to update feedback: " + error.message);
    }
  };

  // Reset state when companyName changes or on navigate
  useEffect(() => {
    setQuery(''); // Clears the textfield
    setResponse(''); // Clears any chat response
}, [companyName]); // Dependencies

  const formatAIResponseText = (text) => {
    const lines = text.split('\n');
    const formattedItems = lines.map((item, index) => {
      // Determine if the line is a bullet point
      const isBulletPoint = item.trim().startsWith('-');
      const cleanText = isBulletPoint ? item.trim().substring(1).replace('**', '').replace('**', '').trim() : item.replace('**', '').replace('**', '').trim();
      // const cleanText = item.replace('- **', '').replace('**', '').trim();
      const splitIndex = cleanText.indexOf(':');
      const keyText = splitIndex > -1 ? cleanText.substring(0, splitIndex + 1) : ""; // Key text up to colon
      const restText = splitIndex > -1 ? cleanText.substring(splitIndex + 1).trim() : cleanText; // Text after the colon

      if (index === 0) { // First line with the icon
        return (
          <ListItem key={index} sx={{ pl: 0.5, mt: 0.5, mb: -1.25, display: isMinimized ? 'none' : 'flex' }}>
            <ListItemIcon sx={{ minWidth: 'auto', mr: 1, color: 'secondary.main', cursor: 'pointer' }}
              onMouseOver={e => setShowTooltip(true)}
              onMouseOut={e => setShowTooltip(false)}
              onClick={() => setIsMinimized(true)}>
              {showTooltip ? <ClearIcon /> : <AutoAwesome />}
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ component: 'div' }}>
              {keyText ? <strong>{keyText}</strong> : ''} {restText}
            </ListItemText>
          </ListItem>
        );
      } else if (isBulletPoint) { // Standard bullet points for other items, with indent
        return (
          <ListItem key={index} sx={{ pl: 4, alignItems: 'flex-start', mb: -0.1, display: 'flex' }}>
            <ListItemIcon sx={{ minWidth: 'auto', mr: 1, fontSize: '1.6em', color: 'black', verticalAlign: 'middle', mt: -0.45 }}>
              • {/* Unicode bullet point */}
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ component: 'div' }} sx={{ mb: -0.1, display: 'flex' }}>
              <strong>{keyText}</strong> {restText}
            </ListItemText>
          </ListItem>
        );
      } else { // Handle lines that do not start with a bullet point
        return (
          <ListItemText key={index} sx={{ pl: 4, mt: 0.5, display: 'flex' }}> {/* Slightly less indent for non-bullet text */}
            {item}
          </ListItemText>
        );
      }
    });

    // Filter out null entries if some lines do not conform to expected formats
    return <List dense>{formattedItems.filter(item => item)}</List>;
  };



  useEffect(() => {
    if (!auth.currentUser) return;

    const checkAndInitializeChatRequests = async () => {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        const lastRequestDate = data.AI_chat_time?.toDate();
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const rateLimit = data.professional ? 75 : 20;

        if (!lastRequestDate || lastRequestDate < today) {
          await updateDoc(userRef, {
            AI_chat_requests: 1,
            AI_chat_time: today
          });
          // setRequestsMade(1);
        } else {
          setRequestsMade(data.AI_chat_requests || 0);
        }
      } else {
        // If no document exists, initialize it with default values
        await setDoc(userRef, {
          AI_chat_requests: 1,
          AI_chat_time: new Date()
        });
        // setRequestsMade(1);
      }
    };

    checkAndInitializeChatRequests();
  }, []);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const sendToAI = async () => {
    if (!query.trim() || requestsMade >= (isProfessional ? 75 : 20)) return;

    setLoading(true);
    const userRef = doc(db, "users", auth.currentUser.uid);
    try {
      const functions = getFunctions();
      if (window.location.hostname === "localhost") {
        connectFunctionsEmulator(functions, "localhost", 5001);
      }
      console.log("QUERY: ", query)
      const getAIChat = httpsCallable(functions, 'getAIChat');
      const result = await getAIChat({
        companyName,
        keyDebates,
        newsHeadlines,
        financialData,
        numbersIntoPrint,
        currentSetupNumbers,
        bullThesis,
        bearThesis,
        personalThesis,
        customNotes,
        query
      });

      if (result.data) {
        setResponse(result.data);
        const newCount = requestsMade + 1;
        await updateDoc(userRef, {
          AI_chat_requests: newCount,
          AI_chat_time: new Date()  // Update only count, keep the date as is
        });
        setRequestsMade(newCount);

        // Saving chat to Firestore
        const sessionRef = collection(db, "users", auth.currentUser.uid, "ai_chats", companyName, "sessions");
        const docRef = await addDoc(sessionRef, {
          question: query,
          answer: result.data,
          date: new Date()
        });
        setSessionId(docRef.id);
      }
    } catch (error) {
      console.error('Failed to fetch AI Chat:', error);
      alert('Error fetching AI Chat: ' + error.message);
    }
    setLoading(false);
    const isNew = true;
    const isChat = true;
    handleOpinionReceived(response.data, isNew, isChat, query);
  };

  const responseParagraphs = response ? response.split('\n').map((item, index) => (
    <p key={index}>{item}</p>
  )) : null;


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <GradientTextField
        fullWidth
        value={query}
        onChange={handleInputChange}
        placeholder="Ask about recent earnings..."
        multiline
        minRows={1}
        maxRows={4}
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <GradientButton
                onClick={sendToAI}
                disabled={loading || requestsMade >= (isProfessional ? 75 : 20) || !query.trim()}
                startIcon={!loading && <AutoAwesome />}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Ask AI'}
              </GradientButton>
            </InputAdornment>
          )
        }}
        className="gradient-input"
      />
      <Box sx={{ mt: -2.5, mb: 0, overflow: 'auto' }}>
        {isMinimized && (
          <Button onClick={handleMinimizeClick} startIcon={<KeyboardArrowRightIcon sx={{ color: 'secondary.main', mt: 1.3, ml: -1.8, paddingTop: 1 }} />}
            sx={{
              position: 'relative',
              padding: '-30px -50px -50px -50px', // Adjust these values to center the hover effect around the icon
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0)', // Example hover effect
                // Ensure the hover effect is centered by adjusting padding if necessary
              }
            }}>
          </Button>
        )}
        <Collapse in={!isMinimized}>
          {response && formatAIResponseText(response)}
        </Collapse>
      </Box>
      {!isMinimized && response && (
        <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '-17px', marginBottom: '-5px' }}>
          <Tooltip title="Minimize Chat">
            <IconButton onClick={handleMinimizeClick} sx={{
              color: 'rgba(0, 0, 0, 0.45)', // Adjusts the color to be slightly grey
              '& .MuiSvgIcon-root': { // Targets the icon within the IconButton
                fontSize: '0.9em' // Reduces the icon size by 10%
              }
            }}>
              <ExpandLessIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={thankYouVisible ? "Thank you!" : "Send Positive Feedback about this Chat"}>
            <span> {/* Span is needed because Tooltip does not work directly on disabled elements */}
              <IconButton onClick={() => handleFeedback(true)} disabled={thankYouVisible} sx={{
                color: 'rgba(0, 0, 0, 0.45)', // Adjusts the color to be slightly grey
                '& .MuiSvgIcon-root': { // Targets the icon within the IconButton
                  fontSize: '0.9em' // Reduces the icon size by 10%
                }
              }}>
                <ThumbUpAltIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={thankYouVisible ? "Thank you!" : "Send Negative Feedback about this Chat"}>
            <span>
              <IconButton onClick={() => handleFeedback(false)} disabled={thankYouVisible} sx={{
                color: 'rgba(0, 0, 0, 0.45)', // Adjusts the color to be slightly grey
                '& .MuiSvgIcon-root': { // Targets the icon within the IconButton
                  fontSize: '0.9em' // Reduces the icon size by 10%
                }
              }}>
                <ThumbDownAltIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
      {requestsMade >= (isProfessional ? 75 : 20) && (
        <Typography color="error">Daily limit reached</Typography>
      )}


    </Box>
  );
};

export default AI_Chat;
