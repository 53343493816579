import React, { useEffect, useState, useCallback } from 'react';
import { auth, db } from '../firebaseConfig';
import { useLocation } from 'react-router-dom';
import { TableContainer, Typography, IconButton, Paper, Table, Box, TableHead, TableRow, TableBody, TableCell, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import transcriptData from '../all_transcript_data.json';
import jsonData from '../api_responses.json';
import { signOut } from "firebase/auth";
import NavigationBar from './NavigationBar';
import format from 'date-fns/format';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function SearchResults() {
  const location = useLocation();
  const navigate = useNavigate();
  const { searchResults, previousSearchTerm } = location.state;
  const [showRecentEarnings, setShowRecentEarnings] = useState(false);
  const [modifiedResults, setModifiedResults] = useState([]);
  const [sortedResults, setSortedResults] = useState([]);
  const [user, setUser] = useState(null);

  console.log("search results: ", searchResults);
  // Ensure results are sorted every time they are rendered

  // Modify the results to show total matches only for the most recent earnings when toggled

  useEffect(() => {
    console.log("RECALCULATING MODIFIED!!!!!!!!")
    const newModifiedResults = Object.entries(searchResults).reduce((acc, [ticker, details]) => {
      if (showRecentEarnings && jsonData[ticker] && jsonData[ticker][details.mostRecent]) {
        const mostRecentMatches = details.matchesPerCall[details.mostRecent] || 0;
        acc[ticker] = { ...details, totalMatches: mostRecentMatches };
      } else {
        acc[ticker] = details;
      }
      return acc;
    }, {});

    setModifiedResults(newModifiedResults);
  }, [searchResults, showRecentEarnings]);

  useEffect(() => {
    console.log("RECALCULATING SORTED!!!!!!!!")

    const newSortedResults = Object.entries(modifiedResults).sort((a, b) => b[1].totalMatches - a[1].totalMatches);
    setSortedResults(newSortedResults);
  }, [modifiedResults]);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const indexOfFirstEvent = currentPage * rowsPerPage;
  const indexOfLastEvent = indexOfFirstEvent + rowsPerPage;
  const currentResults = sortedResults.slice(indexOfFirstEvent, indexOfLastEvent);
  const handleNextPage = () => {
    console.log("Handling next page. Current page:", currentPage);

    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    console.log("Handling previous page. Current page:", currentPage);

    setCurrentPage(currentPage - 1);
  };

  const handleRowsPerPageChange = (number) => {
    console.log("Changing rows per page. New value:", number);

    setRowsPerPage(number);
    setCurrentPage(0); // Reset to the first page when changing the number of rows per page
  };

  const extractSentences = (transcript, previousSearchTerm) => {
    if (!transcript) {
      console.error('No transcript provided');
      return [];
    }

    const text = Array.isArray(transcript) ? transcript.join(' ') : String(transcript);

    // Create a RegExp to find sentences containing the exact word (using word boundaries \b)
    const regex = new RegExp(`\\b${previousSearchTerm}\\b`, 'gi');

    // Split text into sentences
    const sentences = text.split(/(?<=[.!?])\s+/);

    // Filter sentences to find those that contain the keyword
    const matchingSentences = sentences.filter(sentence => sentence.match(regex));

    return matchingSentences;
  };

  // Change the current sentence index
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState({});

  const changeSentence = useCallback((ticker, date, offset) => {
    console.log("Changing sentence. Ticker:", ticker, "Date:", date, "Offset:", offset);

    const key = `${ticker}-${date}`;
    console.log("ticker", ticker);
    console.log("date: ", date);
    console.log("search term: ", previousSearchTerm);
    const sentences = extractSentences(transcriptData[ticker]?.[date], previousSearchTerm);
    console.log("Change sentence ticker: ", ticker);
    console.log("sentences: ", sentences);
    const currentIndex = currentSentenceIndex[key] || 0;
    const newIndex = Math.max(0, Math.min(sentences.length - 1, currentIndex + offset));
    if (newIndex !== currentIndex) {
      setCurrentSentenceIndex(prev => ({ ...prev, [key]: newIndex }));
    }
  }, [currentSentenceIndex, previousSearchTerm]);


  // NAV BAR
  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(currentUser => {
      setUser(currentUser);
    });
    return () => unsubscribeAuth();
  }, []);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const companies = Object.keys(jsonData);

  useEffect(() => {
    const results = searchTerm ? companies.filter(company => {
      // if (searchTerm === previousSearchTerm) return false;
      if (!jsonData[company]) return false;
      const sortedDates = Object.keys(jsonData[company]).sort((a, b) => new Date(formatDate(b)) - new Date(formatDate(a)));
      const firstDateKey = sortedDates[0];
      const companyData = jsonData[company][firstDateKey];
      const matchesTicker = company.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFullName = companyData && companyData.full_name && companyData.full_name.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesTicker || matchesFullName;
    }) : [];
    setFilteredCompanies(results);
  }, [searchTerm, companies, jsonData]);

  const highlightTerm = (text, term) => {
    if (!text) {
      return text;
    }
    const parts = text.split(new RegExp(`(${term})`, 'gi'));
    return parts.map((part, index) => part.toLowerCase() === term.toLowerCase() ?
      <span key={index} style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{part}</span> :
      part);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleCompanySelect = (company) => {
    navigate(`/company/${company}`, { state: { company } });
    setSearchTerm('');  // Clear the search term to hide the search results
  };
  const handleSignOut = async () => {
    await signOut(auth);
    navigate('/');  // Redirect to login after sign out
  };
  const convertToISOFormat = (dateStr) => {
    if (!dateStr) return null; // Return null if dateStr is empty
    const parts = dateStr.split('-');
    if (parts.length !== 3) return null; // Return null if dateStr format is not as expected

    // Assuming date is in YY-MM-DD format and needs conversion to YYYY-MM-DD
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const fullYear = year.length === 2 ? (parseInt(year, 10) > 50 ? `19${year}` : `20${year}`) : year;

    return `${fullYear}-${month}-${day}`; // Return the date in ISO format (YYYY-MM-DD)
  };

  const displayDate = (dateStr) => {
    try {

      const date = new Date(dateStr + 'T18:00:00Z');
      if (isNaN(date.getTime())) {
        console.error("Invalid Date Object from ISO String:", date); // More specific logging
        return 'Invalid date';
      }

      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error(`Error in formatDate function: ${dateStr}`, error);
      return 'Invalid date';
    }
  }
  const formatDate = (dateStr) => {
    try {
      const isoDateStr = convertToISOFormat(dateStr);
      if (!isoDateStr) {
        console.error("Invalid ISO Date:", dateStr); // Log bad inputs
        return 'Invalid date';
      }

      const date = new Date(dateStr + 'T18:00:00Z');
      if (isNaN(date.getTime())) {
        console.error("Invalid Date Object from ISO String:", isoDateStr); // More specific logging
        return 'Invalid date';
      }

      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error(`Error in formatDate function: ${dateStr}`, error);
      return 'Invalid date';
    }
  };
  // Row click
  const handleRowClick = (company) => {
    if (jsonData[company]) {
      window.scrollTo(0, 0);  // Scroll to the top of the window
      navigate(`/company/${company}`);
    } else {
      console.log('Ticker not found in API responses');
    }
  };
  const contentStyle = {
    marginTop: '77px' // Adjust based on your nav bar height
  };

  return (
    <div style={contentStyle}>
      <NavigationBar
        user={user}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        filteredCompanies={filteredCompanies}
        jsonData={jsonData}
        handleCompanySelect={handleCompanySelect}
        setShowUserMenu={setShowUserMenu}
        showUserMenu={showUserMenu}
        handleSignOut={handleSignOut}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1.5, mr: 3, ml: 3, mb: -1.5 }}>
        <Button variant="outlined" onClick={() => setShowRecentEarnings(prev => !prev)} sx={{ mr: 2 }}>
          {showRecentEarnings ? 'All Earnings' : 'Most Recent Earnings Only'}
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 3, mr: 3, ml: 3, maxWidth: '97%' }}>


        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Company:</TableCell>
              <TableCell align="center">Matches:</TableCell>
              <TableCell align="center">Details:</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentResults.map(([ticker, details]) => {
              const key = `${ticker}-${details.mostRecent}`;
              console.log("ticker", ticker);
              console.log("date", details.mostRecent);
              const sentences = extractSentences(transcriptData[ticker][details.mostRecent], previousSearchTerm);
              const currentIndex = currentSentenceIndex[key] || 0;
              return (
                <TableRow key={ticker} sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f5f5f5' } }}>
                  <TableCell align="center">{jsonData[ticker] && jsonData[ticker][details.mostRecent] ? jsonData[ticker][details.mostRecent].full_name + ' (' + ticker + ')' : ticker}</TableCell>
                  <TableCell align="center">{details.totalMatches}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton onClick={() => changeSentence(ticker, details.mostRecent, -1)} disabled={currentIndex === 0}>
                        <ArrowBackIosNewIcon />
                      </IconButton>
                      <Typography sx={{ mx: 2 }}>
                        {highlightTerm(sentences[currentIndex], previousSearchTerm)}
                      </Typography>
                      <IconButton onClick={() => changeSentence(ticker, details.mostRecent, 1)} disabled={currentIndex === sentences.length - 1}>
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              onClick={handlePreviousPage}
              disabled={currentPage === 0} // Disable if on the first page
              startIcon={<ArrowBackIosNewIcon />}
            >
              Previous
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={indexOfLastEvent >= sortedResults.length} // Disable if no more companies to show
              endIcon={<ArrowForwardIosIcon />}
            >
              Next
            </Button>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center', // Ensures vertical alignment is centered
            justifyContent: 'flex-end',
            color: 'action.disabled', // Applies a grey color similar to disabled buttons
          }}>
            <Typography sx={{ marginRight: 1 }}>Show:</Typography>
            <Button onClick={() => handleRowsPerPageChange(50)} sx={{ color: 'text.tertiary' }}>
              50
            </Button>
            <Typography sx={{ marginX: 1 }}>|</Typography>
            <Button onClick={() => handleRowsPerPageChange(100)} sx={{ color: 'text.tertiary' }}>
              100
            </Button>
            <Typography sx={{ marginX: 1 }}>|</Typography>
            <Button onClick={() => handleRowsPerPageChange(200)} sx={{ color: 'text.tertiary' }}>
              200
            </Button>
          </Box>
        </Box>
      </TableContainer>
    </div>
  );
}
export default SearchResults;
