import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, TextField, Paper, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import MenuIcon from '@mui/icons-material/Menu';
import { db } from '../firebaseConfig'; // Ensure you import your Firebase config and db correctly
import transcriptData from '../all_transcript_data.json';

function NavigationBar({ user, searchTerm, handleSearchChange, filteredCompanies, jsonData, handleCompanySelect, setShowUserMenu, showUserMenu, handleSignOut }) {
  const navigate = useNavigate();
  const [requestStatus, setRequestStatus] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const windowWidth = useWindowWidth(); // Use custom hook

  const isMobile = windowWidth < 800;

  const handleRequest = async () => {
    if (requestStatus !== 'requested') {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        ticker_requests: arrayUnion(searchTerm)
      });
      setRequestStatus('requested');
    }
  };

  function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
  }

  const searchTranscripts = (keyword, data) => {
    const results = {};
    Object.entries(data).forEach(([ticker, transcripts]) => {
      let totalMatches = 0;
      const matchesPerCall = {};

      Object.entries(transcripts).forEach(([date, transcriptArray]) => {
        // Join the array into a single string if it's not already a string
        const transcript = Array.isArray(transcriptArray) ? transcriptArray.join(' ') : transcriptArray;
        const count = (transcript.match(new RegExp(keyword, 'gi')) || []).length;
        if (count > 0) {
          matchesPerCall[date] = count;
          totalMatches += count;
        }
      });

      if (totalMatches > 0) {
        results[ticker] = {
          totalMatches,
          matchesPerCall,
          mostRecent: Object.keys(transcripts).sort().pop()
        };
      }
    });

    return results;
};

  const handleSearchSubmit = () => {
    if (searchTerm.trim()) {
      const searchResults = searchTranscripts(searchTerm, transcriptData);
      // setSearchTerm('');
      handleSearchChange({ target: { value: '' } });
      const previousSearchTerm = searchTerm;
      navigate('/results', { state: { searchResults, previousSearchTerm } });
    }
  };

  const onBlurHandler = () => {
    setRequestStatus('');
  };

  const handleChange = (event) => {
    handleSearchChange(event);
    if (requestStatus) {
      setRequestStatus('');
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" color="default" elevation={1}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Box sx={{ flexGrow: 0.125 }}>
            <Button color="inherit" onClick={() => navigate('/dashboard')} sx={{ ml: 0, mr: 0, minWidth: '115px' }}>
              <Typography
                variant="h6"
                onClick={() => navigate('/dashboard')}
                style={{
                  cursor: 'pointer', mr: 6, ml: 6,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    transform: 'scale(1.1)',
                    transition: 'transform 0.2s, background-color 0.2s'
                  }
                }}
              >
                <img src="/logo512.png" alt="Calypso CoPilot Logo" style={{ width: 60, marginBottom: -15, marginTop: -5, marginRight: -20, cursor: 'pointer' }} />
              </Typography>
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            {isMobile ? (
              <>
                <IconButton color="inherit" onClick={handleMenuOpen} sx={{ ml: 1, mr: 1 }}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => { navigate('/dashboard'); handleMenuClose(); }}>Dashboard</MenuItem>
                  <MenuItem onClick={() => { navigate('/events'); handleMenuClose(); }}>Events</MenuItem>
                  <MenuItem onClick={() => { navigate('/trades'); handleMenuClose(); }}>Trades</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button color="inherit" onClick={() => navigate('/dashboard')} sx={{ ml: 1, mr: 1 }}>
                  <Typography sx={{ ml: 0, mr: 0 }}>Dashboard</Typography>
                </Button>
                <Button color="inherit" onClick={() => navigate('/events')} sx={{ ml: 1, mr: 1 }}>
                  <Typography sx={{ ml: 0, mr: 0 }}>Events</Typography>
                </Button>
                <Button color="inherit" onClick={() => navigate('/trades')} sx={{ ml: 1, mr: 1 }}>
                  <Typography sx={{ ml: 0, mr: 0 }}>Trades</Typography>
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ flexGrow: 8, display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <TextField
              label="Search Here"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleChange}
              onBlur={onBlurHandler}
              sx={{ width: '70%', ml: -8 }}
            />
            {searchTerm && (
              <Paper sx={{
                position: "absolute",
                backgroundColor: "white",
                width: '70%',
                mt: '50px',
                ml: -8,
                zIndex: 1
              }}>
                {filteredCompanies.map((company) => (
                  <Typography key={company} onClick={() => handleCompanySelect(company)} sx={{ p: 1, cursor: "pointer" }}>
                    {jsonData[company] ? jsonData[company][Object.keys(jsonData[company])[0]].full_name : "Unknown"} ({company})
                  </Typography>
                ))}
                    <Typography  onClick={handleSearchSubmit} sx={{ p: 1, cursor: "pointer" }}>
                  Search '{searchTerm}' in transcripts...
                </Typography>
                {/* {searchTerm && filteredCompanies.length === 0 && !requestStatus && (
                  <Button sx={{ position: 'absolute', right: 5, top: -35, height: '100%' }} onClick={handleRequest}>
                    Request
                  </Button>
                )} */}
                {requestStatus === 'requested' && (
                  <Typography sx={{ position: 'absolute', right: 5, top: -35, height: '100%', padding: '0 12px', display: 'flex', alignItems: 'center' }}>
                    Sent!
                  </Typography>
                )}
              </Paper>
            )}
          </Box>

          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
              onMouseOver={() => setShowUserMenu(true)}
              onMouseLeave={() => setShowUserMenu(false)}>
              <IconButton style={{ padding: 0 }}>
                <img src={user?.photoURL || 'https://via.placeholder.com/50'} alt="Profile" style={{ width: 40, height: 40, borderRadius: '50%' }} />
              </IconButton>
              {showUserMenu && (
                <Box sx={{ position: 'absolute', right: 0, top: '100%', backgroundColor: 'white', boxShadow: 1 }}>
                  <Button onClick={() => navigate('/account')} style={{ display: 'block' }}>Account</Button>
                  <Button onClick={handleSignOut} style={{ display: 'block' }}>Sign Out</Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
